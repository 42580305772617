import React from "react";
// Customizable Area Start
import { Typography, Box, Tooltip, Modal, IconButton, Button, Autocomplete, createFilterOptions } from "@mui/material";
import * as Yup from "yup";

import { Formik } from "formik";
import {
  banner,
  passwordHiddenIcon,
  navLogo,
  errorIcon,
  closeIcon,
  successIcon,
} from "./assets";
import { configJSON } from "./SignUpPageController";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Snackbar from '@mui/material/Snackbar';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import supportedZipCodes from './supportedZipCodes';
import { styled } from '@mui/material/styles';
// Customizable Area End

import SignUpPageController, { Props } from "./SignUpPageController";
import { borderRadius } from "react-select/src/theme";
// import {  } from "react-native-elements";
// Customizable Area Start

const filterOptions = createFilterOptions({
  matchFrom: 'start',
});

// Customizable Area End
export default class SignUpPage extends SignUpPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  baseURL = require("../../../framework/src/config.js").baseURL;
  questionSchema = () => {
    return Yup.object().shape({
      zip_code: Yup.string()
        .trim()
        .required("Please enter your zip")
        .matches(/^\d{4,7}(?:[-\s]\d{4})?$/, "Zip code is invalid") // need to check again
        .typeError("Please enter a valid zip code")
        .test(
          "isSupported",
          "Unfortunately, we are not yet able to provide service in your area. Please check back in the near future to see if your area is within our coverage. For more details or questions, please call us at (718) 756-4123.",
          (val) => !val || supportedZipCodes.includes(val)
        ),
      email: Yup.string()
        .email("Invalid email format")
        .required("Mail is required"),
      full_name: Yup.string()
        .trim()
        .required("Name is required"),
      password: Yup.string()
        .matches(/^\S*$/, 'Password cannot contain spaces')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[\w!@#$%^&*(),.?":{}|<>]{8,72}$/,
          'Password must include at least one uppercase letter, one lowercase letter, one number, one special character, be at least 8 characters long, and have equal or less than 72 characters'
        )
        .required("Required"),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    });
  };

  handleZipCodeChange(newValue: any, setFieldValue: any) {
    setFieldValue('zip_code', newValue);
    this.state.zipCodeError && this.setState({ zipCodeError: '' });
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.signUpContainer}>
        <Modal
          open={this.state.showModal}
          onClose={() => {
            this.setModalShow(false);
          }}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={webStyle.modalBox}>
            <Box sx={webStyle.modalTitleBlock}>
              <Typography sx={webStyle.modalTitle}>
                {configJSON.termsAndConditionsText}
              </Typography>
              <button
                style={webStyle.modalCloseButton}
                onClick={() => this.setModalShow(false)}
              >
                <img src={closeIcon} alt="Logo" style={{ height: "24px" }} />
              </button>
            </Box>
            <Box
              component="div"
              sx={webStyle.modalScrollBox}
              dangerouslySetInnerHTML={{
                __html:
                  this.state.termsAndConditionPageContentData?.description,
              }}
            />
          </Box>
        </Modal>

        <Box sx={webStyle.leftBlock}>
          <Box sx={webStyle.logoContainer}>
            <img src={navLogo} alt="Logo" style={{ height: "40px" }} />
            <Typography sx={webStyle.logoText}>
              {configJSON.logoText}
            </Typography>
          </Box>
          <Box sx={webStyle.signUpFormContainer}>
            <Box>
              <Typography style={webStyle.formTitle}>
                {configJSON.signUpText}
              </Typography>
              <Typography style={webStyle.formSubTitle}>
                {configJSON.createAnAccountText}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                zip_code: "",
                full_name: "",
                email: "",
                password: "",
                password_confirmation: "",
                term_agree: false,
              }}
              validateOnChange={true}
              validationSchema={this.questionSchema}
              onSubmit={(values) => {
                this.setValidate(true);
                if (!values.term_agree) {
                  this.setState({ warningMessage: 'You have to agree to the terms & conditions' });

                  return;
                }
                this.doSignUp();
              }}
              data-test-id="SignUpForm"
            >
              {({ errors, values, setFieldValue, handleSubmit, validateField }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"zip_code"}>
                        {configJSON.zipCodeText}
                      </label>
                      <Autocomplete
                        freeSolo
                        options={supportedZipCodes}
                        onChange={(_, newValue) => this.handleZipCodeChange(newValue, setFieldValue)}
                        onInputChange={(_, newValue) => this.handleZipCodeChange(newValue, setFieldValue)}
                        filterOptions={filterOptions}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <input
                              type="text"
                              {...params.inputProps}
                              style={{...webStyle.inputElement, ...((errors.zip_code || this.state.zipCodeError) && !this.state.zipCodeFieldFocusing ? webStyle.inputElementError : {})}}
                              placeholder={configJSON.zipCodePlaceholderText}
                              name="zip_code"
                              onBlur={(e) => {
                                validateField('zip_code');
                                this.setState({ zipCodeFieldFocusing: false });
                                params.inputProps?.onBlur?.(e);
                              }}
                              onFocus={() => this.setState({ zipCodeFieldFocusing: true })}
                            />
                          </div>
                        )}
                        data-test-id="zip_code"
                        ListboxComponent={StyledListBox}
                      />
                      {(errors.zip_code || this.state.zipCodeError) && !this.state.zipCodeFieldFocusing && (
                        <Tooltip
                          open={Boolean(errors.zip_code || this.state.zipCodeError)}
                          title={errors.zip_code || this.state.zipCodeError}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"full_name"}>
                        {configJSON.nameText}
                      </label>
                      <input
                        style={{...webStyle.inputElement, ...(this.state.validate && errors.full_name ? webStyle.inputElementError : {})}}
                        data-test-id="full_name"
                        type="text"
                        placeholder={configJSON.namePlaceholderText}
                        onChange={(event) => {
                          setFieldValue("full_name", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.full_name && (
                        <Tooltip
                          open={Boolean(errors.full_name)}
                          title={errors.full_name}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"email"}>
                        {configJSON.emailText}
                      </label>
                      <input
                        style={{...webStyle.inputElement, ...((this.state.validate && errors.email) || this.state.emailTextError ? webStyle.inputElementError : {})}}
                        data-test-id="email"
                        type="email"
                        placeholder={configJSON.emailPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("email", event.target.value);
                        }}
                      />
                      {((this.state.validate && errors.email) || this.state.emailTextError) && (
                        <Tooltip
                          open={Boolean(errors.email || this.state.emailTextError)}
                          title={errors.email || this.state.emailTextError}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"password"}>
                        {configJSON.passwordText}
                      </label>
                      <input
                        style={{...webStyle.inputElement, ...webStyle.inputElementPassword, ...(this.state.validate && errors.password ? webStyle.inputElementError : {})}}
                        data-test-id="password"
                        type={this.state.showPassowrd ? "text" : "password"}
                        placeholder={configJSON.passwordPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("password", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.password ? (
                        <Tooltip
                          open={Boolean(errors.password)}
                          title={errors.password}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                            data-test-id="password-warning"
                          />
                        </Tooltip>
                      ) : (
                        <IconButton
                          style={webStyle.passwordHiddenIcon}
                          onClick={() =>
                            this.setShowPassword(!this.state.showPassowrd)
                          }
                        >
                          {this.state.showPassowrd ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      )}
                      <Typography style={webStyle.passwordHelp}>
                        {configJSON.alLeastSevenLlettersText}
                      </Typography>
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label
                        style={webStyle.inputLabel}
                        htmlFor={"password_confirmation"}
                      >
                        {configJSON.confirmPasswordText}
                      </label>
                      <input
                        style={{...webStyle.inputElement, ...webStyle.inputElementPassword, ...(this.state.validate && errors.password_confirmation ? webStyle.inputElementError : {})}}
                        data-test-id="password_confirmation"
                        type={
                          this.state.showConfirmPassword ? "text" : "password"
                        }
                        placeholder={configJSON.confirmPasswordPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("password_confirmation", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.password_confirmation ? (
                        <Tooltip
                          open={Boolean(errors.password_confirmation)}
                          title={errors.password_confirmation}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      ) : 
                      (
                        <IconButton
                          style={webStyle.passwordHiddenIcon}
                          onClick={() =>
                            this.setShowConfirmPassword(!this.state.showConfirmPassword)
                          }
                        >
                          {this.state.showConfirmPassword ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldCheckboxControl}>
                      <Box
                        sx={
                          values?.term_agree
                            ? webStyle.checkboxChecked
                            : webStyle.checkboxUnchecked
                        }
                        onClick={() => {
                          setFieldValue("term_agree", !values.term_agree);
                        }}
                      >
                        <img src={successIcon} style={webStyle.successIcon} />
                      </Box>
                      <input
                        style={webStyle.checkboxElemnt}
                        data-test-id="term_agree"
                        type="checkbox"
                        onChange={(event) => {
                          setFieldValue("term_agree", event.target.checked);
                        }}
                      />
                      <Typography sx={webStyle.checkboxLabel} >
                        {configJSON.agreeoText}{" "}
                        <Button
                          variant="text"
                          sx={webStyle.termAndConditionsButton}
                          onClick={() => this.setModalShow(true)}
                        >
                          {configJSON.termsAndConditionsText}
                        </Button>
                      </Typography>
                      {this.state.validate && errors.term_agree && (
                        <Tooltip
                          open={Boolean(errors.term_agree)}
                          title={errors.term_agree}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorAcceptIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <button
                      style={webStyle.submitButton}
                      data-test-id="submitBtn"
                      type="button"
                      onClick={() => {
                        this.setFormDate(values);
                        this.setValidate(true);
                        if (Object.keys(errors).length == 0) {
                          handleSubmit();
                        }
                      }}
                    >
                      {configJSON.signUpText}
                    </button>
                  </Box>
                </form>
              )}
            </Formik>
            <Box sx={webStyle.loginRedirection}>
              <Typography style={webStyle.loginRedirectionLabel}>
                {configJSON.alreadeyHaveAnAccountText}
              </Typography>
              <button
                style={webStyle.loginRedirectionButton}
                onClick={() => this.goToLogIn()}
              >
                {configJSON.logInText}
              </button>
            </Box>
          </Box>
        </Box>
        <Box sx={webStyle.rightBlock}>
          <Box sx={webStyle.rightBlockBackground} />
          {/* Bkock right */}
        </Box>
        <Snackbar
          open={!!this.state.warningMessage}
          message={(
            <Box sx={webStyle.snackbarContent}>
              <CancelRoundedIcon sx={webStyle.snackbarIcon} />
              <Typography sx={webStyle.snackbarText}>{this.state.warningMessage}</Typography>
            </Box>
          )}
          autoHideDuration={3500}
          onClose={() => this.setState({ warningMessage: '' })}
          sx={webStyle.snackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const tooltipStyles = {
  popper: {
    sx: {
      zIndex: 1,
    }
  },
  tooltip: {
    sx: {
      color: '#FFFFFF',
      fontFamily: "'Inter', sans-serif",
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '400',
      padding: '8px 12px',
      backgroundColor: '#1E293B',
      borderRadius: '8px',
      width: 'fit-content',
      maxWidth: '480px',
      '& .MuiTooltip-arrow': {
        color: '#1E293B',
      },
    }
  }
};

const StyledListBox = styled('ul')(() => ({
  "& .MuiAutocomplete-option": {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: '#0F172A',
    padding: '8px 12px !important',
    margin: '0 8px 4px',
    borderRadius: '8px',
    '&:last-of-type': {
      margin: '0 8px',
    },
  }
}))

const webStyle = {
  signUpContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    minHeight: '100vh',
    alignItems: 'flex-start',
    "@media (max-width: 1036px)": {
      margin: 'auto 0',
      alignItems: 'center',
    },
  },
  rightBlock: {
    flex: 1,
    display: "flex",
    "@media (max-width: 1036px)": {
      display: "none",
    },
  },
  rightBlockBackground: {
    flex: 1,
    display: "flex",
    margin: "20px",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "50%",
    borderRadius: "24px",
    height: "880px",
  },
  leftBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    margin: "40px auto",
    "@media (max-width: 500px)": {
      margin: "40px",
      flexDirection: "column",
    }
  },
  logoText: {
    fontFamily: "Oswald, sans-serif",
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "24px",
    "@media (max-width: 500px)": {
      marginTop: "16px",
    }
  },
  signUpFormContainer: {
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
    fontFamily: "'Inter', sans-serif",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
  },
  formSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  fieldCheckboxControl: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: '22px 0 32px',
    position: "relative",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  inputElement: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    width: '100%',
  },
  inputElementPassword: {
    padding: '10px 52px 10px 10px',
  },
  inputElementError: {
    border: "1px solid #F87171",
  },
  passwordHelp: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    marginTop: "5px",
    marginBottom: "5px",
  },
  passwordHiddenIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  errorAcceptIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 4,
    right: 16,
  },
  checkboxLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    }
  },
  termAndConditionsButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    border: "none",
    backgroundColor: "#F8FAFC",
    padding: "0px 8px",
    textTransform: "unset",
    textDecoration: "underline",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  checkboxUnchecked: {
    border: "1px solid #64748B",
    width: "20px",
    height: "20px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    margin: "2px",
  },
  checkboxChecked: {
    border: "1px solid #1A7BA4",
    width: "20px",
    height: "20px",
    backgroundColor: "#1A7BA4",
    borderRadius: "8px",
    margin: "2px",
  },
  successIcon: {
    width: "14px",
    height: "14px",
    margin: "3px",
  },
  checkboxElemnt: {
    appearance: "none" as const,
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
  },
  loginRedirection: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "20px auto",
    width: "max-content",
  },
  loginRedirectionLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    marginRight: "8px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  loginRedirectionButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
  },
  modalBox: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    height: "80vh",
    backgroundColor: "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 810px)": {
      width: "60vw",
    }
  },
  modalScrollBox: {
    mb: 2,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    overflowY: "scroll",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "32px",
    textAlign: "left",
    flex: 1,
    color: "0F172A",
    marginTop: "20px",
    "@media (max-width: 810px)": {
      marginTop: "0px",
      lineHeight: "26px",
      fontSize: "14px",
    }
  },
  modalTitleBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "20px",
    marginTop: "-12px",
    borderBottom: "1px solid #CBD5E1",
  },
  modalTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#000000",
    "@media (max-width: 810px)": {
      fontSize: "20px",
    }
  },
  modalCloseButton: {
    color: "#64748B",
    border: "none",
    backgroundColor: "#F8FAFC",
    borderRadius: '100%',
    width: '56px',
    height: '56px',
    cursor: 'pointer',
  },
  snackbar: {
    fontFamily: "'Inter', sans-serif",
    "& .MuiPaper-root": {
      backgroundColor: '#FFFFFF',
      fontFamily: "'Inter', sans-serif !important",
      padding: '8px 16px 8px 8px',
      boxShadow: '0px 6px 15px -3px #00000026',
      borderRadius: '8px',
      "& .MuiSnackbarContent-message": {
        padding: 0,
      }
    },
    "@media (min-width: 1037px)": {
      left: '25% !important',
      transform: 'translateX(-50%)',
    },
  },
  snackbarContent: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  snackbarIcon: {
    width: '26px',
    height: '26px',
    '> path': {
      fill: '#F87171',
    }
  },
  snackbarText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#0F172A',
  }
};
// Customizable Area End
