import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Button } from '@mui/material';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import { removeStorageData } from '../../framework/src/Utilities';
import FieldRepresentativesSVGIcon from './FieldRepresentativesSVGIcon';
import SettingSVGIcon from './SettingSVGIcon';
import SupportSVGIcon from './SupportSVGIcon';
import CustomersSVGIcon from './CustomersSVGIcon';
import AnalyticsSVGIcon from './AnalyticsSVGIcon';
export const configJSON = require("../../blocks/landingpage/src/config");

export default class AdminConsoleSideBar extends Component {

  logOut = async () => {
    await removeStorageData('role');
    await removeStorageData('token');
  }

  render() {
    const isfieldRepresentative = ["/FieldRepresentativesPage"].includes(window.location.pathname)
    const isCustomers = ["/CustomersPage"].includes(window.location.pathname)
    const isCustomersDetails = ["/CustomerDetailsPage"].includes(window.location.pathname)
    const isAdminSettings = ["/admin/settings"].includes(window.location.pathname)
    const isAnalitics = ["/admin/analytics"].includes(window.location.pathname)
    const isAdminSupport = ["/admin/support"].includes(window.location.pathname)
  
    return (
      <Box sx={styles.sideBarConatiner}>
        <Box sx={styles.sideBarWrapper}>
          <Box sx={styles.listContainer}>
            <Box sx={styles.navContainer}>
              <Link to="/FieldRepresentativesPage" style={styles.linkStyle}>
                <Box sx={styles.listItem(isfieldRepresentative)}>
                  <FieldRepresentativesSVGIcon sx={styles.svg(isfieldRepresentative)} />
                  <Typography sx={styles.lisgItemText(isfieldRepresentative)}>Field representatives</Typography>
                </Box>
              </Link>
              <Link to="/CustomersPage" style={styles.linkStyle}>
                <Box sx={styles.listItem(isCustomers || isCustomersDetails)}>
                  <CustomersSVGIcon sx={styles.svg(isCustomers || isCustomersDetails)} />
                  <Typography sx={styles.lisgItemText(isCustomers || isCustomersDetails)} >Customers</Typography>
                </Box>
              </Link>
              <Link to="/admin/analytics" style={styles.linkStyle}>
                <Box sx={styles.listItem(isAnalitics)}>
                  <AnalyticsSVGIcon sx={styles.svg(isAnalitics)} />
                  <Typography sx={styles.lisgItemText(isAnalitics)}>Analytics</Typography>
                </Box>
              </Link>
              <Link to="/admin/support" style={styles.linkStyle}>
                <Box sx={styles.listItem(isAdminSupport)}>
                  <SupportSVGIcon sx={styles.svg(isAdminSupport)} />
                  <Typography sx={styles.lisgItemText(isAdminSupport)}>Support</Typography>
                </Box>
              </Link>
              <Link to="/admin/settings" style={styles.linkStyle}>
                <Box sx={styles.listItem(isAdminSettings)}>
                  <SettingSVGIcon sx={styles.svg(isAdminSettings)} />
                  <Typography sx={styles.lisgItemText(isAdminSettings)}>Settings</Typography>
                </Box>
              </Link>
            </Box>
            <Link to="/LogiInPage" style={styles.logoutButton} data-test-id="logoutButton" onClick={this.logOut}>
              <PowerSettingsNewRoundedIcon sx={styles.logoutIcon} />
              <Typography sx={styles.logoutText}>Log out</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    );
  }
}
const styles = {
  sideBarConatiner: {
    position: "relative",
    boxShadow: "0px 2px 8px 0px #00000014",
    zIndex: '100',
  },
  sideBarWrapper: {
    minHeight: "100%",
  },
  listContainer: {
    height: "100%",
    padding: "0 24px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    "@media (max-width: 1036px)": {
      padding: "0px",
    },
  },
  navContainer: {
    paddingTop: '24px',
  },
  listItem: (isActive: boolean = false) => ({
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: isActive ? 700 : 400,
    lineHeight: "24px",
    color: isActive ? "#1A7BA4" : "#64748B",
    backgroundColor:  isActive ? "#E8F9FC" : "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    width: "293px",
    height: "40px",
    padding: "8px 12px 8px 12px",
    alignItem: "center",
    margin: "0px 10px",
    display: "flex",
    flexDirectiion: "row",
    textDecoration: "none",
    "@media (max-width: 1036px)": {
      width: "40px",
    },
  }),
  linkStyle: {
    textDecoration: "none",
  },
  svg: (isActive: boolean = false) => ({
    margin: '8px 8px 8px 0',
    'path': {
      stroke: isActive ? "#1A7BA4" : '#64748B',
    },
    "@media (max-width: 1036px)": {
      margin: "8px",
    },
  }),
  lisgItemText: (isActive: boolean = false) => ({
    fontFamily: "'Inter', sans-serif",
    alignItems: "center",
    flex: 1,
    display: "flex",
    fontWeight: isActive ? 700 : 400,
    "@media (max-width: 1036px)": {
      display: "none",
    },
  }),
  logoutButton: {
    width: "100%",
    height: "40px",
    padding: "8px 12px 8px 12px",
    margin: "0px 10px 24px",
    display: "flex",
    flexDirectiion: "row",
    textDecoration: "none",
    backgroundColor: '#FFFFFF',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  logoutIcon: {
    color: '#F87171',
    margin: '0 8px',
    height: '24px',
  },
  logoutText: {
    fontFamily: "'Inter', sans-serif",
    color: '#F87171',
    fontSize: '16px',
    textTransform: 'none',
    "@media (max-width: 1036px)": {
      display: "none",
    },
  }
};
