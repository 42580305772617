import React, { useRef } from 'react';
import { Box } from "@mui/material";
import Chart, { BubbleDataPoint, ChartData, ChartTypeRegistry, Point } from 'chart.js/auto';
import {valueOrDefault} from 'chart.js/helpers';
import colorLib from '@kurkle/color';

export interface Props {
    chartData: number[];
    chartType: string;
  }

export const CustomChart = ({ chartData, chartType }: Props) => {
    const inputs = {
      min: 0,
      max: 10,
      count: 8,
      decimals: 2,
      continuity: 1
    };
  
    var _seed = Date.now();
  
    function rand(min: number, max: number) {
      min = valueOrDefault(min, 0);
      max = valueOrDefault(max, 0);
      _seed = (_seed * 9301 + 49297) % 233280;
      return min + (_seed / 233280) * (max - min);
    }
  
    function numbers(config: any) {
      var cfg = config || {};
      var min = valueOrDefault(cfg.min, 0);
      var max = valueOrDefault(cfg.max, 100);
      var from = valueOrDefault(cfg.from, []);
      var count = valueOrDefault(cfg.count, 8);
      var decimals = valueOrDefault(cfg.decimals, 8);
      var continuity = valueOrDefault(cfg.continuity, 1);
      var dfactor = Math.pow(10, decimals) || 0;
      var data = [];
      var i, value;
    
      for (i = 0; i < count; ++i) {
        value = (from[i] || 0) + rand(min, max);
        if (rand(0,0) <= continuity) {
          data.push(Math.round(dfactor * value) / dfactor);
        } else {
          data.push(null);
        }
      }
    
      return data;
    }
  
    function transparentize(value: any, opacity: number | undefined) {
      var alpha = opacity === undefined ? 0.5 : 1 - opacity;
      return colorLib(value).alpha(alpha).rgbString();
    }
  
    const CHART_COLORS = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)'
    };
    let chart: Chart<keyof ChartTypeRegistry, (number | [number, number] | Point | BubbleDataPoint | null)[], unknown>; // de
  
    const option = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            boxWidth: 15,
            font: {
              size: 14,
            }
          }
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 14,
            }
          }
        },
        y: {
          ticks: {
            font: {
              size: 14,
            }
          }
        }
      }
    };

    window.addEventListener("resize", (event) => {
      if (window.innerWidth < 320) {
        option.plugins.legend.labels.boxWidth = 8;
        option.plugins.legend.labels.font.size = 8;
        option.scales.x.ticks.font.size = 8;
        option.scales.y.ticks.font.size = 8;
      } else if (window.innerWidth < 680) {
        option.plugins.legend.labels.boxWidth = 12;
        option.plugins.legend.labels.font.size = 12;
        option.scales.x.ticks.font.size = 12;
        option.scales.y.ticks.font.size = 12;
      } else {
        option.plugins.legend.labels.boxWidth = 16;
        option.plugins.legend.labels.font.size = 16;
        option.scales.x.ticks.font.size = 16;
        option.scales.y.ticks.font.size = 16;
      }
      chart.options = option;
      chart.update();
    });

    const generateData = () => (numbers(inputs));
    const formatData = (data: number[]): ChartData => ({
      labels: ["1/1/2024", "2/1/2024", "3/1/2024", "4/1/2024", "5/1/2024", "6/1/2024", "7/1/2024", "8/1/2024"],
      datasets: [
        {
          label: 'Dataset 1',
          data: generateData(),
          borderColor: CHART_COLORS.red,
          backgroundColor: transparentize(CHART_COLORS.red, 0.5),
          fill: true
        },
        {
          label: 'Dataset 2',
          data: generateData(),
          borderColor: CHART_COLORS.blue,
          backgroundColor: transparentize(CHART_COLORS.blue, 0.5),
          fill: true
        },
        {
          label: 'Dataset 3',
          data: generateData(),
          borderColor: CHART_COLORS.green,
          backgroundColor: transparentize(CHART_COLORS.green, 0.5),
          fill: true
        },
        {
          label: 'Dataset 4',
          data: generateData(),
          borderColor: CHART_COLORS.grey,
          backgroundColor: transparentize(CHART_COLORS.grey, 0.5),
          fill: true
        },
        {
          label: 'Dataset 5',
          data: generateData(),
          borderColor: CHART_COLORS.orange,
          backgroundColor: transparentize(CHART_COLORS.orange, 0.5),
          fill: true
        },
        {
          label: 'Dataset 6',
          data: generateData(),
          borderColor: CHART_COLORS.purple,
          backgroundColor: transparentize(CHART_COLORS.purple, 0.5),
          fill: true
        },
        {
          label: 'Dataset 7',
          data: generateData(),
          borderColor: CHART_COLORS.yellow,
          backgroundColor: transparentize(CHART_COLORS.yellow, 0.5),
          fill: true
        },
      ]
    });
  
    // // use a ref to store the chart instance since it it mutable
    const chartRef = useRef<Chart | null>(null);
  
    // // callback creates the chart on the canvas element
    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
      if (!canvas) return;
      const ctx = canvas.getContext("2d");

      let chartStatus = Chart.getChart("myChart"); // <canvas> id
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }
      //-- End of chart destroy   

      if (ctx) {
        chart = new Chart(ctx, {
          type: chartType === 'bar' ? 'bar' : 'line',
          data: formatData(chartData),
          options: option,
        });

        chartRef.current = chart;
      }
    };
  
    return (
      <Box>
        <Box sx={webStyle.overflowHidden}>
          <canvas id="myChart" ref={canvasCallback}></canvas>
        </Box>
      </Box>
    );
  };

// Customizable Area Start
const webStyle = {
  overflowHidden: {
    position: "relative",
    height:"35vW",
    width:"100%",
    "@media (max-width: 1036px)": {
      height:"45vW",
    },
    "@media (max-width: 500px)": {
      height:"55vW",
    },
  }
}
// Customizable Area End
