import React, { useEffect, useState } from "react";
import { Typography, Box, InputBase, Dialog, DialogTitle, DialogContent, Divider, IconButton, Select, Radio, Tooltip, CircularProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { searchIcon, addIcon, noPropertieisIcon, errorIcon, caledarIcon, calendarErrorIcon } from "./assets";
import { styled } from "@mui/material/styles";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import moment from "moment";
import { CustomerContextMenuButton } from "./CustomerContextMenuButton.web";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Formik } from "formik";
import * as Yup from "yup";
import { GenerateReportFormProps, Property, ReportListItem } from "./CustomersPageController";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { CustomerPdfViewer } from "./CustomerPdfViewer.web";

export interface ReportMeter {
  id: string;
  meter_number: string;
  present: number;
  previous: number;
  consumptionCubicFeet?: number;
  price_id: number;
  amount: number;
  comment?: string;
  commentDate?: string;
  images?: string[];
  isPresentEstimated?: boolean;
  isPreviousEstimated?: boolean;
  reading_id: string;
}

export interface ReportTenant {
  id: string;
  name: string;
  meters: ReportMeter[];
}

export interface CustomerReportsProps {
  setSelectedReport: (reportId: string, reportType: 'Water' | 'Electric', propertyId: string) => void;
  openInvoice: (reportId: string, reportType: 'Water' | 'Electric', propertyId: string) => void;
  properties: Property[];
  reportsList: ReportListItem[];
  setReportsList: (value: ReportListItem[]) => void;
  getReportsList: () => void;
  reportsListLoading: boolean;
  generateReport: (values: GenerateReportFormProps) => void;
  isTest?: boolean;
}

export type ProRate = {
  tenant_id: string;
  tenant_name: string;
  amount: number;
};

export type Report = {
  id: string;
  propertyId: string;
  propertyName: string;
  generatedAt: string;
  type: "Water" | "Electric";
  paymentStatus: ReportPaymentStatus;
  comment: string | null;
  tenantsList: ReportTenant[];
  proRates: ProRate[];

  waterSubType?: "Hot" | "Cold" | null;
};

export enum ReportPaymentStatus {
  PAID = 'paid',
  PENDING = 'pending',
  NO_INVOICE = 'no_inovoice',
  PAST_DUE = 'past_due',
  PAST_DUE_30 = '30+ days past due',
  PAST_DUE_60 = '60+ days past due',
  PAST_DUE_90 = '90+ days past due',
  PAST_DUE_120 = '120+ days past due',
}

const itemsPerPage = 10;

const PAYMENT_STATUS_COLOR_MAP = {
  [ReportPaymentStatus.PAID]: { color: '#059669', backgroundColor: '#D1FAE5' },
  [ReportPaymentStatus.PENDING]: { color: '#D97706', backgroundColor: '#FEF3C7' },
  [ReportPaymentStatus.NO_INVOICE]: { color: '#475569', backgroundColor: '#F1F5F9' },
  [ReportPaymentStatus.PAST_DUE]: { color: '#DC2626', backgroundColor: '#FEF2F2' },
  [ReportPaymentStatus.PAST_DUE_30]: { color: '#DC2626', backgroundColor: '#FEF2F2' },
  [ReportPaymentStatus.PAST_DUE_60]: { color: '#DC2626', backgroundColor: '#FEF2F2' },
  [ReportPaymentStatus.PAST_DUE_90]: { color: '#DC2626', backgroundColor: '#FEF2F2' },
  [ReportPaymentStatus.PAST_DUE_120]: { color: '#DC2626', backgroundColor: '#FEF2F2' },
}

const PAYMENT_STATUS_TEXT_MAP = {
  [ReportPaymentStatus.PAID]: 'Paid',
  [ReportPaymentStatus.PENDING]: 'Pending',
  [ReportPaymentStatus.NO_INVOICE]: 'No invoice',
  [ReportPaymentStatus.PAST_DUE]: 'Past due',
  [ReportPaymentStatus.PAST_DUE_30]: '30+ days past due',
  [ReportPaymentStatus.PAST_DUE_60]: '60+ days past due',
  [ReportPaymentStatus.PAST_DUE_90]: '90+ days past due',
  [ReportPaymentStatus.PAST_DUE_120]: '120+ days past due',
}

export const CustomerReports = ({
  setSelectedReport,
  openInvoice,
  reportsList,
  setReportsList,
  properties,
  getReportsList,
  reportsListLoading,
  generateReport,
  isTest,
}: CustomerReportsProps) => {
  const [addReportDialogOpen, setReportDialogOpen] = useState(false);
  const [validate, setValidate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [getReportsListRequestFired, setReportsListRequestFired] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  
  const filteredReportList = searchText ? reportsList.filter((meter) => meter.report_id?.toLowerCase().includes(searchText.toLowerCase())) : reportsList;
  const totalPages = Math.ceil(filteredReportList.length / itemsPerPage) || 1;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const displayedReports = filteredReportList.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    getReportsList();
    setReportsListRequestFired(true);
  }, []);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(reportsList.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePaymentStatusChange = (value: any, index: number) => {
    setReportsList(
      reportsList.map((report, reportIndex) => ({
        ...report,
        payment_status: index === reportIndex ? value : report.payment_status,
      }))
    );
  };

  const questionSchema = Yup.object().shape({
    propertyId: Yup.string().required('Property is required'),
    meterType: Yup.string(),
    waterType: Yup.string(),
    dateFrom: Yup.string().required("From date is required"),
    dateTo: Yup.string()
      .test('is-after-fromDate', 'To date must be after From date', function (value) {
        const { dateFrom } = this.parent;
        return dateFrom && value && new Date(value) > new Date(dateFrom);
      })
      .required("To date is required"),
  })

  const handleDialogClose = () => {
    setReportDialogOpen(false);
    setValidate(false);
  }

  const handleSendReportToCustomer = (reportId: string) => {
    setReportsList(reportsList.map((report) => ({
      ...report,
      reportSent: report.report_id === reportId ? true : report.report_sent,
    })));
  }

  const handleSendInvoiceToCustomer = (reportId: string) => {
    setReportsList(reportsList.map((report) => ({
      ...report,
      invoiceSent: report.report_id === reportId ? true : report.invoice_sent,
    })));
  }

  const getSendToCustomerMenuItem = (isSent?: boolean) => {
    if (isSent) {
      return (
        <Box sx={webStyle.sentToCustomerWrapper}>
          <CheckRoundedIcon sx={webStyle.checkmarkIcon} />
          <Box>Sent</Box>
        </Box>
      )
    }

    return 'Send to customer';
  }

  if (reportsListLoading || (!getReportsListRequestFired && !isTest)) {
    return (
      <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
        <CircularProgress sx={webStyle.loader} />
      </Box>
    );
  }

  return (
    <Box sx={webStyle.reportsContainer}>
      {reportsList.length === 0 ? (
        <Box sx={webStyle.noReportsBlock}>
          <Box sx={webStyle.noReportsBlockTitle}>
            <img src={noPropertieisIcon} style={webStyle.mainImage} />
            <Typography style={webStyle.noReportsTitle}>No reports</Typography>
            <Typography style={webStyle.noReportsSubTitle}>
              Click on the button below to start adding your reports
            </Typography>
            <Box sx={webStyle.buttonBlock}>
              <button
                style={webStyle.addNewButton}
                data-test-id="submitBtn"
                type="button"
                onClick={() => setReportDialogOpen(true)}
              >
                <Typography sx={webStyle.buttonText}>Add report</Typography>
              </button>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={webStyle.reportsTableHeader}>
            <Box sx={webStyle.searchContainer}>
              <Box sx={webStyle.fieldControl}>
                <StyledInput
                    type="text"
                    data-test-id="search"
                    placeholder="Search"
                    onChange={(event) => {
                      setSearchText(event.target.value);
                      currentPage !== 1 && setCurrentPage(1);
                    }}
                />
                <img src={searchIcon} alt="" style={webStyle.searchIcon} />
              </Box>
            </Box>
            <Box sx={webStyle.actionContainer}>
              <button
                style={webStyle.addReportButton}
                onClick={() => setReportDialogOpen(true)}
                data-test-id="addReport"
              >
                <img src={addIcon} alt="" style={webStyle.btnIcon} />
                <Typography sx={webStyle.buttonText}>Add report</Typography>
              </button>
            </Box>
          </Box>
          <Box>
            <Typography
              fontFamily={"'Inter', sans-serif"}
              fontSize={"16px"}
              color={"#334155"}
              fontWeight={500}
              marginBottom={"16px"}
            >
              {filteredReportList.length} results
            </Typography>
          </Box>
          {filteredReportList.length > 0 && (
            <Box sx={{ minHeight: totalPages > 1 ? "674px" : "unset" }}>
            <Box sx={webStyle.reportsTable}>
              <Box sx={webStyle.reportsTableHead}>
                <Box sx={webStyle.reportsDataTableHead}>
                  <Typography style={webStyle.headerTitle}>REPORT #</Typography>
                  <Typography style={webStyle.headerTitle}>PROPERTY</Typography>
                  <Typography style={webStyle.headerTitle}>
                    GENERATED AT
                  </Typography>
                  <Typography style={webStyle.headerTitle}>TYPE</Typography>
                  <Typography style={webStyle.headerTitle}>
                    PAYMENT STATUS
                  </Typography>
                </Box>
                <Typography style={webStyle.headerTitleAction}>
                  ACTIONS
                </Typography>
              </Box>
              {displayedReports
                .map((report: ReportListItem, index: number) => (
                  <Box sx={webStyle.reportsTableBody} key={report.report_id}>
                    <Box sx={webStyle.reportsDataTableBody}>
                      <Box sx={webStyle.reportItem}>{report.report_id}</Box>
                      <Box sx={webStyle.reportItem}>{report.property_name}</Box>
                      <Box sx={webStyle.reportItem}>
                        {moment(report.generated_at, 'DD/MM/YYYY').format("MM/DD/YYYY")}
                      </Box>
                      <Box sx={webStyle.reportItem}>{report.meter_type}</Box>
                      <Box sx={webStyle.reportItem}>
                        <StyledSelect
                          defaultValue={report.payment_status}
                          sx={webStyle.select(report.payment_status)}
                          onChange={(event) =>
                            handlePaymentStatusChange(event.target.value, index)
                          }
                        >
                          {Object.values(ReportPaymentStatus).map((value) => {
                            const { color, backgroundColor } = PAYMENT_STATUS_COLOR_MAP[value];

                            return (
                              <MenuItem value={value} sx={webStyle.selectOption} key={value}>
                                <Box
                                  sx={webStyle.selectOptionText(
                                    color,
                                    backgroundColor
                                  )}
                                >
                                  {PAYMENT_STATUS_TEXT_MAP[value]}
                                </Box>
                              </MenuItem>
                            );
                          })}
                        </StyledSelect>
                      </Box>
                    </Box>
                    <Box sx={webStyle.reportItemAction} data-test-id="tableActions">
                      <CustomerContextMenuButton buttonText="Report">
                        <MenuItem sx={webStyle.contextMenuOption} onClick={() => setSelectedReport(report.report_id, report.meter_type, report.property_id || '180')}>
                            Edit
                        </MenuItem>
                        <MenuItem sx={webStyle.contextMenuOption} onClick={() => setPdfUrl('pdfFile')}>
                            View as PDF
                        </MenuItem>
                        <MenuItem sx={webStyle.contextMenuOption} onClick={() => !report.report_sent && handleSendReportToCustomer(report.report_id)}>
                          {getSendToCustomerMenuItem(report.report_sent)}
                        </MenuItem>
                      </CustomerContextMenuButton>
                      <CustomerContextMenuButton buttonText="Invoice">
                        <MenuItem sx={webStyle.contextMenuOption} onClick={() => openInvoice(report.report_id, report.meter_type, report.property_id || '180')}>
                            Edit
                        </MenuItem>
                        <MenuItem sx={webStyle.contextMenuOption} onClick={() => setPdfUrl('pdfFile')}>
                            View as PDF
                        </MenuItem>
                        <MenuItem sx={webStyle.contextMenuOption} onClick={() => !report.invoice_sent && handleSendInvoiceToCustomer(report.report_id)}>
                          {getSendToCustomerMenuItem(report.invoice_sent)}
                        </MenuItem>
                      </CustomerContextMenuButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {totalPages > 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "5%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Previous Button */}
                <button
                  onClick={handlePrevPage}
                  data-test-id="previous-button"
                  style={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                    marginRight: "4px",
                  }}
                  disabled={currentPage === 1}
                >
                  <KeyboardArrowLeftRoundedIcon
                    sx={
                      currentPage === 1
                        ? webStyle.chevronIconDisabled
                        : webStyle.chevronIconActive
                    }
                  />
                </button>

                {totalPages <= 3 ? (
                  [...Array(totalPages).keys()].map((page) => (
                    <button
                      key={page + 1}
                      data-test-id={"page-button"}
                      style={{
                        marginRight: "4px",
                        width: currentPage === page + 1 ? "24px" : "16px",
                        height: "24px",
                        backgroundColor:
                          currentPage === page + 1 ? "#DBF5FF" : "inherit",
                        color: currentPage === page + 1 ? "#1A7BA4" : "#64748B",
                        border: "none",
                        textDecoration:
                          currentPage !== page + 1 ? "underline" : "none",
                        borderRadius: "8px",
                        fontWeight: currentPage !== page + 1 ? 400 : 700,
                        cursor: "pointer",
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() => handlePageChange(page + 1)}
                    >
                      {page + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {currentPage !== 1 && (
                      <button
                        data-test-id="ellipsis"
                        style={{
                          marginRight: "4px",
                          width: "16px",
                          height: "24px",
                          backgroundColor: "inherit",
                          color: "#64748B",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => handlePageChange(1)}
                      >
                        1
                      </button>
                    )}

                    {currentPage > 3 && (
                      <span style={{ marginRight: "4px", color: "#64748B" }}>
                        ...
                      </span>
                    )}

                    {currentPage > 2 && (
                      <button
                        data-test-id="middle-page"
                        style={{
                          marginRight: "4px",
                          width: "24px",
                          height: "24px",
                          backgroundColor: "inherit",
                          color: "#64748B",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        {currentPage - 1}
                      </button>
                    )}
                    <button
                      data-test-id="middle-page2"
                      style={{
                        marginRight: "4px",
                        width: "24px",
                        height: "24px",
                        backgroundColor: "#DBF5FF",
                        color: "#1A7BA4",
                        border: "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontWeight: 700,
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() => handlePageChange(currentPage)}
                    >
                      {currentPage}
                    </button>

                    {currentPage < totalPages - 1 && (
                      <button
                        data-test-id="middle-page3"
                        style={{
                          marginRight: "4px",
                          width: "16px",
                          height: "24px",
                          color: "#64748B",
                          backgroundColor: "inherit",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        {currentPage + 1}
                      </button>
                    )}

                    {currentPage < totalPages - 2 && (
                      <span style={{ marginRight: "4px", color: "#64748B" }}>
                        ...
                      </span>
                    )}

                    {currentPage !== totalPages && (
                      <button
                        data-test-id="last-page"
                        style={{
                          marginRight: "4px",
                          width: currentPage === totalPages ? "24px" : "16px",
                          height: "24px",
                          backgroundColor:
                            currentPage === totalPages ? "#DBF5FF" : "inherit",
                          color:
                            currentPage === totalPages ? "#1A7BA4" : "#64748B",
                          border: "none",
                          textDecoration:
                            currentPage !== totalPages ? "underline" : "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                          fontWeight: currentPage !== totalPages ? 400 : 700,
                        }}
                        onClick={() => handlePageChange(totalPages)}
                      >
                        {totalPages}
                      </button>
                    )}
                  </>
                )}

                <button
                  data-test-id="next-page"
                  onClick={() => handleNextPage()}
                  style={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                  }}
                  disabled={currentPage === totalPages}
                >
                  <KeyboardArrowRightRoundedIcon
                    sx={
                      currentPage === totalPages
                        ? webStyle.chevronIconDisabled
                        : webStyle.chevronIconActive
                    }
                  />
                </button>
              </Box>

              <Box sx={{ marginRight: "10px" }}>
                <Typography
                  fontFamily={'"Nunito Sans", sans-serif'}
                  fontSize={"12px"}
                  color={"grey"}
                >
                  {indexOfFirstItem + 1}-{indexOfLastItem} of{" "}
                  {filteredReportList.length} results
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
      <Dialog
        open={addReportDialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "32px",
            maxHeight: "calc(100% - 120px)",
          },
        }}
        data-test-id="addReportDialog"
      >
        <DialogTitle style={{ padding: "0 0 32px", position: "relative" }}>
          <Typography variant="h6" sx={webStyle.dialogTitleText}>
            Add report
          </Typography>
          <IconButton
            onClick={handleDialogClose}
            style={{
              position: "absolute",
              right: "0",
              top: "-8px",
              width: "56px",
              height: "56px",
              backgroundColor: "#F8FAFC",
              borderRadius: "52px",
            }}
          >
            <CloseRoundedIcon
              sx={{ width: "24px", height: "24px", color: "#64748B" }}
            />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{ padding: 0, overflowY: "unset", margin: "32px 0 0" }}
        >
          <Formik
            initialValues={{
              propertyId: "",
              meterType: 'Water',
              waterType: 'Cold',
              dateFrom: '',
              dateTo: '',
            }}
            validateOnChange={true}
            validationSchema={questionSchema}
            onSubmit={(values: GenerateReportFormProps) => {
              generateReport(values);
            }}
            data-test-id="serviceChargeForm"
          >
            {({ setFieldValue, values, errors, handleSubmit }) => (
              <form style={{ margin: 0 }}>
                <Box>
                  <Box sx={webStyle.propertyWrapper}>
                    <label style={webStyle.inputLabel}>Property</label>
                    <Select
                      value={values.propertyId}
                      onChange={(event) => setFieldValue('propertyId', event.target.value)}
                      sx={{...webStyle.dialogSelect, ...(values.propertyId ? { color: '#0F172A' } : undefined)}}
                      renderValue={(select) => {
                        if (!select) {
                          return 'Select property...';
                        }

                        return properties.find((property) => property.id === select)?.property_name;
                      }}
                      displayEmpty
                    >
                      {properties.map((property) => (
                        <MenuItem sx={webStyle.dialogSelectOption} value={property.id} key={property.id}>{property.property_name}</MenuItem>
                      ))}
                    </Select>
                    {validate && errors.propertyId && (
                      <Tooltip
                        open={Boolean(errors.propertyId)}
                        title={errors.propertyId}
                        componentsProps={tooltipStyles}
                        arrow
                      >
                        <img
                          src={errorIcon}
                          alt=""
                          style={webStyle.errorIcon}
                        />
                      </Tooltip>
                    )}
                  </Box>
                  <Box sx={webStyle.radioWrapper}>
                    <label style={webStyle.inputLabel}>Meter</label>
                    <Box sx={webStyle.radioButtonGroup}>
                      <Box sx={webStyle.radioButtonWrapper}>
                        <Radio
                          sx={webStyle.radioButton}
                          data-test-id={`meterType.water`}
                          name={`meter_type_water`}
                          value={"Water"}
                          checked={"Water" === values?.meterType}
                          onChange={(event) => {
                            setFieldValue(`meterType`, event.currentTarget.value);
                          }}
                        />
                        <label
                          style={webStyle.radioButtonLabel}
                          htmlFor="meter_type_water"
                        >
                          Water
                        </label>
                        
                      </Box>
                      <Box sx={webStyle.radioButtonWrapper}>
                        <Radio
                            sx={webStyle.radioButton}
                            data-test-id={`meterType.electrical`}
                            name={`meter_type_electrical`}
                            value={"Electric"}
                            checked={"Electric" === values?.meterType}
                            onChange={(event) => {
                              setFieldValue(`meterType`, event.currentTarget.value);
                            }}
                          />
                          <label
                            style={webStyle.radioButtonLabel}
                            htmlFor="electrical"
                          >
                            Electrical
                          </label>
                      </Box>
                    </Box>
                  </Box>
                  {values?.meterType?.toLowerCase() === "water" && (
                    <Box sx={webStyle.radioWrapper}>
                      <label style={webStyle.inputLabel}>Type</label>
                      <Box sx={webStyle.radioButtonGroup}>
                        <Box sx={webStyle.radioButtonWrapper}>
                          <Radio
                            sx={webStyle.radioButton}
                            data-test-id={`waterType.cold`}
                            name={`water_type_cold`}
                            value={"Cold"}
                            checked={"Cold" === values?.waterType}
                            onChange={(event) => {
                              setFieldValue(`waterType`, event.currentTarget.value);
                            }}
                          />
                          <label
                            style={webStyle.radioButtonLabel}
                            htmlFor="water_type_cold"
                          >
                            Cold
                          </label>
                        </Box>
                        <Box sx={webStyle.radioButtonWrapper}>
                          <Radio
                              sx={webStyle.radioButton}
                              data-test-id={`waterType.hot`}
                              name={`water_type_hot`}
                              value={"Hot"}
                              checked={"Hot" === values?.waterType}
                              onChange={(event) => {
                                setFieldValue(`waterType`, event.currentTarget.value);
                              }}
                            />
                            <label
                              style={webStyle.radioButtonLabel}
                              htmlFor="water_type_hot"
                            >
                              Hot
                            </label>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <Box sx={webStyle.datesWrapper}>
                    <Box sx={webStyle.dateWrapper}>
                      <label style={webStyle.inputLabel}>From</label>
                      <Box position="relative" width="fit-content">
                        <StyledInput
                          data-test-id={`dateFrom`}
                          type="date"
                          placeholder="mm/dd/yyyy"
                          value={values?.dateFrom ? moment(values.dateFrom).format('YYYY-MM-DD') : ''}
                          onChange={(event) => {
                            setFieldValue(`dateFrom`, event.target.value);
                          }}
                          style={!values?.dateFrom ? { color: '#94A3B8' } : undefined}
                          sx={{...(validate && errors.dateTo ? webStyle.inputElementError : undefined), ...{ padding: '10px', width: '200px', height: '56px' }}}
                        />
                        <Tooltip
                          open={validate && Boolean(errors.dateFrom)}
                          title={errors.dateFrom}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={validate && !!errors.dateFrom ? calendarErrorIcon : caledarIcon}
                            alt=""
                            style={webStyle.calendarIcon as any}
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box sx={webStyle.dateWrapper}>
                      <label style={webStyle.inputLabel}>To</label>
                      <Box position="relative" width="fit-content">
                        <StyledInput
                          data-test-id={`dateTo`}
                          type="date"
                          placeholder="mm/dd/yyyy"
                          value={values?.dateTo ? moment(values.dateTo).format('YYYY-MM-DD') : ''}
                          onChange={(event) => {
                            setFieldValue(`dateTo`, event.target.value);
                          }}
                          style={!values?.dateTo ? { color: '#94A3B8' } : undefined}
                          sx={{...(validate && errors.dateTo ? webStyle.inputElementError : undefined), ...{ padding: '10px', width: '200px', height: '56px' }}}
                        />
                        <Tooltip
                          open={validate && !!errors.dateTo}
                          title={errors.dateTo}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={validate && !!errors.dateTo ? calendarErrorIcon : caledarIcon}
                            alt=""
                            style={webStyle.calendarIcon as any}
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider style={{ marginTop: '32px' }} />
                <Box sx={webStyle.dialogButtonsWrapper}>
                  <button
                    style={webStyle.cancelButton}
                    data-test-id="cancelDialogButton"
                    type="button"
                    onClick={handleDialogClose}
                  >
                    Cancel
                  </button>
                  <button
                    style={webStyle.submitButton}
                    data-test-id="submitDialogButton"
                    type="button"
                    onClick={() => {
                      setValidate(true);
                      if (Object.keys(errors).length == 0) {
                        handleSubmit();
                      }
                    }}
                  >
                    Generate report
                  </button>
                </Box>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <CustomerPdfViewer pdfUrl={pdfUrl} onClose={() => setPdfUrl(null)} />
    </Box>
  );
};

const tooltipStyles = {
  tooltip: {
    sx: {
      color: '#FFFFFF',
      fontFamily: "'Inter', sans-serif",
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '400',
      padding: '8px 12px',
      backgroundColor: '#1E293B',
      borderRadius: '8px',
      width: 'fit-content',
      maxWidth: '480px',
      '& .MuiTooltip-arrow': {
        color: '#1E293B',
      },
    }
  }
};

const StyledInput = styled(InputBase)(() => ({
    height: "44px",
    padding: "12px 44px 12px 12px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    fontFamily: "'Inter', sans-serif",

    '& .MuiInputBase-input': {
      fontSize: '16px',

      '&::placeholder': {
        fontFamily: "'Inter', sans-serif",
        color: '#94A3B8',
        fontSize: '16px',
        opacity: 1, // Firefox requires this
      },
    },
  }));

const StyledSelect = styled(Select)(() => ({
  borderRadius: "100px",
  height: "22px",
  fontSize: "12px",
  fontWeight: 700,
  "& .MuiSelect-icon": {
    borderRadius: "0 100px 100px 0",
    borderLeft: "1px solid #FFFFFF",
    height: "22px",
    right: 0,
    top: 0,
    backgroundColor: "#F1F5F9",
  },
  "& .MuiSelect-iconOpen": {
    borderLeft: 'none',
    borderRadius: '100px 0 0 100px',
    borderRight: '1px solid #FFFFFF',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 'none',
  },
}));

const webStyle = {
  reportsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  reportsTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    width: "340px",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  reportsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  reportsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    backgroundColor: "#F8FAFC",
    borderRadius: "12px 12px 0 0",
  },
  reportsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    "&:last-of-type": {
      borderRadius: "0 0 12px 12px",
    },
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputElement: {
    height: "44px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchElement: {
    padding: "10px 40px 10px 10px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  addReportButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 16px",
    gap: "8px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "20%",
    margin: "12px 0px",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "215px",
    margin: "12px 0px",
  },
  editButton: {
    backgroundColor: "#1A7BA4",
    color: "#FFFFFF",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 8px",
    borderRadius: "7px",
    border: "none",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 8px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
    justifyContent: "center",
  },
  disabledButton: {
    backgroundColor: "rgba(26, 123, 164, 0.4)",
  },
  reportItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "20%",
    margin: "13px 0px",
    alignItems: "center",
    display: "flex",
    wordBreak: "break-word",
  },
  reportItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "215px",
    display: "flex",
    flexDirection: "row",
    margin: "13px 0px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noReportsBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noReportsBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noReportsTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  noReportsSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  addNewButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px auto",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "430px",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  buttonBlock: {
    display: "flex",
    flexDirection: "row",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  select: (paymentStatus: ReportPaymentStatus) => {
    const { color, backgroundColor } = PAYMENT_STATUS_COLOR_MAP[paymentStatus];

    return {
      "& .MuiSelect-select": {
        padding: "0 24px 0 0 !important",
      },
      fontFamily: "'Inter', sans-serif",
      backgroundColor,
      color,
    };
  },
  selectOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    padding: "8px 8px",
    margin: "0",
    borderRadius: "0",
    "&:last-of-type": {
      margin: "0",
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  selectOptionText: (color: string, backgroundColor: string) => ({
    padding: "2px 8px",
    fontSize: "12px",
    fontWeight: 700,
    borderRadius: "200px",
    color,
    backgroundColor,
  }),
  chevronIconActive: {
    "> path": {
      fill: "#475569",
    },
  },
  chevronIconDisabled: {
    "> path": {
      fill: "#64748B",
    },
  },
  reportsDataTableHead: {
    width: "calc(100% - 215px)",
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  reportsDataTableBody: {
    width: "calc(100% - 215px)",
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  contextMenuOption: {
    fontFamily: "'Inter', sans-serif"
  },
  dialogTitleText: {
    fontWeight: "700",
    fontSize: "24px",
    fontFamily: "'Inter', sans-serif",
  },
  dialogButtonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "32px"
  },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "transparent",
    color: "#1A7BA4",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "0 0 0 16px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  dialogSelectOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    borderRadius: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "#E8FBFF",
      color: "#1A7BA4",
      fontWeight: "600",
    },
  },
  dialogSelect: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    height: "56px",
    borderRadius: '8px',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CBD5E1',
    }
  },
  radioButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  radioButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flex: 1,
    width: '100%',
  },
  radioButton: {
    padding: 0,
    width: '20px',
    height: '20px',
    '& .MuiSvgIcon-root': {
      color: '#64748B',
      width: '20px',
      height: '20px',
    },
    '&.Mui-checked': {
      backgroundColor: '#1A7BA4',
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
      '& .MuiSvgIcon-root:first-of-type': {
        color: '#1A7BA4',
      },
      '& .MuiSvgIcon-root:nth-of-type(2)': {
        color: '#FFFFFF',
      }
    },
  },
  radioButtonLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
  },
  inputElementError: {
    border: "1px solid #F87171",
  },
  datesWrapper: {
    display: 'flex',
  },
  dateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  radioWrapper: {
    margin: '16px 0',
  },
  propertyWrapper: {
    position: "relative",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 8,
    backgroundColor: '#FFFFFF',
  },
  calendarIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 18,
    right: 13,
    backgroundColor: '#FFFFFF',
    pointerEvents: 'none',
  },
  checkmarkIcon: {
    width: '18px',
    height: '18px',
    color: '#059669',
  },
  sentToCustomerWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    backgroundColor: '#D1FAE5',
    color: '#059669',
    padding: '0 8px',
    borderRadius: '100px',
    fontFamily: "'Inter', sans-serif",
  },
  loaderWrapper: {
   margin: '200px auto 0',
   width: 'fit-content',
  },
  loader: {
   color: '#1A7BA4',
   height: '60px',
   width: '60px',
  }
};
