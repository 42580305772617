import React from "react";
// Customizable Area Start
import {
  searchIcon,
  editIcon,
  noPropertieisIcon,
} from "./assets";
import { Typography, Box, CircularProgress } from "@mui/material";

// Customizable Area End

import CustomersPageController, {
  Props,
  configJSON,
} from "./CustomersPageController";
import { borderRadius } from "react-select/src/theme";
// Customizable Area Start
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

// Customizable Area End
export default class CustomersPage extends CustomersPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.customersContainer}>
        {this.state.companyListLoading ? (
          <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
            <CircularProgress sx={webStyle.loader} />
          </Box>
        ) : this.state.companyList.length === 0 ? (
          <Box sx={webStyle.noPropertyBlock}>
            <Box sx={webStyle.noPropertyBlockTitle}>
              <img src={noPropertieisIcon} style={webStyle.mainImage} />
              <Typography style={webStyle.noPropertyTitle}>
                {configJSON.noPropertyTitleText}
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={webStyle.customersTableHeader}>
            <Typography sx={webStyle.customerTitle}>
                Customers
              </Typography>
              <Box sx={webStyle.searchContainer}>
                <Box sx={webStyle.fieldControl}>
                  <input
                    style={webStyle.inputElemnt}
                    data-test-id="search"
                    type={"text"}
                    placeholder={configJSON.searchPlaceholderText}
                    onChange={(event) => {
                      this.searchcompany(event.target.value);
                    }}
                  />
                  <img src={searchIcon} alt="" style={webStyle.searchIcon} />
                </Box>
                <Typography sx={webStyle.customerCount}>
                {this.state.filteredCompanies.length + " results"}
              </Typography>
              </Box>
             
            </Box>
            {this.state.filteredCompanies.length > 0 && (
            <Box sx={this.state.totalPages > 1 ? { height: '674px' } : undefined}>
            <Box sx={webStyle.propertisTable}>
              <Box sx={webStyle.propertisTableHead}>
                <Box sx={webStyle.propertisDataTableHead}>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.companyNameText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.addressText}
                  </Typography>
                  <Typography style={webStyle.headerShortTitle}>
                    {configJSON.createdAtText}
                  </Typography>
                  <Typography style={webStyle.headerShortTitle}>
                    {configJSON.lastLoggedInText}
                  </Typography>
                </Box>
                <Typography style={webStyle.headerTitleAction}>
                  {configJSON.actionsText}
                </Typography>
              </Box>
              {this.state.currentcompany.map(company => (
            <Box sx={webStyle.propertisTableBody} key={company.id}>
              <Box sx={webStyle.propertyItem}>{company.companyName}</Box>
              <Box sx={webStyle.propertyItem}>{company.serviceAdress}</Box>
              <Box sx={webStyle.propertyShortItem}>{company.createdAT}</Box>
              <Box sx={webStyle.propertyShortItem}>{company.lastLoggedIn}</Box>
              <Box sx={webStyle.propertyItemAction}>
                      <button data-test-id="viewDetails" style={webStyle.editButton} onClick={() => {
                        this.companyDetails(company.id);
                      }}>
                  <VisibilityOutlinedIcon sx={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>{configJSON.viewDetailsText}</Typography>
                </button>
              </Box>
            </Box>
            ))}
            </Box>
            </Box>
            )}
            {this.state.totalPages > 1 &&
                <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Previous Button */}
                  <button
                    onClick={() => this.handlePrevPage()}
                    data-test-id="previous-button"
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                      marginRight: "4px",
                    }}
                    disabled={this.state.currentPage === 1}
                  >
                    <KeyboardArrowLeftRoundedIcon sx={this.state.currentPage === 1 ? webStyle.chevronIconDisabled : webStyle.chevronIconActive} />
                  </button>

                  {this.state.totalPages <= 3 ? (
                    [...Array(this.state.totalPages).keys()].map((page) => (
                      <button
                        key={page + 1}
                        data-test-id={"page-button"}
                        style={{
                          marginRight: "4px",
                          width: this.state.currentPage === page + 1 ? '24px' : '16px',
                          height: '24px',
                          backgroundColor:
                            this.state.currentPage === page + 1
                              ? "#DBF5FF"
                              : "inherit",
                          color:
                            this.state.currentPage === page + 1
                              ? "#1A7BA4"
                              : "#64748B",
                          border: "none",
                          textDecoration:
                            this.state.currentPage !== page + 1
                              ? "underline"
                              : "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: '12px',
                          fontFamily: "'Inter', sans-serif",
                          fontWeight:
                            this.state.currentPage !== page + 1
                                ? 400
                                : 700,
                        }}
                        onClick={() => this.handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </button>
                    ))
                  ) : (
                    <>
                      {this.state.currentPage !== 1 && (
                        <button
                          data-test-id="ellipsis"
                          style={{
                            marginRight: "4px",
                            width: '16px',
                            height: '24px',
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif"
                          }}
                          onClick={() => this.handlePageChange(1)}
                        >
                          1
                        </button>
                      )}

                      {this.state.currentPage > 3 && (
                        <span style={{ marginRight: "4px", color: '#64748B' }}>...</span>
                      )}

                      {this.state.currentPage > 2 && (
                        <button
                          data-test-id="middle-page"
                          style={{
                            marginRight: "4px",
                            width: '24px',
                            height: '24px',
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif"
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage - 1)
                          }
                        >
                          {this.state.currentPage - 1}
                        </button>
                      )}

                      <button
                        data-test-id="middle-page2"
                        style={{
                          marginRight: "4px",
                          width: '24px',
                          height: '24px',
                          backgroundColor: "#DBF5FF",
                          color: "#1A7BA4",
                          border: "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontWeight: 700,
                          fontSize: '12px',
                          fontFamily: "'Inter', sans-serif"
                        }}
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage)
                        }
                      >
                        {this.state.currentPage}
                      </button>

                      {this.state.currentPage < this.state.totalPages - 1 && (
                        <button
                          data-test-id="middle-page3"
                          style={{
                            marginRight: "4px",
                            width: '16px',
                            height: '24px',
                            color: "#64748B",
                            backgroundColor: "inherit",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif"
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage + 1)
                          }
                        >
                          {this.state.currentPage + 1}
                        </button>
                      )}

                      {this.state.currentPage < this.state.totalPages - 2 && (
                        <span style={{ marginRight: "4px", color: '#64748B' }}>...</span>
                      )}

                      {this.state.currentPage !== this.state.totalPages && (
                        <button
                          data-test-id="last-page"
                          style={{
                            marginRight: "4px",
                            width: this.state.currentPage === this.state.totalPages ? '24px' : '16px',
                            height: '24px',
                            backgroundColor:
                              this.state.currentPage === this.state.totalPages
                                ? "#DBF5FF"
                                : "inherit",
                            color:
                              this.state.currentPage === this.state.totalPages
                                ? "#1A7BA4"
                                : "#64748B",
                            border: "none",
                            textDecoration:
                              this.state.currentPage !== this.state.totalPages
                                ? "underline"
                                : "none",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: '12px',
                            fontFamily: "'Inter', sans-serif",
                            fontWeight:
                              this.state.currentPage !== this.state.totalPages
                                  ? 400
                                  : 700,
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.totalPages)
                          }
                        >
                          {this.state.totalPages}
                        </button>
                      )}
                    </>
                  )}

                  <button
                    data-test-id="next-page"
                    onClick={() => this.handleNextPage()}
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    disabled={this.state.currentPage === this.state.totalPages}
                  >
                    <KeyboardArrowRightRoundedIcon sx={this.state.currentPage === this.state.totalPages ? webStyle.chevronIconDisabled : webStyle.chevronIconActive} />
                  </button>
                </Box>
  
                <Box sx={{ marginRight: "10px", width: "120px"}}>
                  <Typography
                    fontFamily={"'Inter', sans-serif"}
                    fontSize={"12px"}
                    color={"grey"}
                  >
                    {this.state.firstItemIndex + 1}-{this.state.lastItemIdex} of{" "}
                    {this.state.filteredCompanies.length} results
                  </Typography>
                </Box>
              </Box>
            }
          </>
        )}
      </Box>
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  customersContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 30px 40px",
    height: "100%",
    backgroundColor:"#F8FAFC"
  },
  customersTableHeader: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "40px 0px 0px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    width: "340px",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  propertisTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  propertisTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderRadius: '12px 12px 0 0',
  },
  propertisDataTableHead: {
    width: "calc(100% - 150px)",
    display: "flex",
    flexDirection: "row",
  },
  propertisTableBody: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    "&:last-of-type": {
      borderRadius: "0 0 12px 12px",
    },
  },
  propertisDataTableBody: {
    width: "calc(100% - 150px)",
    display: "flex",
    flexDirection: "row",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px 40px 10px 10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginBottom:"24px"
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "20px",
    height: "20px",
    marginRight: "6px",
    color: '#1A7BA4',
  },
  addcustomersButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "7px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 10px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "30%",
    margin: "12px 0",
  },
  headerShortTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "20%",
    overflow: "hidden",
    margin: "12px 0",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "150px",
    margin: "12px 0",
  },
  addTenantButton: {
    backgroundColor: "#1A7BA4",
    color: "#FFFFFF",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "none",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  editButton: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 8px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
    whiteSpace:"no-wrap"
  },
  deleteButton: {
    backgroundColor: "#FFFFFF",
    color: "#DC2626",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #DC2626",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  propertyItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "30%",
    wordBreak: 'break-word',
    margin: "13px 0",
    alignItems: "center",
    display: "flex",
  },
  propertyShortItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "20%",
    wordBreak: 'break-word',
    margin: "13px 0",
    alignItems: "center",
    display: "flex",
  },
  propertyItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    minWidth: "150px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 0",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noPropertyBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noPropertyBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noPropertyTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  noPropertySubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  addNewButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px auto",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "430px",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  buttonBlock: {
    display: "flex",
    flexDirection: "row",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  customerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
    marginBottom:"24px"
  },
  customerCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#334155",
    marginBottom:"16px"
  },
  chevronIconActive: {
    '> path': {
      fill: '#475569',
    }
  },
  chevronIconDisabled: {
    '> path': {
      fill: '#64748B',
    }
  },
  loaderWrapper: {
    margin: '200px auto 0',
    width: 'fit-content',
   },
   loader: {
    color: '#1A7BA4',
    height: '60px',
    width: '60px',
   },
};
// Customizable Area End
