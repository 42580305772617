import React from "react";
// Customizable Area Start

import {
  Typography,
  Box,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
  Tabs,
  Tab,
  Chip,
  Autocomplete,
  TextField,
  Tooltip,
  createFilterOptions,
  Snackbar,
} from "@mui/material";

import { listOfStates, listOfCities, getStateByCity } from "./listOfCities";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { addIcon, errorIcon, removeIcon } from "./assets";
import supportedZipCodes from "./supportedZipCodes";
import { styled } from "@mui/material/styles";
// Customizable Area End

import SettingsPageController, {
  AccountSettings,
  Props,
  configJSON,
} from "./SettingsPageController";
// Customizable Area Start
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Switch, { SwitchProps } from "@mui/material/Switch";

const filterOptions = createFilterOptions({
  matchFrom: "start",
});

// Customizable Area End
export default class SettingsPage extends SettingsPageController {
  constructor(props: Props) {
    super(props);
  }

  companyDetailSchema = () => {
    return Yup.object().shape({
      company_name: Yup.string().trim().required("Company Name is required"),
      mailing_address: Yup.string().trim().required("Mailing Address is required"),
      zip_code: Yup.string()
        .trim()
        .required("Please enter your zip")
        .matches(/^\d{4,7}(?:[-\s]\d{4})?$/, "Zip code is invalid") // need to check again
        .typeError("Please enter a valid zip code")
        .test(
          "isSupported",
          "Unfortunately, we are not yet able to provide service in your area. Please check back in the near future to see if your area is within our coverage. For more details or questions, please call us at (718) 756-4123.",
          (val) => !val || supportedZipCodes.includes(val)
        ),
      state: Yup.string().trim().required("*State is required!"),
      city: Yup.string().trim().required("*City is required!"),
      phone_number: Yup.string()
        .trim()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(8, "Must be more then 8 digits")
        .max(12, "Must be less then 512digits")
        .required("Phone Number is required"),
      fax_number: Yup.string()
        .trim()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(8, "Must be more then 8 digits")
        .max(12, "Must be less then 512digits")
        .required("Fax Number is required"),
      primary_email: Yup.string()
        .trim()
        .email("Invalid email format")
        .required("Primary email is required"),
      contact_person: Yup.string().trim().required("Contact person is required"),
      title: Yup.string().trim().required("Title of contact person is required"),
      alternative_emails: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string()
              .trim()
              .email("Invalid email format")
              .required("Phon number is required"),
            use_for: Yup.string().trim().required("Phon number is required"),
          })
        )
        .notRequired(),
    });
  };

  trimSpaces = (value: string) => value.trim().replace(/\s+/g, " ");

  questionSchema = () => {
    return Yup.object().shape({
      zip_code: Yup.string()
        .required("Please enter your zip")
        .matches(/^\d{4,7}(?:[-\s]\d{4})?$/, "Zip code is invalid") // need to check again
        .typeError("Please enter a valid zip code")
        .test(
          "isSupported",
          "Unfortunately, we are not yet able to provide service in your area. Please check back in the near future to see if your area is within our coverage. For more details or questions, please call us at (718) 756-4123.",
          (val) => !val || supportedZipCodes.includes(val)
        ),
      full_name: Yup.string()
        .transform((value: string) => this.trimSpaces(value))
        .required("Name is required"),
      new_password: Yup.string()
        .matches(/^\S*$/, "Password cannot contain spaces")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[\w!@#$%^&*(),.?":{}|<>]{8,72}$/,
          "Password must include at least one uppercase letter, one lowercase letter, one number, one special character, be at least 8 characters long, and have equal or less than 72 characters"
        ),
      confirm_password: Yup.string()
        .when("new_password", {
          is: (new_password) => !!new_password,
          then: Yup.string().required("Confirm password is required"),
          otherwise: Yup.string(),
        })
        .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
    });
  };

  handleZipCodeChange(newValue: any, setFieldValue: any) {
    setFieldValue("zip_code", newValue);
  }

  companyDetailsForm = () => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const isEdit = !!this.state.companyDetailId;

    return (
      <Formik
        initialValues={{
          company_name: this.state.companyDetails?.company_name || "",
          mailing_address: this.state.companyDetails?.mailing_address || "",
          zip_code: this.state.companyDetails?.zip_code || "",
          city: this.state.companyDetails?.city || "",
          state: this.state.companyDetails?.state || "",
          phone_number: this.state.companyDetails?.phone_number || "",
          fax_number: this.state.companyDetails?.fax_number || "",
          primary_email: this.state.companyDetails?.primary_email || "",
          contact_person: this.state.companyDetails?.contact_person || "",
          title: this.state.companyDetails?.title || "",
          alternative_emails: this.state.companyDetails?.alternative_emails || [] as { email: string; use_for: string[] }[],
        }}
        validateOnMount={true}
        validateOnChange={true}
        enableReinitialize={true}
        validationSchema={this.companyDetailSchema}
        onSubmit={() => {
          this.setValidate(true);
          isEdit
            ? this.updateCompanyDetails(this.state.companyDetailId as number)
            : this.createCompanyDetails();
        }}
        data-test-id="updateCompanyDetails"
      >
        {({ setFieldValue, setValues, values, errors, handleSubmit, validateField }) => (
          <form>
            <Box sx={webStyle.formContainer}>
              <Box sx={webStyle.fieldControl}>
                <label style={webStyle.inputLabel} htmlFor={"company_name"}>
                  {configJSON.companyNameText}
                </label>
                <input
                  style={{
                    ...webStyle.inputField(this.state.isCompanyDetailsEdit),
                    ...(this.state.validate && errors.company_name
                      ? webStyle.inputElementError
                      : {}),
                  }}
                  disabled={!this.state.isCompanyDetailsEdit}
                  data-test-id="company_name"
                  type="text"
                  value={values?.company_name}
                  onChange={(event) => {
                    setFieldValue("company_name", event.target.value);
                  }}
                />
                {this.state.validate && errors.company_name && (
                  <Tooltip
                    open={Boolean(errors.company_name)}
                    title={errors.company_name}
                    componentsProps={tooltipStyles}
                    arrow
                  >
                    <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                  </Tooltip>
                )}
              </Box>
              <Box sx={webStyle.fieldGroupContral}>
                <Box sx={webStyle.addressFieldControl}>
                  <label
                    style={webStyle.inputLabel}
                    htmlFor={"mailing_address"}
                  >
                    {configJSON.mailingAddressText}
                  </label>
                  <input
                    style={{
                      ...webStyle.inputField(this.state.isCompanyDetailsEdit),
                      ...(this.state.validate && errors.mailing_address
                        ? webStyle.inputElementError
                        : {}),
                    }}
                    disabled={!this.state.isCompanyDetailsEdit}
                    data-test-id="mailing_address"
                    type="text"
                    value={values?.mailing_address}
                    onChange={(event) => {
                      setFieldValue("mailing_address", event.target.value);
                    }}
                  />
                  {this.state.validate && errors.mailing_address && (
                    <Tooltip
                      open={Boolean(errors.mailing_address)}
                      title={errors.mailing_address}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                    </Tooltip>
                  )}
                </Box>
                <Box sx={webStyle.zipCodeFieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"zip_code"}>
                    {configJSON.zipCodeText}
                  </label>
                  <Autocomplete
                    freeSolo
                    options={supportedZipCodes}
                    value={values.zip_code}
                    onChange={(_, newValue) =>
                      this.handleZipCodeChange(newValue, setFieldValue)
                    }
                    onInputChange={(_, newValue) =>
                      this.handleZipCodeChange(newValue, setFieldValue)
                    }
                    filterOptions={filterOptions}
                    disabled={!this.state.isCompanyDetailsEdit}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <input
                          type="text"
                          {...params.inputProps}
                          style={{
                            ...webStyle.inputField(this.state.isCompanyDetailsEdit),
                            ...(this.state.validate && errors.zip_code
                              ? webStyle.inputElementError
                              : {}),
                          }}
                          placeholder={configJSON.zipCodePlaceholderText}
                          name="zip_code"
                          onBlur={(e) => {
                            validateField("zip_code");
                            this.setState({ zipCodeFieldFocusing: false });
                            params.inputProps?.onBlur?.(e);
                          }}
                          onFocus={() =>
                            this.setState({ zipCodeFieldFocusing: true })
                          }
                        />
                      </div>
                    )}
                    data-test-id="zip_code"
                    ListboxComponent={StyledListBox}
                  />
                  {this.state.validate && errors.zip_code && (
                    <Tooltip
                      open={Boolean(errors.zip_code)}
                      title={errors.zip_code}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                    </Tooltip>
                  )}
                </Box>
              </Box>
              <Box sx={webStyle.fieldGroupContral}>
                <Box sx={webStyle.columnFieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"city"}>
                    {configJSON.cityText}
                  </label>
                  <Autocomplete
                    disablePortal
                    value={values.city || ""}
                    options={listOfCities(values?.state)}
                    onChange={(_, newValue) => {
                      const currentState = getStateByCity(newValue || "");
                      setValues({ 
                        ...values,
                        city: newValue || "",
                        state: currentState || ""
                      });
                    }}
                    disabled={!this.state.isCompanyDetailsEdit}
                    sx={{
                      ...webStyle.selectField(this.state.isCompanyDetailsEdit, values.city || ""),
                      ...(this.state.validate && errors.city
                        ? webStyle.inputElementError
                        : {}),
                    }}
                    // onChange={(_, newValue) => setFieldValue("city", newValue)}
                    data-test-id="city"
                    renderInput={(params) => (
                      <TextField {...params}  placeholder="Select one" />
                    )}
                  />
                  {this.state.validate && errors.city && (
                    <Tooltip
                      open={Boolean(errors.city)}
                      title={errors.city}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img src={errorIcon} alt="" style={{...webStyle.errorIcon, right: "32px" }} />
                    </Tooltip>
                  )}
                </Box>
                <Box sx={webStyle.columnFieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"state"}>
                    {configJSON.stateText}
                  </label>
                  <Autocomplete
                    disablePortal
                    value={values.state || ""}
                    options={listOfStates()}
                    disabled={!this.state.isCompanyDetailsEdit}
                    sx={{
                      ...webStyle.selectField(this.state.isCompanyDetailsEdit, values.state || ""),
                      ...(this.state.validate && errors.state
                        ? webStyle.inputElementError
                        : {}),
                    }}
                    data-test-id="state"
                    onChange={(_, newValue) => {
                      setValues({
                        ...values,
                        state: newValue || "",
                        city: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select one" />
                    )}
                  />
                  {this.state.validate && errors.state && (
                    <Tooltip
                      open={Boolean(errors.state)}
                      title={errors.state}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img src={errorIcon} alt="" style={{...webStyle.errorIcon, right: "32px" }} />
                    </Tooltip>
                  )}
                </Box>
              </Box>
              <Box sx={webStyle.fieldControl}>
                <label style={webStyle.inputLabel} htmlFor={"phone_number"}>
                  {configJSON.phoneNumberText}
                </label>
                <input
                  style={{
                    ...webStyle.inputField(this.state.isCompanyDetailsEdit),
                    ...(this.state.validate && errors.phone_number
                      ? webStyle.inputElementError
                      : {}),
                  }}
                  disabled={!this.state.isCompanyDetailsEdit}
                  data-test-id="phone_number"
                  type="text"
                  value={values?.phone_number}
                  onChange={(event) => {
                    setFieldValue("phone_number", event.target.value);
                  }}
                />
                {this.state.validate && errors.phone_number && (
                  <Tooltip
                    open={Boolean(errors.phone_number)}
                    title={errors.phone_number}
                    componentsProps={tooltipStyles}
                    arrow
                  >
                    <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                  </Tooltip>
                )}
              </Box>
              <Box sx={webStyle.fieldControl}>
                <label style={webStyle.inputLabel} htmlFor={"fax_number"}>
                  {configJSON.faxNumberText}
                </label>
                <input
                  style={{
                    ...webStyle.inputField(this.state.isCompanyDetailsEdit),
                    ...(this.state.validate && errors.fax_number
                      ? webStyle.inputElementError
                      : {}),
                  }}
                  disabled={!this.state.isCompanyDetailsEdit}
                  data-test-id="fax_number"
                  type="text"
                  value={values?.fax_number}
                  onChange={(event) => {
                    setFieldValue("fax_number", event.target.value);
                  }}
                />
                {this.state.validate && errors.fax_number && (
                  <Tooltip
                    open={Boolean(errors.fax_number)}
                    title={errors.fax_number}
                    componentsProps={tooltipStyles}
                    arrow
                  >
                    <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                  </Tooltip>
                )}
              </Box>
              <Box sx={webStyle.fieldControl}>
                <label style={webStyle.inputLabel} htmlFor={"primary_email"}>
                  {configJSON.primaryEmailText}
                </label>

                <input
                  style={{
                    ...webStyle.inputField(this.state.isCompanyDetailsEdit),
                    ...(this.state.validate && errors.primary_email
                      ? webStyle.inputElementError
                      : {}),
                  }}
                  disabled={!this.state.isCompanyDetailsEdit}
                  data-test-id="primary_email"
                  type="email"
                  value={values?.primary_email}
                  onChange={(event) => {
                    setFieldValue("primary_email", event.target.value);
                  }}
                />
                {this.state.validate && errors.primary_email && (
                  <Tooltip
                    open={Boolean(errors.primary_email)}
                    title={errors.primary_email}
                    componentsProps={tooltipStyles}
                    arrow
                  >
                    <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                  </Tooltip>
                )}
              </Box>
              <FieldArray name="alternative_emails">
                {(arrayHelpers: any) => (
                  <>
                    {values.alternative_emails.map((_, index) => {
                      const currentValue = values?.alternative_emails[index];
                      const currentError = (errors?.alternative_emails || [])[index] as any;
                      return (
                        <Box sx={webStyle.fieldGroupContral} key={index}>
                          <Box sx={webStyle.columnFieldControl}>
                            <label
                              style={webStyle.inputLabel}
                              htmlFor={"contact_person"}
                            >
                              {configJSON.alternativeEmailText}
                              {index + 1}
                            </label>
                            <input
                              style={{
                                ...webStyle.inputField(this.state.isCompanyDetailsEdit),
                                ...(this.state.validate && currentError?.email
                                  ? webStyle.inputElementError
                                  : {}),
                              }}
                              data-test-id={`alternative_emails.${index}.email`}
                              type="text"
                              value={currentValue.email}
                              disabled={!this.state.isCompanyDetailsEdit}
                              onChange={(event) => {
                                const updatedEmailArray = [
                                  ...values.alternative_emails,
                                ];
                                updatedEmailArray[index].email =
                                  event.target.value;
                                setFieldValue(
                                  "alternative_emails",
                                  updatedEmailArray
                                );
                              }}
                            />
                            {this.state.validate && currentError?.email && (
                              <Tooltip
                                open={Boolean(currentError?.email)}
                                title={currentError?.email}
                                componentsProps={tooltipStyles}
                                arrow
                              >
                                <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                              </Tooltip>
                            )}
                          </Box>
                          <Box sx={webStyle.columnFieldControl}>
                            <label style={webStyle.inputLabel} htmlFor={"title"}>
                              {configJSON.useForText}
                            </label>
                            <Select
                              value={currentValue?.use_for}
                              data-test-id={`use-for-select-${index}`}
                              multiple
                              disabled={!this.state.isCompanyDetailsEdit}
                              renderValue={(selected) => {
                                if (!selected.length) {
                                  return (
                                    <Box
                                      component="div"
                                      sx={webStyle.selectPlaceholder}
                                    >
                                      Select
                                    </Box>
                                  );
                                }

                                return (
                                  <Box sx={webStyle.chipsWrapper}>
                                    {selected.map((value) => (
                                      <Chip
                                        key={value}
                                        label={value}
                                        disabled={!this.state.isCompanyDetailsEdit}
                                        onDelete={(event) => {
                                          event.stopPropagation();
                                          const updatedEmailArray = [
                                            ...values.alternative_emails,
                                          ];
                                          updatedEmailArray[index].use_for =
                                            updatedEmailArray[
                                              index
                                            ].use_for.filter(
                                              (item) => item !== value
                                            );
                                          setFieldValue(
                                            "alternative_emails",
                                            updatedEmailArray
                                          );
                                        }}
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                        sx={webStyle.chip}
                                        deleteIcon={<CloseRoundedIcon />}
                                      />
                                    ))}
                                  </Box>
                                );
                              }}
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                              sx={{
                                ...webStyle.selectWithCheckboxes(this.state.isCompanyDetailsEdit),
                                ...(this.state.validate && currentError?.use_for
                                  ? webStyle.inputElementError
                                  : {}),
                              }}
                              onChange={(event) => {
                                console.log(
                                  event.target.value,
                                  values.alternative_emails
                                );
                                const updatedEmailArray = [
                                  ...values.alternative_emails,
                                ];
                                updatedEmailArray[index].use_for = [ ...event.target.value ];
                                setFieldValue(
                                  "alternative_emails",
                                  updatedEmailArray
                                );
                              }}
                              MenuProps={MenuProps}
                            >
                              <MenuItem
                                value={"Reports"}
                                sx={webStyle.selectOption}
                              >
                                <Checkbox
                                  sx={webStyle.checkbox}
                                  checked={
                                    !!values.alternative_emails[index].use_for.find(
                                      (item) => item === "Reports"
                                    )
                                  }
                                />
                                <ListItemText
                                  primary={"Reports"}
                                  sx={webStyle.checkboxLabel}
                                />
                              </MenuItem>
                              <MenuItem
                                value={"Invoices"}
                                sx={webStyle.selectOption}
                              >
                                <Checkbox
                                  sx={webStyle.checkbox}
                                  checked={
                                    !!values.alternative_emails[index].use_for.find(
                                      (item) => item === "Invoices"
                                    )
                                  }
                                />
                                <ListItemText
                                  primary={"Invoices"}
                                  sx={webStyle.checkboxLabel}
                                />
                              </MenuItem>
                            </Select>
                            {this.state.validate && currentError?.use_for && (
                              <Tooltip
                                open={Boolean(currentError?.use_for)}
                                title={currentError?.use_for}
                                componentsProps={tooltipStyles}
                                arrow
                              >
                                <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                              </Tooltip>
                            )}
                          </Box>
                          <Box
                            hidden={!this.state.isCompanyDetailsEdit}
                            sx={webStyle.removeAlternativeEmail}
                            onClick={() => arrayHelpers.remove(index)}
                            data-test-id="removeEmail"
                          >
                            <img
                              src={removeIcon}
                              alt=""
                              style={webStyle.btnIcon}
                            />
                          </Box>
                        </Box>
                      )
                    })}
                    <Box sx={webStyle.addNewEmailWrapper}>
                      <button
                        style={webStyle.addNewEmailButton}
                        type="button"
                        disabled={!this.state.isCompanyDetailsEdit}
                        onClick={() => {
                          arrayHelpers.push({
                            email: "",
                            use_for: [],
                          });
                        }}
                      >
                        <img src={addIcon} alt="" style={webStyle.btnIcon} />
                        <Typography style={webStyle.typographyTitleText}>
                          {configJSON.addAlternativeEmailText}
                        </Typography>
                      </button>
                    </Box>
                  </>
                )}
              </FieldArray>
              <Box sx={webStyle.fieldGroupContral}>
                <Box sx={webStyle.columnFieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"contact_person"}>
                    {configJSON.contactPersonText}
                  </label>
                  <input
                    style={{
                      ...webStyle.inputField(this.state.isCompanyDetailsEdit),
                      ...(this.state.validate && errors.contact_person
                        ? webStyle.inputElementError
                        : {}),
                    }}
                    disabled={!this.state.isCompanyDetailsEdit}
                    data-test-id="contact_person"
                    type="text"
                    value={values?.contact_person}
                    onChange={(event) => {
                      setFieldValue("contact_person", event.target.value);
                    }}
                  />
                  {this.state.validate && errors.contact_person && (
                    <Tooltip
                      open={Boolean(errors.contact_person)}
                      title={errors.contact_person}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                    </Tooltip>
                  )}
                </Box>
                <Box sx={webStyle.columnFieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"title"}>
                    {configJSON.titleOfContactPersonText}
                  </label>
                  <input
                    style={{
                      ...webStyle.inputField(this.state.isCompanyDetailsEdit),
                      ...(this.state.validate && errors.title
                        ? webStyle.inputElementError
                        : {}),
                    }}
                    disabled={!this.state.isCompanyDetailsEdit}
                    data-test-id="title"
                    type="text"
                    value={values?.title}
                    onChange={(event) => {
                      setFieldValue("title", event.target.value);
                    }}
                  />
                  {this.state.validate && errors.title && (
                    <Tooltip
                      open={Boolean(errors.title)}
                      title={errors.title}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                    </Tooltip>
                  )}
                </Box>
              </Box>
              <Box sx={webStyle.fieldControlFirstStepGroup}>
                {!this.state.isCompanyDetailsEdit ? (
                  <button
                    style={webStyle.submitButton}
                    onClick={() => this.setIsCompanyDetailsEdit()}
                    type="button"
                    data-test-id="editBtn"
                  >
                    {configJSON.editButtomText}
                  </button>
                ) : (
                  <>
                    {this.state.companyDetailId ? (
                      <button
                        style={webStyle.prevButton}
                        data-test-id="cancelBtn"
                        type="button"
                        onClick={() => {
                          setValues({ 
                            ...values,
                            company_name: this.state.companyDetails?.company_name || "",
                            mailing_address: this.state.companyDetails?.mailing_address || "",
                            zip_code: this.state.companyDetails?.zip_code || "",
                            city: this.state.companyDetails?.city || "",
                            state: this.state.companyDetails?.state || "",
                            phone_number: this.state.companyDetails?.phone_number || "",
                            fax_number: this.state.companyDetails?.fax_number || "",
                            primary_email: this.state.companyDetails?.primary_email || "",
                            contact_person: this.state.companyDetails?.contact_person || "",
                            title: this.state.companyDetails?.title || "",
                            alternative_emails: this.state.companyDetails?.alternative_emails || [],
                          })
                          this.setIsCompanyDetailsEdit();
                        }}
                      >
                        <Typography sx={webStyle.buttonText}>
                          {configJSON.cancelButtonText}
                        </Typography>
                      </button>
                    ) : ""}
                    <button
                      style={webStyle.submitButton}
                      data-test-id="submitBtn"
                      type="button"
                      onClick={() => {
                        this.setCompanyDetatils(values);
                        this.setValidate(true);
                        if (Object.keys(errors).length === 0) {
                          handleSubmit();
                        }
                      }}
                    >
                      <Typography sx={webStyle.buttonText}>
                        {configJSON.saveButtomText}
                      </Typography>
                    </button>
                  </>
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    );
  };

  updateAccountDetailsForm = () => (
    <Formik
      initialValues={{
        zip_code: this.state.accountSettings?.zip_code || "",
        full_name: this.state.accountSettings?.full_name || "",
        new_password: "",
        confirm_password: "",
      }}
      validateOnChange={true}
      validationSchema={this.questionSchema}
      onSubmit={(values: AccountSettings) => {
        this.setValidate(false);
        this.updateAccountSettings({
          ...values,
          full_name: this.trimSpaces(values.full_name),
        });
      }}
      data-test-id="updateAccountDetails"
    >
      {({ values, errors, setFieldValue, handleSubmit, validateField }) => (
        <form>
          <Box sx={webStyle.formContainer}>
            <Box sx={webStyle.fieldControl}>
              <label style={webStyle.inputLabel} htmlFor={"zip_code"}>
                {configJSON.zipCodeText}
              </label>
              <Autocomplete
                freeSolo
                options={supportedZipCodes}
                onChange={(_, newValue) =>
                  this.handleZipCodeChange(newValue || '', setFieldValue)
                }
                onInputChange={(_, newValue) =>
                  this.handleZipCodeChange(newValue || '', setFieldValue)
                }
                filterOptions={filterOptions}
                value={values.zip_code}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      {...params.inputProps}
                      style={{
                        ...webStyle.inputField(
                          this.state.isAccountSettingsEdit
                        ),
                        ...(this.state.validate &&
                        (errors.zip_code || this.state.zipCodeError) &&
                        !this.state.zipCodeFieldFocusing
                          ? webStyle.inputElementError
                          : {}),
                      }}
                      placeholder={configJSON.zipCodePlaceholderText}
                      name="zip_code"
                      onBlur={(e) => {
                        validateField("zip_code");
                        this.setState({ zipCodeFieldFocusing: false });
                        params.inputProps?.onBlur?.(e);
                      }}
                      onFocus={() =>
                        this.setState({ zipCodeFieldFocusing: true })
                      }
                      disabled={!this.state.isAccountSettingsEdit}
                    />
                  </div>
                )}
                data-test-id="zipCode"
                ListboxComponent={StyledListBox}
              />
              {this.state.validate && errors.zip_code && !this.state.zipCodeFieldFocusing && (
                <Tooltip
                  open={Boolean(errors.zip_code)}
                  title={errors.zip_code}
                  componentsProps={tooltipStyles}
                  arrow
                >
                  <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                </Tooltip>
              )}
            </Box>
            <Box sx={webStyle.fieldControl}>
              <label style={webStyle.inputLabel} htmlFor={"name"}>
                {configJSON.nameText}
              </label>
              <input
                style={{
                  ...webStyle.inputField(this.state.isAccountSettingsEdit),
                  ...(this.state.validate && errors.full_name
                    ? webStyle.inputElementError
                    : {}),
                }}
                data-test-id="full_name"
                type="text"
                placeholder={configJSON.namePlaceholderText}
                value={values.full_name}
                name="full_name"
                onChange={(event) => {
                  setFieldValue("full_name", event.target.value);
                }}
                autoComplete="off"
                disabled={!this.state.isAccountSettingsEdit}
              />
              {this.state.validate && errors.full_name && (
                <Tooltip
                  open={Boolean(errors.full_name)}
                  title={errors.full_name}
                  componentsProps={tooltipStyles}
                  arrow
                >
                  <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                </Tooltip>
              )}
            </Box>
            <Box sx={webStyle.fieldControl}>
              <label style={webStyle.inputLabel} htmlFor={"new_password"}>
                {configJSON.passwordText}
              </label>
              <input
                style={{
                  ...webStyle.inputField(this.state.isAccountSettingsEdit),
                  ...webStyle.inputElementPassword,
                  ...(this.state.validate && errors.new_password
                    ? webStyle.inputElementError
                    : {}),
                }}
                data-test-id="new_password"
                type={this.state.showPassword ? "text" : "password"}
                placeholder={configJSON.passwordPlaceholderText}
                value={values.new_password}
                onChange={(event) => {
                  setFieldValue("new_password", event.target.value);
                }}
                autoComplete="new-password"
                disabled={!this.state.isAccountSettingsEdit}
              />
              {this.state.validate && errors.new_password ? (
                <Tooltip
                  open={Boolean(errors.new_password)}
                  title={errors.new_password}
                  componentsProps={tooltipStyles}
                  arrow
                >
                  <img
                    src={errorIcon}
                    alt=""
                    style={webStyle.errorIcon}
                    data-test-id="password-warning"
                  />
                </Tooltip>
              ) : (
                <IconButton
                  style={webStyle.passwordHiddenIcon}
                  onClick={() => this.setShowPassword(!this.state.showPassword)}
                >
                  {this.state.showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              )}
              <Typography style={webStyle.passwordHelp}>
                {configJSON.alLeastSevenLlettersText}
              </Typography>
            </Box>
            <Box sx={webStyle.fieldControl}>
              <label style={webStyle.inputLabel} htmlFor={"confirm_password"}>
                {configJSON.confirmPasswordText}
              </label>
              <input
                style={{
                  ...webStyle.inputField(this.state.isAccountSettingsEdit),
                  ...webStyle.inputElementPassword,
                  ...(this.state.validate && errors.confirm_password
                    ? webStyle.inputElementError
                    : {}),
                }}
                data-test-id="confirm_password"
                type={this.state.showConfirmPassword ? "text" : "password"}
                placeholder={configJSON.confirmPasswordPlaceholderText}
                value={values.confirm_password}
                onChange={(event) => {
                  setFieldValue("confirm_password", event.target.value);
                }}
                autoComplete="new-password"
                disabled={!this.state.isAccountSettingsEdit}
              />
              {this.state.validate && errors.confirm_password ? (
                <Tooltip
                  open={Boolean(errors.confirm_password)}
                  title={errors.confirm_password}
                  componentsProps={tooltipStyles}
                  arrow
                >
                  <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                </Tooltip>
              ) : (
                <IconButton
                  style={webStyle.passwordHiddenIcon}
                  onClick={() =>
                    this.setShowConfirmPassword(!this.state.showConfirmPassword)
                  }
                >
                  {this.state.showConfirmPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              )}
            </Box>
            <Box sx={webStyle.fieldControlFirstStepGroup}>
              {!this.state.isAccountSettingsEdit ? (
                <button
                  style={webStyle.submitButton}
                  onClick={() => this.setIsAccountSettingsEdit()}
                  type="button"
                  data-test-id="editBtn"
                >
                  Edit
                </button>
              ) : (
                <>
                  <button
                    style={webStyle.prevButton}
                    data-test-id="cancelBtn"
                    type="button"
                    onClick={() => {
                      setFieldValue(
                        "full_name",
                        this.state.accountSettings?.full_name || ""
                      );
                      setFieldValue(
                        "zip_code",
                        this.state.accountSettings?.zip_code || ""
                      );
                      setFieldValue("new_password", "");
                      setFieldValue("confirm_password", "");
                      this.setIsAccountSettingsEdit();
                    }}
                  >
                    <Typography sx={webStyle.buttonText}>
                      {configJSON.cancelButtonText}
                    </Typography>
                  </button>
                  <button
                    style={webStyle.submitButton}
                    data-test-id="submitBtn"
                    type="button"
                    onClick={() => {
                      this.setValidate(true);
                      if (Object.keys(errors).length === 0) {
                        setFieldValue(
                          "full_name",
                          this.trimSpaces(values.full_name)
                        );
                        handleSubmit();
                      }
                    }}
                  >
                    <Typography sx={webStyle.buttonText}>
                      {configJSON.saveButtomText}
                    </Typography>
                  </button>
                </>
              )}
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
  // Customizable Area Start
  notificationsForm = () => (
    <Box sx={webStyle.formContainer} data-test-id="notificationsBlock">
      <Box sx={{...webStyle.fieldCheckboxControl, margin: '0 0 0 10px'}}>
        <Typography style={webStyle.switchTitle}>
          Notifications turned on
        </Typography>
        <IOSSwitch
          sx={{ m: 1 }}
          defaultChecked={true} // TODO: do GET request on mount to get the initial value
          data-test-id="turnNotification"
          onChange={(_, checked) => this.handleReceiveNotificationsChange(checked)}
      />
      </Box>
    </Box>
  )
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addReadingContainer}>
        <Box sx={webStyle.addReadingBlock}>
          <Box sx={webStyle.signUpFormContainer}>
            <Box sx={webStyle.addReadingTitle}>
              <Typography style={webStyle.formTitle}>
                {configJSON.settingsText}
              </Typography>
            </Box>
            <Tabs
              value={this.state.step}
              onChange={(_, newValue) => this.setStep(newValue)}
              sx={webStyle.tabsContainer}
            >
              <Tab
                value={1}
                label={configJSON.companyDetailsText}
                data-test-id="firstStep"
                sx={webStyle.tabButton(this.state.step === 1)}
              />
              <Tab
                value={2}
                label={configJSON.accountSettingsText}
                data-test-id="secondStep"
                sx={webStyle.tabButton(this.state.step === 2)}
              />
              <Tab
                value={3}
                label={configJSON.notificationsText}
                data-test-id="thirdStep"
                sx={webStyle.tabButton(this.state.step === 3)}
              />
            </Tabs>
            <Box role="tabpanel" hidden={this.state.step !== 1}>
              {this.state.step === 1 && this.companyDetailsForm()}
            </Box>
            <Box role="tabpanel" hidden={this.state.step !== 2}>
              {this.state.step === 2 && this.updateAccountDetailsForm()}
            </Box>
            <Box role="tabpanel" hidden={this.state.step !== 3}>
              {this.state.step === 3 && this.notificationsForm()}
            </Box>
          </Box>
        </Box>
        <Snackbar
          open={this.state.snackbarProps.isOpen}
          message={
            <Box sx={webStyle.snackbarContent}>
              {this.state.snackbarProps?.type === "Error" ? (
                <CancelRoundedIcon
                  sx={{
                    ...webStyle.snackbarIcon,
                    ...webStyle.snackbarWarningIcon,
                  }}
                />
              ) : (
                <CheckCircleRoundedIcon
                  sx={{
                    ...webStyle.snackbarIcon,
                    ...webStyle.snackbarSuccessIcon,
                  }}
                />
              )}
              <Typography sx={webStyle.snackbarText}>
                {this.state.snackbarProps?.messages.map(message => (
                  <Box>{message}</Box>
                ))}
              </Typography>
            </Box>
          }
          autoHideDuration={3500}
          onClose={this.closeSnackbar}
          sx={webStyle.snackbar}
        />
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

const tooltipStyles = {
  popper: {
    sx: {
      zIndex: 1,
    },
  },
  tooltip: {
    sx: {
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "13px",
      lineHeight: "18px",
      fontWeight: "400",
      padding: "8px 12px",
      backgroundColor: "#1E293B",
      borderRadius: "8px",
      width: "fit-content",
      maxWidth: "480px",
      "& .MuiTooltip-arrow": {
        color: "#1E293B",
      },
    },
  },
};

const StyledListBox = styled("ul")(() => ({
  "& .MuiAutocomplete-option": {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: "#0F172A",
    padding: "8px 12px !important",
    margin: "0 8px 4px",
    borderRadius: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
  },
}));
// Customizable Area Start

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#1A7BA4",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

const webStyle = {
  addReadingContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    height: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  addReadingBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  signUpFormContainer: {
    margin: "40px auto",
    maxWidth: "600px",
    width: "80%",
  },
  addReadingTitle: {
    margin: "0 10px 20px",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    marginBottom: "15px",
  },
  formSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  fieldGroupContral: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  prevTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  prevEmptyTitle: {
    display: "flex",
    flexDirection: "row",
  },
  fieldControlFirstStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "25px",
  },
  fieldControlSecontStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  aditionInputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  inputDisabledElement: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: "#F1F5F9",
    fontFamily: "'Inter', sans-serif",
  },
  textAreaElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
  },
  textAreaErrorElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #F87171",
    fontFamily: "'Inter', sans-serif",
  },
  textAreaComent: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    marginTop: "5px",
    marginBottom: "5px",
  },
  textAreaErrorComent: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#F87171",
    marginTop: "5px",
    marginBottom: "5px",
  },
  selectField: (isEditing: boolean, value: string) => ({
    fontFamily: "'Inter', sans-serif",
    height: '56px',
    fontSize: '16px',
    backgroundColor: isEditing ? "#FFFFFF" : "#F1F5F9", // Change to white when editing
    borderRadius: '8px',
    color: "#0F172A",
    width: '100%',
    '& .MuiFormControl-root .MuiInputBase-root ': {
      borderRadius: '8px',
      border: "1px solid #CBD5E1",
      height: '56px'
    },
    '& fieldset': {
      border: "none",
    },
    '& input:disabled': {
      color: '#0F172A',
      backgroundColor: '#F1F5F9',
      "-webkit-text-fill-color": "#0F172A",
    },
    "& .MuiAutocomplete-clearIndicator": {
      display: value ? "inline-block" : "none",
    },
  }),
  selectWithCheckboxes: (isEditing: boolean) => ({
    fontFamily: "'Inter', sans-serif",
    height: "56px",
    fontSize: "16px",
    backgroundColor: isEditing ? "#FFFFFF" : "#F1F5F9",
    borderRadius: "8px",
    color: "#0F172A",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1 !important",
    },
    "& .MuiSelect-select.Mui-disabled": {
      "-webkit-text-fill-color": "#475569",
    }
  }),
  selectOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    borderRadius: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  selectPlaceholder: {
    color: "#94A3B8",
  },
  prevButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "30px 0 0",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#F8FAFC",
    color: "#1A7BA4",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "30px 10px 0 16px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  informationBlock: {
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  informationFieldBlock: {
    margin: "10px 5px",
  },
  informationTitle: {
    color: "#94A3B8",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    alignItems: "center",
    display: "flex",
    margin: "0px 5px",
  },
  informationField: {
    color: "#0F172A",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  informationTitleContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "5px 0px",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
  },
  tabsContainer: {
    borderBottom: "1px solid #e0e0e0",
    margin: "0 10px 30px",
    fontSize: "16px",
    fontFamily: "'Inter', sans-serif",
    "& .Mui-selected": {
      color: "#1A7BA4 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#1A7BA4 !important",
    },
  },
  tabButton: (isActive: boolean) => ({
    fontWeight: isActive ? 700 : 400,
    color: isActive ? "#1A7BA4" : "#64748B",
    textTransform: "none",
    padding: "8px 12px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
  }),
  activeTabItemContainer: {
    cursor: "pointer",
    borderBottom: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row",
  },
  tabItemContainer: {
    cursor: "pointer",
    borderBottom: "1px solid #E2E8F0",
    display: "flex",
    flexDirection: "row",
  },
  tabTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    margin: "7px",
  },
  activeTabTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#64748B",
    margin: "7px",
  },
  zipCodeFieldControl: {
    width: "135px",
    display: "flex",
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  addressFieldControl: {
    flexBasis: "60%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  columnFieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  addNewEmailWrapper: {
    margin: "6px 10px",
  },
  addNewEmailButton: {
    cursor: "pointer",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "0",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "inherit",
    color: "#1A7BA4",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    width: "100%",
  },
  btnIcon: {
    width: "24px",
    height: "24px",
    margin: "4px",
  },
  typographyTitleText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  passwordHiddenIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  passwordHelp: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    marginTop: "8px",
  },
  inputElementPassword: {
    padding: "10px 52px 10px 10px",
  },
  removeAlternativeEmail: {
    backgroundColor: "#FEF2F2",
    width: "32px",
    height: "32px",
    borderRadius: "16px",
    position: "absolute",
    right: "-40px",
    bottom: "24px",
    cursor: "pointer",
  },
  checkboxLabel: {
    "& .MuiTypography-root": {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
    },
  },
  checkbox: {
    width: "0px",
    height: "0px",
    borderRadius: "0px",
    color: "#64748B",
    marginRight: "8px",
    "& .MuiSvgIcon-root": {
      fontSize: "26px",
    },
    "&.Mui-checked": {
      color: "#1A7BA4",
    },
  },
  chipsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
  },
  chip: {
    height: "22px",
    backgroundColor: "#F1F5F9",
    color: "#475569",
    fontWeight: "700",
    textTransform: "uppercase",
    "& .MuiChip-label": {
      fontSize: "12px",
      padding: "0 2px 0 8px",
      marginTop: "3px",
    },
    "& .MuiChip-deleteIcon": {
      fontSize: "16px",
      margin: "0 8px 0 0",
      color: "#64748B",
    },
  },
  inputElementError: {
    border: "1px solid #F87171",
  },
  snackbar: {
    left: "50% !important",
    transform: "translateX(-50%)",
    fontFamily: "'Inter', sans-serif",
    "& .MuiPaper-root": {
      backgroundColor: "#FFFFFF",
      fontFamily: "'Inter', sans-serif !important",
      padding: "8px 16px 8px 8px",
      boxShadow: "0px 6px 15px -3px #00000026",
      borderRadius: "8px",
      minWidth: "auto",
      "& .MuiSnackbarContent-message": {
        padding: 0,
      },
    },
  },
  snackbarContent: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    zIndex: 10,
  },
  snackbarIcon: {
    width: "26px",
    height: "26px",
  },
  snackbarWarningIcon: {
    "> path": {
      fill: "#F87171",
    },
  },
  snackbarSuccessIcon: {
    "> path": {
      fill: "#34D399",
    },
  },
  snackbarText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    display: "flex",
    flexDirection: "column",
  },
  inputField: (isEditing: boolean = false) => ({
    width: "100%",
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    color: "#0F172A",
    fontWeight: "400",
    backgroundColor: isEditing ? "#FFFFFF" : "#F1F5F9",
  }),
  fieldCheckboxControl: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "10px",
    position: "relative",
  },
  switchTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#475569",
    alignItems: "center",
    display: "flex",
    marginRight: "40px",
  },
};
// Customizable Area End
