import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface Representative {
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  confirm_password?: string;
  last_login?: string;
  status: "ACTIVE" | "INVITE_SENT";
  enabled: boolean;
}

interface AddEditRepresentativeDialogProps {
  isAdd?: boolean;
  initialRepresentative?: Representative;
}

interface SnackbarProps {
  isOpen: boolean;
  type: "Success" | "Error";
  message: string;
}

interface S {
  representativeList: Representative[];
  // Customizable Area Start
  showPassword: boolean;
  showConfirmPassword: boolean;
  passwordValidations: {
    hasCapital: boolean;
    hasLowercase: boolean;
    hasNumber: boolean;
    minLength: boolean;
    hasSpecialSymbol: boolean;
  };
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
  firstItemIndex: number;
  lastItemIdex: number;
  searchText: string;
  token: string;
  filteredRepresentativeList: Representative[];
  currentRepresentativeList: Representative[];
  addEditDialogProps: AddEditRepresentativeDialogProps | null;
  deleteRepresentativeDialogId: string | null;
  snackbarProps: SnackbarProps;
  fieldRepresentativesLoading: boolean;
  validate: boolean;
  changeStatusLoading: boolean;
  resendInviteLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export enum PaymentStatus {
  Paid = "Paid",
  Pending = "Pending",
  NoInvoice = "No Invoice",
}

export default class fieldRepresentativesPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getFieldRepresentativeCallId: string;
  addEditFieldRepresentativeCallId: string;
  deleteFieldRepresentativeCallId: string;
  changeStatusOfFieldRepresentativeCallId: string;
  changeStatusOfFieldRepresentativeCallAccountId: string;
  resentInviteCallId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.getFieldRepresentativeCallId = "";
    this.addEditFieldRepresentativeCallId = "";
    this.deleteFieldRepresentativeCallId = "";
    this.changeStatusOfFieldRepresentativeCallId = "";
    this.changeStatusOfFieldRepresentativeCallAccountId = "";
    this.resentInviteCallId = "";

    this.state = {
      representativeList: [],
      showPassword: false,
      showConfirmPassword: false,
      passwordValidations: {
        hasCapital: false,
        hasLowercase: false,
        hasNumber: false,
        minLength: false,
        hasSpecialSymbol: false,
      },
      totalPages: 1,
      currentPage: 1,
      itemsPerPage: 10,
      firstItemIndex: 1,
      lastItemIdex: 10,
      searchText: "",
      token: "",
      filteredRepresentativeList: [],
      currentRepresentativeList: [],
      addEditDialogProps: null,
      deleteRepresentativeDialogId: null,
      snackbarProps: {
        isOpen: false,
        type: "Success",
        message: "",
      },
      fieldRepresentativesLoading: false,
      validate: false,
      changeStatusLoading: false,
      resendInviteLoading: false,
    }
      // Customizable Area End
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!apiRequestCallId || !responseJson) {
      return;
    }

    if (apiRequestCallId === this.getFieldRepresentativeCallId) {
      this.setFieldRepresentativesList(responseJson);
    } else if (apiRequestCallId === this.addEditFieldRepresentativeCallId) {
      this.updateFieldRepresentativesList(responseJson);
    } else if (apiRequestCallId === this.deleteFieldRepresentativeCallId) {
      this.filterDeletedFieldRepresentative(responseJson);
    } else if (
      apiRequestCallId === this.changeStatusOfFieldRepresentativeCallId
    ) {
      this.updateStatusOfFieldRepresentative(responseJson);
    } else if (apiRequestCallId === this.resentInviteCallId) {
      this.inviteSentResponse(responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  editRepresentative = (representative: Representative) => {
    this.setState({
      addEditDialogProps: { initialRepresentative: representative },
    });
  };

  resetAddFRForm = () => {
    this.setState({
      showPassword: false,
      showConfirmPassword: false,
      passwordValidations: {
        hasCapital: false,
        hasLowercase: false,
        hasNumber: false,
        minLength: false,
        hasSpecialSymbol: false,
      },
    });
  };

  handleCloseDeleteDialog = () => {
    this.setState({ deleteRepresentativeDialogId: null });
  };

  handleCloseAddFR = () => {
    this.setState({ addEditDialogProps: null, validate: false });
    this.resetAddFRForm();
  };

  searchRepresentatives = (searchText: string) => {
    this.setState({ searchText });
  };

  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  toggleShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handlePasswordChange = (e: { target: { value: any } }) => {
    this.validatePassword(e.target.value);
  };

  validatePassword = (password: string) => {
    const validations = {
      hasCapital: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      minLength: password.length >= 8,
      hasSpecialSymbol: /(?=.*[!@#$%^&*(),.?":{}|<>])/.test(password),
    };
    this.setState({ passwordValidations: validations });
  };

  componentDidMount = async () => {
    const userRole = await getStorageData("role");

    if (userRole !== "admin") {
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LogInPage"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else {
      await this.getToken();
      this.getFieldRepresentatives();
    }
  };

  async componentDidUpdate(prevProps: Props, prevState: S): Promise<void> {
    if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.representativeList !== this.state.representativeList ||
      prevState.searchText !== this.state.searchText
    ) {
      this.updatePagination();
    }
  }

  handleNextPage = () => {
    if (
      this.state.currentPage <
      Math.ceil(this.state.representativeList.length / this.state.itemsPerPage)
    ) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  };

  handlePrevPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
  };

  updatePagination = () => {
    const { currentPage, itemsPerPage, representativeList, searchText } =
      this.state;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredRepresentativeList = searchText
      ? representativeList.filter(
          (representative) =>
            representative.first_name
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            representative.last_name
              ?.toLowerCase()
              .includes(searchText.toLowerCase())
        )
      : representativeList;
    const currentRepresentativeList = filteredRepresentativeList.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    const totalPages = Math.ceil(
      filteredRepresentativeList.length / itemsPerPage
    );

    if (indexOfLastItem > filteredRepresentativeList.length) {
      indexOfLastItem = filteredRepresentativeList.length;
    }

    this.setState({
      filteredRepresentativeList,
      currentRepresentativeList,
      totalPages,
      firstItemIndex: indexOfFirstItem,
      lastItemIdex: indexOfLastItem,
      currentPage:
        currentRepresentativeList.length === 0 && currentPage !== 1
          ? 1
          : currentPage,
    });
  };

  getFieldRepresentatives = () => {
    this.setState({ fieldRepresentativesLoading: true });

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFieldRepresentativeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/fetch_field_representatives"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setFieldRepresentativesList = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({
        representativeList: responseJson.data.map((item: any) => ({
          ...item.attributes,
          id: item.id,
        })),
      });
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          type: "Error",
          message:
            "An unexpected error occurred while loading the field representatives list.",
        },
      });
    }
    this.setState({ fieldRepresentativesLoading: false });
  };

  addEditRepresentative = (representative: Representative) => {
    let isEdit = !!representative.id;

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addEditFieldRepresentativeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isEdit
        ? `/account_block/accounts/${representative.id}`
        : "/account_block/accounts/add_field_representative"
    );

    const httpData = {
      field_representative: {
        ...representative,
        id: undefined,
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      isEdit ? "Put" : "Post"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateFieldRepresentativesList = (responseJson: any) => {
    if (responseJson?.data?.attributes) {
      const updatedId =
        this.state.addEditDialogProps?.initialRepresentative?.id;
      const updatedFieldRepresentativeList = updatedId
        ? this.state.representativeList.map((item) => {
            if (item.id === updatedId) {
              return { ...responseJson.data.attributes, id: item.id };
            }
            return item;
          })
        : [
            { ...responseJson.data.attributes, id: responseJson.data.id },
            ...this.state.representativeList,
          ];

      this.setState({
        representativeList: updatedFieldRepresentativeList,
        addEditDialogProps: null,
        snackbarProps: {
          isOpen: true,
          type: "Success",
          message: updatedId
            ? "Successfully updated the field representative."
            : "Successfully added a new field representative.",
        },
      });
      this.resetAddFRForm();
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          type: "Error",
          message: `An unexpected error occurred while ${
            this.state.addEditDialogProps?.isAdd
              ? "adding a new field representative"
              : "updating the field representative"
          }.`,
        },
      });
    }
  };

  deleteFieldRepresentative = (id: string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFieldRepresentativeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Delete"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  filterDeletedFieldRepresentative = (responseJson: any) => {
    if (responseJson?.message) {
      this.setState((prevState) => ({
        representativeList: prevState.representativeList.filter(
          (representative) =>
            representative.id !== prevState.deleteRepresentativeDialogId
        ),
        deleteRepresentativeDialogId: null,
        snackbarProps: {
          isOpen: true,
          type: "Success",
          message: "Successfully removed the field representative.",
        },
      }));
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          type: "Error",
          message: `An unexpected error occurred while removing the field representative.`,
        },
      });
    }
  };

  changeStatusOfFieldRepresentative = (id: string, enabled: boolean) => {
    this.setState({ changeStatusLoading: true });
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeStatusOfFieldRepresentativeCallId = requestMessage.messageId;
    this.changeStatusOfFieldRepresentativeCallAccountId = id;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/fr_enable_disable`
    );

    const httpData = {
      account_id: id,
      enabled,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Put"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateStatusOfFieldRepresentative = (responseJson: any) => {
    if (responseJson?.enabled === true || responseJson?.enabled === false) {
      const updatedFieldRepresentativeList = this.state.representativeList.map(
        (item) => {
          if (item.id === this.changeStatusOfFieldRepresentativeCallAccountId) {
            return { ...item, enabled: responseJson.enabled };
          }
          return item;
        }
      );

      const isEnabled = responseJson?.enabled;

      this.setState({
        representativeList: updatedFieldRepresentativeList,
        addEditDialogProps: null,
        snackbarProps: {
          isOpen: true,
          type: "Success",
          message: `Successfully ${
            isEnabled ? "enabled" : "disabled"
          } field representative's account.`,
        },
      });
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          type: "Error",
          message: `An unexpected error occurred while changing status of the field representative.`,
        },
      });
    }
    this.setState({ changeStatusLoading: false });
  };

  resendInvite = (id: string) => {
    this.setState({ resendInviteLoading: true });

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resentInviteCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/resend_invite`
    );

    const httpData = {
      account_id: id,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Put"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  inviteSentResponse = (responseJson: any) => {
    if (responseJson?.message) {
      this.setState({
        snackbarProps: {
          isOpen: true,
          type: "Success",
          message:
            "Successfully resent the invite to the field representative.",
        },
      });
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          type: "Error",
          message:
            "An unexpected error occurred while resending the invite to the field representative.",
        },
      });
    }
    this.setState({ resendInviteLoading: false });
  };

  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };

  trimSpaces = (value: string) => value.trim().replace(/\s+/g, " ");

  closeSnackbar = () => {
    this.setState((prevState) => ({
      snackbarProps: { ...prevState.snackbarProps, isOpen: false },
    }));
  };

  // Customizable Area End
}
