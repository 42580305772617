import React, { FC, useState } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

interface ContextMenyButtonProps {
    buttonText: string;
}

export const CustomerContextMenuButton: FC<ContextMenyButtonProps> = ({ children, buttonText }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ButtonBase
        onClick={handleClick}
        sx={webStyle.button}
        data-test-id="contextMenuButton"
      >
        <Box sx={webStyle.buttonContent}>
          <span>{buttonText}</span>
          <Box sx={webStyle.buttonIconWrapper}>
            <ExpandMoreRoundedIcon />
          </Box>
        </Box>
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {children}
      </Menu>
    </div>
  );
};

const webStyle = {
  button: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "0 4px 0 8px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContent: {
    display: "flex",
    alignItems: "center",
    height: '100%',
  },
  buttonIconWrapper: {
    display: "flex",
    alignItems: "center",
    borderLeft: "1px solid #1A7BA4",
    paddingLeft: "4px",
    marginLeft: '8px',
    height: '100%',
  }
}