import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Radio,
  Button,
} from "@mui/material";
import { noPropertieisIcon, downloadIcon } from "./assets";
// Customizable Area End

import UnCheckedCheckboxIcon from "../assets/UnCheckedCheckboxIcon";
import CheckedCheckboxIcon from "../assets/CheckedCheckboxIcon";

import UnCheckedRadioButtonIcon from "../assets/UnCheckedRadioButtonIcon";
import CheckedRadioButtonIcon from "../assets/CheckedRadioButtonIcon";

import DashboardPageController, {
  Props,
  configJSON,
} from "./DashboardPageController";
import { CustomChart } from "../../../components/src/CustomChart.web";
// Customizable Area Start

// Customizable Area End
export default class DashboardPage extends DashboardPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      // Customizable Area Start
      <Box sx={webStyle.reportsContainer}>
        {this.state.reportList.length === 0 ? (
          <Box sx={webStyle.noReportBlock}>
            <Box sx={webStyle.noReportBlockTitle}>
              <img src={noPropertieisIcon} style={webStyle.mainImage} />
              <Typography sx={webStyle.noReportTitle}>
                {configJSON.noReportTitleText}
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={webStyle.reportsTableHeader}>
              <Typography sx={webStyle.reportTitle}>
                {configJSON.dashboardText}
              </Typography>
              <Box sx={webStyle.dashboardControl}>
                <Box sx={webStyle.searchContainer}>
                  <Typography sx={webStyle.inputLabel}>
                    {configJSON.yearText}
                  </Typography>
                  <Box sx={webStyle.fieldControl}>
                    <Select
                      value={this.state.dashboardFilter}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      data-test-id="datePeriod"
                      onChange={(event) => {
                        this.changeDashboardFilter(
                          event.target.value as number
                        );
                      }}
                      sx={webStyle.select}
                    >
                      <MenuItem value={1} sx={webStyle.selectOption}>
                        {configJSON.lastYearText}
                      </MenuItem>
                      <MenuItem value={2} sx={webStyle.selectOption}>
                        {configJSON.previusYearText}
                      </MenuItem>
                      <MenuItem value={3} sx={webStyle.selectOption}>
                        {configJSON.currentYearText}
                      </MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Button style={webStyle.downloadButton} onClick={() => {}}>
                  <img src={downloadIcon} alt="" style={webStyle.btnIcon} />
                  <Typography sx={webStyle.downloadButtonText}>
                    {configJSON.saveAsPdfText}
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box sx={webStyle.dashboardBody}>
              <Typography sx={webStyle.cunsumptionTitle}>
                {configJSON.consumptionText}
              </Typography>
              <Box sx={webStyle.filterBar}>
                <Box sx={webStyle.fieldControl}>
                  <Typography sx={webStyle.inputLabel}>
                    {configJSON.propertyNameText}
                  </Typography>
                  <Select
                    value={this.state.propertyName}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="propertyName"
                    sx={{
                      ...webStyle.selectOther,
                      ...(this.state.propertyName.length
                        ? webStyle.selectOtherActive
                        : webStyle.selectProperty),
                    }}
                    renderValue={(select) => select || "Select"}
                    placeholder={"Select"}
                    onChange={(event) => {
                      this.changePropertyName(event.target.value);
                    }}
                  >
                    <MenuItem
                      value={"Property Name 1"}
                      sx={webStyle.selectOption}
                    >
                      Property Name 1
                    </MenuItem>
                    <MenuItem
                      value={"Property Name 2"}
                      sx={webStyle.selectOption}
                    >
                      Property Name 2
                    </MenuItem>
                    <MenuItem
                      value={"Property Name 3"}
                      sx={webStyle.selectOption}
                    >
                      Property Name 3
                    </MenuItem>
                  </Select>
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <Typography sx={webStyle.inputLabel}>
                    {configJSON.tenantNameText}
                  </Typography>
                  <Select
                    value={this.state.tenant}
                    multiple
                    renderValue={(selected) =>
                      selected.length ? `${selected.length} selected` : "Select"
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="tenantName"
                    sx={{
                      ...webStyle.selectOther,
                      ...(this.state.tenant.length
                        ? webStyle.selectOtherActive
                        : {}),
                    }}
                    placeholder={"Select"}
                    onChange={(event) => {
                      console.log("event.target.value", event.target.value);
                      this.changeTenant(
                        typeof event.target.value === "string"
                          ? event.target.value.split(",")
                          : event.target.value
                      );
                    }}
                    MenuProps={MenuProps}
                  >
                    {this.state.tennatList.filter((tenant) =>
                      this.state.tenant.includes(tenant)
                    ).length > 0 ? (
                      <Box sx={webStyle.dividerContainer}>
                        <Box sx={webStyle.dividerText}>
                          {`${
                            this.state.tennatList.filter((tenant) =>
                              this.state.tenant.includes(tenant)
                            ).length
                          } selected`}
                        </Box>
                        <Box sx={webStyle.dividerSpace} />
                      </Box>
                    ) : (
                      ""
                    )}
                    {this.state.tennatList
                      .filter((tenant) => this.state.tenant.includes(tenant))
                      .map((tenant) => (
                        <MenuItem
                          value={tenant}
                          sx={webStyle.selectOtherOption}
                        >
                          <Checkbox
                            icon={<UnCheckedCheckboxIcon />}
                            checkedIcon={<CheckedCheckboxIcon />}
                            checked={this.state.tenant.includes(tenant)}
                            sx={webStyle.checkbox}
                          />
                          <ListItemText
                            primary={tenant}
                            sx={webStyle.checkboxLabel}
                          />
                        </MenuItem>
                      ))}

                    {this.state.tennatList.filter(
                      (tenant) => !this.state.tenant.includes(tenant)
                    ).length > 0 ? (
                      <Box sx={webStyle.dividerContainer}>
                        <Box sx={webStyle.dividerText}>
                          {`${
                            this.state.tennatList.filter(
                              (tenant) => !this.state.tenant.includes(tenant)
                            ).length
                          } remaining`}
                        </Box>
                        <Box sx={webStyle.dividerSpace} />
                      </Box>
                    ) : (
                      ""
                    )}
                    {this.state.tennatList
                      .filter((tenant) => !this.state.tenant.includes(tenant))
                      .map((tenant) => (
                        <MenuItem
                          value={tenant}
                          sx={webStyle.selectOtherOption}
                        >
                          <Checkbox
                            icon={<UnCheckedCheckboxIcon />}
                            checkedIcon={<CheckedCheckboxIcon />}
                            checked={this.state.tenant.includes(tenant)}
                            sx={webStyle.checkbox}
                          />
                          <ListItemText
                            primary={tenant}
                            sx={webStyle.checkboxLabel}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <Typography sx={webStyle.inputLabel}>
                    {configJSON.meterTypeText}
                  </Typography>
                  <Select
                    value={this.state.meterType}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="meterType"
                    sx={{
                      ...webStyle.selectOther,
                      ...(this.state.meterType
                        ? webStyle.selectOtherActive
                        : {}),
                    }}
                    placeholder={"Select"}
                    renderValue={(selected) => selected || "Select"}
                    onChange={(event) => {
                      this.changeMeterType(event.target.value);
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"Water"} sx={webStyle.selectOtherOption}>
                      <Box sx={webStyle.radioWrapper}>
                        <Radio
                          checked={this.state.meterType === "Water"}
                          sx={webStyle.radioButton}
                          data-test-id="meters.type.water"
                          name="Water"
                          icon={<UnCheckedRadioButtonIcon />}
                          checkedIcon={<CheckedRadioButtonIcon />}
                        />
                        <Typography sx={webStyle.radioButtonLabel}>
                          {configJSON.waterTitleText}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem
                      value={"Electric"}
                      sx={webStyle.selectOtherOption}
                    >
                      <Box sx={webStyle.radioWrapper}>
                        <Radio
                          checked={this.state.meterType === "Electric"}
                          sx={webStyle.radioButton}
                          data-test-id="meters.type.electric"
                          name="Electric"
                          icon={<UnCheckedRadioButtonIcon />}
                          checkedIcon={<CheckedRadioButtonIcon />}
                        />
                        <Typography sx={webStyle.radioButtonLabel}>
                          {configJSON.electricTypeText}
                        </Typography>
                      </Box>
                    </MenuItem>
                  </Select>
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <Typography sx={webStyle.inputLabel}>
                    {configJSON.meterIdText}
                  </Typography>
                  <Select
                    value={this.state.meter}
                    displayEmpty
                    multiple
                    renderValue={(selected) =>
                      selected.length ? `${selected.length} selected` : "Select"
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="meter"
                    sx={{
                      ...webStyle.selectOther,
                      ...(this.state.meter.length
                        ? webStyle.selectOtherActive
                        : {}),
                    }}
                    placeholder={"Select"}
                    onChange={(event) => {
                      this.changeMeter(
                        typeof event.target.value === "string"
                          ? event.target.value.split(",")
                          : event.target.value
                      );
                    }}
                    MenuProps={MenuProps}
                  >
                    {this.state.meterList.filter((meter) =>
                      this.state.meter.includes(meter)
                    ).length > 0 ? (
                      <Box sx={webStyle.dividerContainer}>
                        <Box sx={webStyle.dividerText}>
                          {`${
                            this.state.meterList.filter((meter) =>
                              this.state.meter.includes(meter)
                            ).length
                          } selected`}
                        </Box>
                        <Box sx={webStyle.dividerSpace} />
                      </Box>
                    ) : (
                      ""
                    )}
                    {this.state.meterList
                      .filter((meter) => this.state.meter.includes(meter))
                      .map((meter) => (
                        <MenuItem value={meter} sx={webStyle.selectOtherOption}>
                          <Checkbox
                            icon={<UnCheckedCheckboxIcon />}
                            checkedIcon={<CheckedCheckboxIcon />}
                            checked={this.state.meter.includes(meter)}
                            sx={webStyle.checkbox}
                          />
                          <ListItemText
                            primary={meter}
                            sx={webStyle.checkboxLabel}
                          />
                        </MenuItem>
                      ))}
                    {this.state.meterList.filter(
                      (meter) => !this.state.meter.includes(meter)
                    ).length > 0 ? (
                      <Box sx={webStyle.dividerContainer}>
                        <Box sx={webStyle.dividerText}>
                          {`${
                            this.state.meterList.filter(
                              (meter) => !this.state.meter.includes(meter)
                            ).length
                          } remaining`}
                        </Box>
                        <Box sx={webStyle.dividerSpace} />
                      </Box>
                    ) : (
                      ""
                    )}
                    {this.state.meterList
                      .filter((meter) => !this.state.meter.includes(meter))
                      .map((meter) => (
                        <MenuItem value={meter} sx={webStyle.selectOtherOption}>
                          <Checkbox
                            icon={<UnCheckedCheckboxIcon />}
                            checkedIcon={<CheckedCheckboxIcon />}
                            checked={this.state.meter.includes(meter)}
                            sx={webStyle.checkbox}
                          />
                          <ListItemText
                            primary={meter}
                            sx={webStyle.checkboxLabel}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              </Box>
              <Box sx={webStyle.chartContainer}>
                <Box sx={webStyle.chartSelectorContainer}>
                  <Button
                    variant="text"
                    sx={webStyle.chartSelector(this.state.chartType === "line")}
                    onClick={() => this.changeChartType("line")}
                  >
                    <Typography
                      sx={webStyle.chartSelectorTypographyText(
                        this.state.chartType === "line"
                      )}
                    >
                      {configJSON.lineChartText}
                    </Typography>
                  </Button>
                  <Button
                    variant="text"
                    sx={webStyle.chartSelector(this.state.chartType === "bar")}
                    onClick={() => this.changeChartType("bar")}
                  >
                    <Typography
                      sx={webStyle.chartSelectorTypographyText(
                        this.state.chartType === "bar"
                      )}
                    >
                      {configJSON.barChartText}
                    </Typography>
                  </Button>
                </Box>
                <CustomChart
                  chartType={this.state.chartType}
                  chartData={[0, 1, 2, 3, 4, 5, 6]}
                />
              </Box>
              <Box sx={webStyle.tableWrapper(1)}>
                <Box sx={webStyle.reportsTable}>
                  <Box sx={webStyle.reportsTableHead}>
                    <Typography sx={webStyle.headerTitle}>
                      {configJSON.tenantText}
                    </Typography>
                    <Typography sx={webStyle.headerTitle}>
                      {configJSON.meterIdText}
                    </Typography>
                    <Typography sx={webStyle.headerTitle}>
                      {configJSON.consumptionText}
                    </Typography>
                    <Typography sx={webStyle.headerTitle}>
                      {configJSON.dateText}
                    </Typography>
                  </Box>
                  {this.state.reportList.map((report) => (
                    <Box sx={webStyle.reportsTableBody} key={report.id}>
                      <Box sx={webStyle.reportItem}>{report.tenant}</Box>
                      <Box sx={webStyle.reportItem}>{report.meter}</Box>
                      <Box sx={webStyle.reportItem}>{report.consumption}</Box>
                      <Box sx={webStyle.reportItem}>{report.date}</Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  reportsContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(248, 250, 252)",
    padding: "0px 30px",
    minHeight: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  reportsTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    "@media (max-width: 500px)": {
      flexDirection: "column",
      margin: "10px 0px",
    },
  },
  dashboardControl: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 1330px)": {
      justifyContent: "space-between",
    },
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  tableWrapper: (totalPages: number) => ({
    minHeight: totalPages > 1 ? "535px" : "unset",
    marginBottom: "30px",
    "@media (max-width: 767px)": {
      overflow: "auto",
    },
  }),
  reportsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    margin: "0px 10px",
    "@media (max-width: 767px)": {
      minWidth: "600px",
    },
  },
  reportsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  reportsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
    "@media (max-width: 1330px)": {
      width: "25%",
    },
    "@media (max-width: 870px)": {
      width: "100%",
      flexDirection: "row",
      margin: "10px 0px",
    },
    "@media (max-width: 500px)": {
      flexDirection: "column",
      width: "100%",
    },
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 1330px)": {
      fontSize: "12px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "14px",
    },
    "@media (max-width: 870px)": {
      width: "50%",
    },
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  inputElemnt: {
    height: "44px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "25%",
    overflow: "hidden",
    margin: "12px 4px",
    "@media (max-width: 1330px)": {
      fontSize: "12px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "10px",
    },
    "@media (max-width: 870px)": {
      fontSize: "10px",
    },
    "@media (max-width: 500px)": {
      fontSize: "10px",
    },
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "200px",
    overflow: "hidden",
    margin: "12px 4px",
  },
  downloadButton: {
    backgroundColor: "#F8FAFC",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px",
    "@media (max-width: 1330px)": {
      fontSize: "14px",
      margin: "10px 4px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "16px",
      margin: "10px",
    },
    "@media (max-width: 870px)": {
      width: "100%",
      margin: "0px",
    },
    "@media (max-width: 500px)": {
      flexDirection: "column",
      margin: "10px 0px",
    },
  },
  downloadButtonText: {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    marginLeft: "8px",
    color: "#1A7BA4",
    "@media (max-width: 1330px)": {
      fontSize: "13px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "16px",
    },
  },
  reportItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "25%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 1330px)": {
      fontSize: "14px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "12px",
    },
    "@media (max-width: 870px)": {
      fontSize: "12px",
    },
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  reportItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "200px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noReportBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noReportBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noReportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  paidStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#D1FAE5",
    color: "#059669",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  pendingStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  noInvoiceStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#F1F5F9",
    color: "#475569",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  reportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  reportCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fonWeight: 500,
    lineHeight: "22px",
    color: "#334155",
  },
  select: {
    width: "187px",
    fontFamily: "'Inter', sans-serif",
    height: "44px",
    fontSize: "16px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    "& .MuiSelect-select": {
      fontFamily: "'Inter', sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
    "@media (max-width: 1330px)": {
      fontSize: "13px",
      width: "150px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "16px",
      width: "100%",
    },
    "@media (max-width: 870px)": {
      width: "100%",
    },
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  selectProperty: {
    color: "#94A3B8",
  },
  selectOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    borderRadius: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "#E8FBFF",
      color: "#1A7BA4",
      fontWeight: "600",
    },
  },
  selectOther: {
    width: "187px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    height: "44px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
    "& .MuiSelect-select": {
      fontFamily: "'Inter', sans-serif",
    },

    "@media (max-width: 1330px)": {
      fontSize: "13px",
      width: "auto",
    },
    "@media (max-width: 1036px)": {
      fontSize: "16px",
      width: "187px",
    },
    "@media (max-width: 870px)": {
      width: "50%",
    },
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  selectOtherActive: {
    color: "#0F172A",
  },
  selectOtherOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px !important",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    borderRadius: "8px",
    gap: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "inherit",
      color: "#0F172A",
      fontWeight: "400",
    },
  },
  selectOptionStart: {},
  dashboardBody: {
    border: "1.3px solid #CBD5E1",
    borderRadius: "19px",
    padding: "20px 10px",
    margin: "10px 0px",
    backgroundColor: "#FFFFFF",
  },
  cunsumptionTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "19px",
    fontWeight: 700,
    lineHeight: "29px",
    color: "#64748B",
    margin: "0px 10px",
  },
  filterBar: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0px",
    "@media (max-width: 870px)": {
      flexDirection: "column",
    },
  },
  radioWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  radioButtonElemnt: {
    height: "20px",
    width: "20px",
    padding: 0,
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    margin: 0,
  },
  radioButton: {
    padding: 0,
    width: "20px",
    height: "20px",
    "& .MuiSvgIcon-root": {
      color: "#64748B",
      width: "20px",
      height: "20px",
    },
    "&.Mui-checked": {
      backgroundColor: "#1A7BA4",
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
      "& .MuiSvgIcon-root:first-of-type": {
        color: "#1A7BA4",
      },
      "& .MuiSvgIcon-root:nth-of-type(2)": {
        color: "#FFFFFF",
      },
    },
  },
  radioButtonLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  chartSelectorContainer: {
    backgroundColor: "#E2E8F0",
    height: "40px",
    width: "fit-content",
    display: "flex",
    gap: "7px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 4px",
    marginBottom: "20px",
    borderRadius: "10px",
    border: "1px solid #E2E8F0",
    "@media (max-width: 767px)": {
      width: "100%",
    },
  },
  chartContainer: {
    padding: " 0px 10px 20px 10px",
  },
  chartSelector: (active: boolean) => ({
    color: active ? "#0F172A" : "#475569",
    fontWeight: "500",
    border: "none",
    backgroundColor: active ? "#FFFFFF" : "#E2E8F0",
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    borderRadius: "7px",
    padding: "5px 10px",
    height: "32px",
    textTransform: "math-auto",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: active ? "#FFFFFF" : "#E2E8F0",
    },
    "@media (max-width: 767px)": {
      width: "50%",
    },
  }),
  chartSelectorTypographyText: (active: boolean) => ({
    color: active ? "#0F172A" : "#475569",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: active ? 500 : 400,
    lineHeight: "22px",
  }),
  dividerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "28px",
    alignItems: "center",
    marginBottom: "12px",
  },
  dividerText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    margin: "0px 12px 0px 8px",
  },
  dividerSpace: {
    borderBottom: "1px solid #CBD5E1",
    display: "flex",
    flex: "auto",
    height: "0px",
  },
  checkbox: {
    width: "0px",
    height: "0px",
    borderRadius: "0px",
    color: "#64748B",
    "& .MuiSvgIcon-root": {
      fontSize: "28px",
      width: "20px",
      height: "20px",
    },
    "&.Mui-checked": {
      color: "#1A7BA4",
    },
  },
  checkboxLabel: {
    "> span": {
      fontSize: "14px",
      lineHeight: "22px",
      fontFamily: "'Inter', sans-serif",
    },
  },
};
// Customizable Area End
