import { BlockComponent } from "../../framework/src/BlockComponent";
import { IBlock } from "../..//framework/src/IBlock";
import { Message } from "../..//framework/src/Message";
import { runEngine } from "../..//framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../framework/src/Utilities";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  showNotification?: boolean
  showUserProfile?: boolean;
  setNotificationVisible?: any;
  setUserProfileVisible?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  notificationList: Notification[];
  accountSettings: AccountSettings;
  showNotification: boolean;
  showUserProfile: boolean;
  userRole: string;
  token: string;
}


export interface AccountSettings {
  email: string;
  full_name: string;

  first_name: string;
  last_name: string;

  initials: string;
}
interface Notification {
  id: number;
  message: string;
  title: string;
  date: string;
  readed: boolean;
}

interface SS {
  id: any;
}

export default class AccountController extends BlockComponent<Props, S, SS> {
  apiGetAccountSettingsCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      userRole: '',
      showNotification: false,
      showUserProfile: false,
      accountSettings: {
        full_name: "",
        email: "",
        first_name: "",
        last_name: "",
        initials: "",
      },
      notificationList: [
        {
          id: 1,
          title: "New reading",
          message: "A new reading has been added",
          date: "5/13/2024, 5:43PM",
          readed: false
        },
        {
          id: 2,
          title: "Meter added successfully",
          message: "The meter number 16DKJH234 has been successfully added to tentant 2HJG983",
          date: "5/13/2024, 5:43PM",
          readed: false
        },
        {
          id: 3,
          title: "New reading",
          message: "A new reading has been added",
          date: "5/13/2024, 5:43PM",
          readed: true
        },
        {
          id: 4,
          title: "Meter added successfully",
          message: "The meter number 16DKJH234 has been successfully added to tentant 2HJG983",
          date: "5/13/2024, 5:43PM",
          readed: true
        },
        {
          id: 5,
          title: "Meter added successfully",
          message: "The meter number 16DKJH234 has been successfully added to tentant 2HJG983",
          date: "5/13/2024, 5:43PM",
          readed: true
        },
        {
          id: 6,
          title: "Meter added successfully",
          message: "The meter number 16DKJH234 has been successfully added to tentant 2HJG983",
          date: "5/13/2024, 5:43PM",
          readed: true
        }
      ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiGetAccountSettingsCallId) {
        if (responseJson?.data?.attributes) {
          let { full_name, email, first_name, last_name } = responseJson?.data?.attributes;
          full_name = full_name || `${first_name} ${last_name}`;
          const initials = full_name.split(' ').map((name: any) => name.substring(0, 1)).join("");
          this.setState({ accountSettings: { full_name, email, first_name, last_name, initials } });
        }
      }
    }
  }

  setNotificationVisible = (show: boolean) => {
    this.setState({ showNotification: show })
  }

  setUserProfileVisible = (show: boolean) => {
    this.setState({ showUserProfile: show })
  }

  logOut = async () => {
    await removeStorageData('role');
    await removeStorageData('token');
  }

  getAccountSettings = () => {
    const header = {
      "Content-Type": "application/json",
      "Cache-Control": 'no-cache, no-store, max-age=0, must-revalidate',
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAccountSettingsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/logged_user"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  componentDidMount = async () => {
    const userRole = await getStorageData('role');

    this.setState({ userRole });


    await this.getToken();
    await this.getAccountSettings();
  }

  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };
  // Customizable Area End
}
