import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  InputBase,
  IconButton,
  Tooltip,
  CircularProgress,
  Checkbox,
  ListItemText,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { ReportMeter, ProRate, Report, ReportTenant } from "./CustomerReports.web";
import AddIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { CustomerEditableTextField } from "./CustomerEditableTextField.web";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { infoIcon, coldWaterIcon, hotWaterIcon } from "./assets";
import { CustomerGallery } from "./CustomerGallery.web";
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import moment from "moment";
import { Price, Tenant } from "./CustomersPageController";

export interface CustomerCreateEditReportProps {
  generateReport: (report: Report) => void;
  report: Report | null;
  getReport: () => void;
  reportLoading: boolean;
  getTenants: () => void;
  tenantsLoading: boolean;
  tenants: Tenant[];
  priceRates: Price[];
  priceRatesLoading: boolean;
  getPriceRates: () => void;
  isTest?: boolean;
  initialTenantFilter?: number[];
}

const prices = ['Price 1', 'Price 2', 'Price 3'];

interface ReportCommentDialogProps {
  isOpen: boolean;
}

interface MeterCommentDialogProps {
  isOpen: boolean;
  comment: string;
  date: string;
}

interface EstimatedReadingDialogProps {
  isOpen: boolean;

  onAccept?: (() => void) | null;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const cubicFeetToGallons = (cubicFeet: number) => {
  return Math.round(cubicFeet * 7.48052);
}

export const CustomerCreateEditReport = ({
  report,
  generateReport,
  getTenants,
  tenantsLoading,
  tenants,
  isTest,
  priceRates,
  priceRatesLoading,
  getPriceRates,
  getReport,
  reportLoading,
  initialTenantFilter,
}: CustomerCreateEditReportProps) => {
  const [internalReport, setInternalReport] = useState<Report | null>(report);
  const [tenantFilter, setTenantFilter] = useState<(number)[]>(initialTenantFilter || []);
  const [reportCommentDialogProps, setReportCommentDialogProps] = useState<ReportCommentDialogProps>({ isOpen: false });
  const [meterCommentDialogProps, setMeterCommentDialogProps] = useState<MeterCommentDialogProps>({ isOpen: false, comment: '', date: '' });
  const [editedComment, setEditedComment] = useState("");
  const [requestsFired, setRequestsFired] = useState(false);
  const [estimatedReadingDialogProps, setEstimatedReadingDialogProps] = useState<EstimatedReadingDialogProps>({ isOpen: false })

  useEffect(() => {
    getTenants();
    getPriceRates();
    getReport();
    setRequestsFired(true);
  }, []);

  useEffect(() => {
    if (report) {
      setInternalReport(report);
      setTenantFilter(report?.tenantsList.map((item) => Number(item.id)));
    }
  }, [report]);

  const isTenantRowVisible = useCallback((tenantId: string) => {
    return tenantFilter.includes(Number(tenantId)) && !internalReport?.proRates.find((proRate) => Number(proRate.tenant_id) === Number(tenantId));
  }, [internalReport, tenantFilter]);

  const {
    sumOfPresent,
    sumOfPrevious,
    sumOfConsumptionKW,
    sumOfConsumptionGallons,
    sumOfConsumptionCubicFeet,
    sumOfAmount,
  } = useMemo(
    () => {
      const sums = {
        sumOfPresent: 0,
        sumOfPrevious: 0,
        sumOfConsumptionKW: 0,
        sumOfConsumptionGallons: 0,
        sumOfConsumptionCubicFeet: 0,
        sumOfAmount: 0,
      };

      internalReport?.tenantsList.forEach((tenant) => {
        if (isTenantRowVisible(tenant.id)) {
          tenant.meters.forEach((meter) => {
            const consumption = (meter.present || 0) - (meter.previous || 0);
            sums.sumOfPresent += meter.present;
            sums.sumOfPrevious += meter.previous;
            sums.sumOfConsumptionKW += consumption;
            sums.sumOfConsumptionGallons += cubicFeetToGallons(consumption);
            sums.sumOfConsumptionCubicFeet += consumption;
            sums.sumOfAmount += Number(meter.amount);
          });
        }
      });

      internalReport?.proRates.forEach((proRate) => {
        if (proRate.tenant_id && proRate.amount) {
          sums.sumOfAmount += Number(proRate.amount);
        }
      })

      return sums;
    },
    [internalReport?.tenantsList, internalReport?.proRates, isTenantRowVisible]
  );

  if (tenantsLoading || priceRatesLoading || reportLoading || !internalReport || (!requestsFired && !isTest)) {
    return (
      <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
        <CircularProgress sx={webStyle.loader} />
      </Box>
    )
  }

  const sumOfAmountFormatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(sumOfAmount));

  const handleAddProRate = () => {
    const newProRates = [...(internalReport.proRates || [])];

    newProRates.push({
      tenant_name: '',
      tenant_id: '',
      amount: 0,
    });

    setInternalReport({
      ...internalReport,
      proRates: newProRates,
    });
  };

  const handleProRateChange = (
    fieldName: keyof ProRate,
    value: string | number,
    proRateIndex: number
  ) => {
    const newProRates = internalReport.proRates
      ? [...internalReport.proRates]
      : [];


    newProRates[proRateIndex][fieldName] = value as never;

    if (fieldName === 'tenant_id') {
      newProRates[proRateIndex].tenant_name = tenants.find((tenant) => Number(tenant.id) === Number(value))?.tenant_name || '';
    }

    setInternalReport({
      ...internalReport,
      proRates: newProRates,
    });
  };

  const handleReportCommentDialogOpen = () => {
    setEditedComment(internalReport.comment || "");
    setReportCommentDialogProps({ isOpen: true });
  };

  const handleCommentDialogCancel = () => {
    setReportCommentDialogProps({ isOpen: false });
  };

  const handleCommentDialogSubmit = () => {
    setInternalReport({
      ...internalReport,
      comment: editedComment,
    });
    setReportCommentDialogProps({ isOpen: false });
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedComment(event.target.value);
  };

  const handleReportMeterNumericValueChange = (
    fieldName: keyof ReportMeter,
    value: string,
    tenantIndex: number,
    meterIndex: number
  ) => {
    const newTenantsList = [...internalReport.tenantsList];
    const newMeterList = [...internalReport.tenantsList[tenantIndex].meters];

    newMeterList[meterIndex][fieldName] = Number(value) as never;
    newTenantsList[tenantIndex].meters = newMeterList;

    setInternalReport({
      ...internalReport,
      tenantsList: newTenantsList,
    });
  };

  const handleReportMeterRateChange = (
    value: number,
    tenantIndex: number,
    meterIndex: number
  ) => {
    const newTenantsList = [...internalReport.tenantsList];
    const newMeterList = [...internalReport.tenantsList[tenantIndex].meters];

    newMeterList[meterIndex].price_id = value;
    newTenantsList[tenantIndex].meters = newMeterList;

    setInternalReport({
      ...internalReport,
      tenantsList: newTenantsList,
    });
  };

  const handleReportMeterMarkAsEstimated = (
    isMarked: boolean,
    fieldName: 'isPresentEstimated' | 'isPreviousEstimated',
    tenantIndex: number,
    meterIndex: number,
  ) => {
    const newTenantsList = [...internalReport.tenantsList];
    const newMeterList = [...internalReport.tenantsList[tenantIndex].meters];

    newMeterList[meterIndex][fieldName] = isMarked;
    newTenantsList[tenantIndex].meters = newMeterList;

    setInternalReport({
      ...internalReport,
      tenantsList: newTenantsList,
    });
  }

  const handleRemoveProRate = (proRateIndex: number) => {
    if (!internalReport.proRates) {
        return;
    }

    setInternalReport({
        ...internalReport,
        proRates: internalReport.proRates.filter((_, index) => index !== proRateIndex),
    });
  }

  const handleGenerateReport = () => {
    generateReport({
      ...internalReport,
      tenantsList: internalReport.tenantsList.filter((tenant) => isTenantRowVisible(tenant.id)),
      proRates: internalReport.proRates.filter((proRate) => !!proRate.tenant_id)
    });
  };

  const recalculateAmount = (previous: number, present: number, priceId: number, tenantIndex: number, meterIndex: number) => {
    let consumption = present - previous;
    const price = priceRates.find((price) => price.id === priceId) // change to price.id
    const consumptionRates = price?.consumptions.sort((a, b) => a.amount - b.amount) || [];
    let amount = 0;

    consumptionRates.forEach((consumptionRate, index) => {
      if (consumption <= 0) {
        return;
      }

      if (index === consumptionRates.length - 1) {
        amount += consumption * consumptionRate.rate;

        return;
      }

      amount += Math.min(consumptionRate.amount, consumption) * consumptionRate.rate;
      consumption -= consumptionRate.amount;
    });

    handleReportMeterNumericValueChange('amount', amount.toFixed(2).toString(), tenantIndex, meterIndex);
  }

  return (
    <Box sx={webStyle.tenantsContainer}>
      <Box sx={webStyle.tenantsTableHeader}>
        <Box sx={webStyle.filterWrapper}>
          <Box sx={webStyle.tenantSelectContainer}>
            <label style={webStyle.tenantSelectLabel}>Tenant</label>
            
            <Select
                    value={tenantFilter}
                    multiple
                    renderValue={(selected) =>
                      selected.length ? `${selected.length} selected` : "Select"
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="premise"
                    sx={{...webStyle.selectOther, ...(tenantFilter.length ? webStyle.selectOtherActive : {})}}
                    placeholder={"Select"}
                    onChange={(event) => {
                      console.log("event.target.value", event.target.value);
                      setTenantFilter(event.target.value as number[]);
                    }}
                    MenuProps={MenuProps}
                  >
                    {tenants.filter((tenant) =>
                      tenant.id && tenantFilter.includes(tenant.id)
                    ).length > 0 ? (
                      <Box sx={webStyle.dividerContainer}>
                        <Box sx={webStyle.dividerText}>
                          {`${
                            tenants.filter((tenant) =>
                              tenant.id && tenantFilter.includes(tenant.id)
                            ).length
                          } selected`}
                        </Box>
                        <Box sx={webStyle.dividerSpace} />
                      </Box>
                    ) : (
                      ""
                    )}
                    {tenants
                      .filter((tenant) => tenant.id && tenantFilter.includes(tenant.id))
                      .map((tenant) => (
                        <MenuItem
                          value={tenant.id || ''}
                          sx={webStyle.selectOtherOption}
                          key={tenant.id || ''}
                        >
                          <Checkbox
                            checked={!!tenant.id && tenantFilter.includes(tenant.id)}
                            sx={webStyle.checkbox}
                          />
                          <ListItemText primary={tenant.tenant_name} sx={webStyle.checkboxLabel} />
                        </MenuItem>
                      ))}

                    {tenants.filter(
                      (tenant) => tenant.id && !tenantFilter.includes(tenant.id)
                    ).length > 0 ? (
                      <Box sx={webStyle.dividerContainer}>
                        <Box sx={webStyle.dividerText}>
                          {`${
                            tenants.filter(
                              (tenant) => tenant.id && !tenantFilter.includes(tenant.id)
                            ).length
                          } remaining`}
                        </Box>
                        <Box sx={webStyle.dividerSpace} />
                      </Box>
                    ) : (
                      ""
                    )}
                    {tenants
                      .filter((tenant) => tenant.id && !tenantFilter.includes(tenant.id))
                      .map((tenant) => (
                        <MenuItem
                          value={tenant.id || ''}
                          sx={webStyle.selectOtherOption}
                          key={tenant.id || ''}
                        >
                          <Checkbox
                            checked={!!tenant.id && tenantFilter.includes(tenant.id)}
                            sx={webStyle.checkbox}
                          />
                          <ListItemText primary={tenant.tenant_name} sx={webStyle.checkboxLabel} />
                        </MenuItem>
                      ))}
                  </Select>
          </Box>
        </Box>
        <Box sx={webStyle.actionContainer}>
          <button
            style={webStyle.commentButton}
            onClick={handleReportCommentDialogOpen}
            data-test-id="commentOpen"
          >
            <Typography sx={webStyle.buttonText}>Comment</Typography>
          </button>
          <button
            style={webStyle.generateReportButton}
            onClick={handleGenerateReport}
            data-test-id="generateReport"
          >
            <Typography sx={webStyle.buttonText}>Generate report</Typography>
          </button>
        </Box>
      </Box>
      <Box>
        <Typography
          fontFamily={"'Inter', sans-serif"}
          fontSize={"16px"}
          color={"#334155"}
          fontWeight={500}
          marginBottom={"16px"}
          marginTop={'20px'}
          data-test-id="resultsText"
        >
          {internalReport?.tenantsList?.filter((tenant) => isTenantRowVisible(tenant.id)).length} results
        </Typography>
      </Box>
      <Box sx={webStyle.tenantsTable}>
        <Box sx={webStyle.tenantsTableHead}>
          <Typography style={webStyle.headerTitle}>TENANTS</Typography>
          <Typography style={webStyle.headerTitle}>METER#</Typography>
          {internalReport.type === "Water" && (
            <Typography style={webStyle.headerTitle}>TYPE</Typography>
          )}
          <Typography style={webStyle.headerTitle}>PRESENT</Typography>
          <Typography style={webStyle.headerTitle}>PREVIOUS</Typography>
          <Box style={{...webStyle.headerTitle, ...(internalReport.type === 'Water' ? { margin: '3px 4px' } : {})}}>
            {internalReport.type === "Water" ? (
              <Box sx={webStyle.tenantConsumptionHeader}>
                <Typography
                  sx={{
                    ...webStyle.tenantConsumptionHeaderText,
                    ...webStyle.tenantConsumptionHeaderTextTitle,
                  }}
                >
                  CONSUMPTION
                </Typography>
                <Box sx={webStyle.tenantConsumption}>
                  <Typography sx={webStyle.tenantConsumptionHeaderText}>
                    GALLONS
                  </Typography>
                  <Typography sx={webStyle.tenantConsumptionHeaderText}>
                    CU FT
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Typography sx={webStyle.tenantConsumptionHeaderText}>
                CONSUMPTION kW
              </Typography>
            )}
          </Box>
          <Box style={webStyle.headerTitle}>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                width: "fit-content",
              }}
            >
              <Typography sx={webStyle.tenantConsumptionHeaderText}>
                RATE
              </Typography>
              <Tooltip
                title={`Rate is in ${
                  internalReport.type === "Water" ? "Cubic Feet" : "kW"
                }`}
                componentsProps={tooltipStyles}
                placement="top"
                arrow
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={infoIcon}
                  alt="Info"
                />
              </Tooltip>
            </Box>
          </Box>
          <Typography style={{...webStyle.headerTitle, width: '150%'}}>AMOUNT</Typography>
        </Box>
        <Box sx={webStyle.tenantsTableBody}>
          <Box sx={webStyle.tenantsDataTableBody}>
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>
              Entire premises
            </Box>
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>
              {internalReport.id}
            </Box>
            {internalReport.type === "Water" && (
              <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>-</Box>
            )}
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>
              {sumOfPresent}
            </Box>
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>
              {sumOfPrevious}
            </Box>
            <Box sx={webStyle.tenantItem}>
              {internalReport.type === "Water" ? (
                <Box sx={webStyle.tenantConsumption}>
                  <Typography
                    sx={{ ...webStyle.tenantConsumptionText, fontWeight: 700 }}
                  >
                    {sumOfConsumptionGallons}
                  </Typography>
                  <Typography
                    sx={{ ...webStyle.tenantConsumptionText, fontWeight: 700 }}
                  >
                    {sumOfConsumptionCubicFeet}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  sx={{ ...webStyle.tenantConsumptionText, fontWeight: 700 }}
                >
                  {sumOfConsumptionKW}
                </Typography>
              )}
            </Box>
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>-</Box>
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700, width: '150%' }}>
              {sumOfAmountFormatted}
            </Box>
          </Box>
        </Box>
        {internalReport.tenantsList.map(
          (tenant: ReportTenant, tenantIndex: number) => tenant.meters.map((meter: ReportMeter, meterIndex: number) => (
              <Box
                sx={{ ...webStyle.tenantsTableBody, flexDirection: "column", display: isTenantRowVisible(tenant.id) ? 'flex' : 'none' }}
                key={meter.id}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: "row",
                  }}
                  key={meter.id}
                >
                  <Box sx={webStyle.tenantItem}>
                    {meterIndex === 0 ? tenant.name : ""}
                  </Box>
                  <Box sx={webStyle.tenantItem}>{meter.meter_number}</Box>
                  {internalReport.type === "Water" && (
                    <Box sx={{ ...webStyle.tenantItem, gap: "4px" }}>
                      <img
                        src={
                          internalReport.waterSubType === "Cold" ? coldWaterIcon : hotWaterIcon
                        }
                      />
                      <Box>{internalReport.waterSubType}</Box>
                    </Box>
                  )}
                  <Box sx={webStyle.tenantItem}>
                    <CustomerEditableTextField
                      initialValue={meter.present?.toString() || '0'}
                      onChange={(value: string) =>{
                        handleReportMeterNumericValueChange(
                          "present",
                          value,
                          tenantIndex,
                          meterIndex
                        )
                        recalculateAmount(meter.previous, Number(value), meter.price_id, tenantIndex, meterIndex);
                      }}
                      onAccept={(value: string) => {
                        if (value && value !== '0') {
                          setEstimatedReadingDialogProps({
                            isOpen: true,
                            onAccept: () => {
                              !meter.isPresentEstimated && handleReportMeterMarkAsEstimated(true, 'isPresentEstimated', tenantIndex, meterIndex);
                            }
                          })
                        } else {
                          meter.isPresentEstimated && handleReportMeterMarkAsEstimated(false, 'isPresentEstimated', tenantIndex, meterIndex);
                        }
                      }}
                      onUndo={() => {
                        meter.isPresentEstimated && handleReportMeterMarkAsEstimated(false, 'isPresentEstimated', tenantIndex, meterIndex);
                      }}
                      isMarked={meter.isPresentEstimated}
                      emptyValue="-"
                    />
                  </Box>
                  <Box sx={webStyle.tenantItem}>
                    <CustomerEditableTextField
                      initialValue={meter.previous?.toString() || '0'}
                      onChange={(value: string) =>{
                        handleReportMeterNumericValueChange(
                          "previous",
                          value,
                          tenantIndex,
                          meterIndex
                        )
                        recalculateAmount(Number(value), meter.present, meter.price_id, tenantIndex, meterIndex);
                      }}
                      onAccept={(value: string) => {
                        if (value && value !== '0') {
                          setEstimatedReadingDialogProps({
                            isOpen: true,
                            onAccept: () => {
                              !meter.isPreviousEstimated && handleReportMeterMarkAsEstimated(true, 'isPreviousEstimated', tenantIndex, meterIndex);
                            }
                          })
                        } else {
                          meter.isPresentEstimated && handleReportMeterMarkAsEstimated(false, 'isPreviousEstimated', tenantIndex, meterIndex);
                        }
                      }}
                      onUndo={() => {
                        meter.isPreviousEstimated && handleReportMeterMarkAsEstimated(false, 'isPreviousEstimated', tenantIndex, meterIndex);
                      }}
                      isMarked={meter.isPreviousEstimated}
                      emptyValue="-"
                    />
                  </Box>
                  <Box sx={webStyle.tenantItem}>
                    {internalReport.type === "Water" ? (
                      <Box sx={webStyle.tenantConsumption}>
                        <Typography sx={webStyle.tenantConsumptionText}>
                          {cubicFeetToGallons((meter.present || 0) - (meter.previous || 0))}
                        </Typography>
                        <Typography sx={webStyle.tenantConsumptionText}>
                          {(meter.present || 0) - (meter.previous || 0)}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography sx={webStyle.tenantConsumptionText}>
                        {(meter.present || 0) - (meter.previous || 0)}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={webStyle.tenantItem}>
                    <StyledSelect
                        value={Number(meter.price_id) || ''}
                        onChange={(event) =>{
                          handleReportMeterRateChange(Number(event.target.value), tenantIndex, meterIndex)
                          recalculateAmount(meter.previous, meter.present, Number(event.target.value), tenantIndex, meterIndex);
                        }}
                        renderValue={(select) => {
                          if (!select) {
                            return '-';
                          }
                          
                          return priceRates.find((price) => Number(price.id) === Number(select))?.name || '-';
                        }}
                        displayEmpty
                    >
                        {priceRates.map((price) => (
                            <MenuItem value={Number(price.id)} key={price.id} sx={webStyle.selectTenantOption}>
                                <Box sx={webStyle.selectRateText}>
                                    {price.name}
                                </Box>
                            </MenuItem>
                        ))}
                    </StyledSelect>
                  </Box>
                  <Box sx={{...webStyle.tenantItem, width: '150%'}}>
                    <CustomerEditableTextField
                      initialValue={meter.amount?.toString() || '0'}
                      onChange={(value: string) =>
                        handleReportMeterNumericValueChange(
                          "amount",
                          value,
                          tenantIndex,
                          meterIndex
                        )
                      }
                      isMoneyValue
                    />
                    <Box sx={{ marginLeft: '8px' }}>
                      <CustomerGallery urls={meter.images || []} />
                    </Box>
                    <Box sx={{ marginLeft: '8px' }}>
                      <IconButton 
                        sx={webStyle.meterCommentButton(!!meter.comment)}
                        onClick={() => setMeterCommentDialogProps({ isOpen: true, comment: meter.comment || '', date: meter.commentDate || '' })}
                        data-test-id="meterCommentOpen"
                      >
                        <ChatBubbleOutlineRoundedIcon sx={webStyle.commentIcon} />
                        {/* {meter.comment && (
                          <Box sx={webStyle.commentBadge} data-test-id="commentBadge" />
                        )} */}
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
              )
          )
        )}
      </Box>
      {internalReport.proRates && internalReport.proRates?.length > 0 && (
        <>
          <Typography sx={webStyle.proRateTitle}>Pro-rate</Typography>
          <Box sx={webStyle.tenantsTable}>
            {internalReport.proRates.map((proRate, proRateIndex) => (
              <Box sx={webStyle.proRateTableRow} key={proRateIndex} data-test-id="proRateRow">
                <Box
                  sx={{
                    ...webStyle.tenantItem,
                    margin: "0 4px",
                    fontWeight: 700,
                  }}
                >
                  <Select
                    value={proRate.tenant_id}
                    sx={webStyle.selectProRateTenant}
                    onChange={(event) =>{
                      handleProRateChange(
                        "tenant_id",
                        event.target.value || '',
                        proRateIndex
                      )
                    }}
                    renderValue={() => proRate.tenant_name}
                  >
                    {tenants.map((tenant) => internalReport.proRates.findIndex((proRate) => Number(proRate.tenant_id) === Number(tenant.id)) === -1 && (
                      <MenuItem
                        value={tenant.id || ''}
                        sx={webStyle.selectTenantOption}
                        key={tenant.id || ''}
                      >
                        {tenant.tenant_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Typography sx={{ ...webStyle.tenantItem, margin: "0 4px" }} />
                <Typography sx={{ ...webStyle.tenantItem, margin: "0 4px" }} />
                <Typography sx={{ ...webStyle.tenantItem, margin: "0 4px" }} />
                <Typography sx={{ ...webStyle.tenantItem, margin: "0 4px" }} />
                <Typography sx={{ ...webStyle.tenantItem, margin: "0 4px" }} />
                {internalReport.type === "Water" && (
                  <Typography
                    sx={{ ...webStyle.tenantItem, margin: "0 4px" }}
                  />
                )}
                <Box
                  sx={{
                    ...webStyle.tenantItem,
                    margin: "0 4px",
                    fontWeight: 700,
                  }}
                >
                  <CustomerEditableTextField
                    initialValue={proRate.amount.toString()}
                    onChange={(value: string) =>
                      handleProRateChange("amount", value, proRateIndex)
                    }
                    valueClass={{ width: 'max-content' }}
                    isMoneyValue
                  />
                </Box>
                <IconButton sx={webStyle.removeItemButton} onClick={() => handleRemoveProRate(proRateIndex)}>
                    <RemoveRoundedIcon sx={webStyle.removeItemIcon} />
                </IconButton>
              </Box>
            ))}
          </Box>
        </>
      )}
      {tenants.length > 0 && internalReport.proRates.length < tenants.length && (
        <button
          style={webStyle.addNewProRateButton}
          type="button"
          onClick={handleAddProRate}
          data-test-id="addProRate"
        >
          <AddIcon style={{ ...webStyle.btnIcon, color: "#1A7BA4" }} />
          <Typography style={webStyle.typographyTitleText1}>
            Add a pro-rate
          </Typography>
        </button>
      )}
      <Box sx={webStyle.estimatedReadingWrapper}>
        <Typography sx={webStyle.asterix}>*</Typography>
        <Typography sx={webStyle.estimatedReadingText}>
          estimated reading
        </Typography>
      </Box>
      <Dialog
        open={reportCommentDialogProps.isOpen}
        onClose={handleCommentDialogCancel}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "32px",
            maxHeight: "calc(100% - 120px)",
          },
        }}
      >
        <DialogTitle style={{ padding: "0 0 32px", position: "relative" }}>
          <Typography variant="h6" sx={webStyle.dialogTitleText}>
            {`${internalReport.comment ? 'Edit' : 'Add'} comment`}
          </Typography>
          <IconButton
            onClick={handleCommentDialogCancel}
            style={{
              position: "absolute",
              right: "0",
              top: "-8px",
              width: "56px",
              height: "56px",
              backgroundColor: "#F8FAFC",
              borderRadius: "52px",
            }}
          >
            <CloseRoundedIcon
              sx={{ width: "24px", height: "24px", color: "#64748B" }}
            />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{ padding: 0, overflowY: "unset", margin: "32px 0" }}
        >
          <Box sx={webStyle.commentWrapper}>
            <label style={webStyle.commentLabel}>Comment</label>
            <StyledCommentInput
              value={editedComment}
              onChange={handleCommentChange}
              placeholder="Start typing..."
              rows={7}
              multiline
            />
          </Box>
        </DialogContent>
        <Divider />
        <Box sx={{ ...webStyle.buttonsWrapper, marginTop: "22px" }}>
          <button
            style={webStyle.cancelButton}
            data-test-id="cancelDialogButton"
            type="button"
            onClick={handleCommentDialogCancel}
          >
            Cancel
          </button>
          <button
            style={webStyle.submitButton}
            data-test-id="submitDialogButton"
            type="button"
            onClick={handleCommentDialogSubmit}
          >
            Save
          </button>
        </Box>
      </Dialog>
      <Dialog
        open={meterCommentDialogProps.isOpen}
        onClose={() => setMeterCommentDialogProps({ ...meterCommentDialogProps, isOpen: false })}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "32px",
            maxHeight: "calc(100% - 120px)",
          },
        }}
        data-test-id="meterCommentDialog"
      >
        <DialogTitle style={{ padding: "0 0 32px", position: "relative" }}>
          <Typography variant="h6" sx={webStyle.dialogTitleText}>
            Comment
          </Typography>
          <IconButton
            onClick={() => setMeterCommentDialogProps({ ...meterCommentDialogProps, isOpen: false })}
            style={{
              position: "absolute",
              right: "0",
              top: "-8px",
              width: "56px",
              height: "56px",
              backgroundColor: "#F8FAFC",
              borderRadius: "52px",
            }}
          >
            <CloseRoundedIcon
              sx={{ width: "24px", height: "24px", color: "#64748B" }}
            />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{ padding: 0, overflowY: "unset", margin: "32px 0" }}
        >
          <Box sx={webStyle.commentWrapper}>
            <label style={webStyle.commentLabel}>{meterCommentDialogProps.date ? moment(meterCommentDialogProps.date).format('M/D/YYYY hh:mmA') : ''}</label>
            <StyledCommentInput
              value={meterCommentDialogProps.comment}
              rows={7}
              multiline
              disabled
            />
          </Box>
        </DialogContent>
        <Divider />
        <Box sx={{ ...webStyle.buttonsWrapper, marginTop: "22px" }}>
          <button
            style={webStyle.cancelButton}
            data-test-id="cancelDialogButton"
            type="button"
            onClick={() => setMeterCommentDialogProps({ ...meterCommentDialogProps, isOpen: false })}
          >
            Close
          </button>
        </Box>
      </Dialog>
      <Dialog
        open={estimatedReadingDialogProps.isOpen}
        onClose={() => setEstimatedReadingDialogProps({ isOpen: false })}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "32px",
            maxHeight: "calc(100% - 120px)",
          },
        }}
        data-test-id="estimatedReadingDialog"
      >
        <DialogTitle style={{ padding: 0, position: "relative" }}>
          <Typography variant="h6" sx={webStyle.dialogTitleText}>
            Do you want to mark this as an estimated reading?
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{ padding: 0, overflowY: "unset", margin: "32px 0" }}
        >
          <Box sx={webStyle.estimatedReadingDialogText}>
            This will add an asterisk next to the reading to let people know that this is an estimated reading.
          </Box>
        </DialogContent>
        <Divider />
        <Box sx={{ ...webStyle.buttonsWrapper, marginTop: "22px" }}>
          <button
            style={{...webStyle.cancelButton, border: 'none'}}
            data-test-id="cancelMarkEstimatedButton"
            type="button"
            onClick={() => setEstimatedReadingDialogProps({ isOpen: false })}
          >
            No, thanks
          </button>
          <button
            style={webStyle.cancelButton}
            data-test-id="markEstimatedButton"
            type="button"
            onClick={() => {
              estimatedReadingDialogProps.onAccept?.();
              setEstimatedReadingDialogProps({ isOpen: false })
            }}
          >
            Mark as estimated
          </button>
        </Box>
      </Dialog>
    </Box>
  );
};

const tooltipStyles = {
  tooltip: {
    sx: {
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "13px",
      lineHeight: "18px",
      fontWeight: "400",
      padding: "8px 12px",
      backgroundColor: "#334155",
      borderRadius: "8px",
      width: "fit-content",
      maxWidth: "480px",
      "& .MuiTooltip-arrow": {
        color: "#334155",
      },
    },
  },
};

const StyledSearchInput = styled(InputBase)(() => ({
    height: "44px",
    padding: "12px 44px 12px 12px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    fontFamily: "'Inter', sans-serif",

    '& .MuiInputBase-input': {
      fontSize: '16px',

      '&::placeholder': {
        fontFamily: "'Inter', sans-serif",
        color: '#94A3B8',
        fontSize: '16px',
        opacity: 1, // Firefox requires this
      },
    },
  }));

const StyledSelect = styled(Select)(() => ({
  borderRadius: "100px",
  height: "22px",
  fontSize: "12px",
  width: '100px',
  "& .MuiSelect-select": {
        padding: "0 32px 0 8px !important",
    },
  "& .MuiSelect-icon": {
    borderRadius: "0 100px 100px 0",
    borderLeft: "1px solid #E2E8F0",
    height: "22px",
    right: 0,
    top: 0,
    backgroundColor: "#F1F5F9",
  },
  "& .MuiSelect-iconOpen": {
    borderLeft: 'none',
    borderRadius: '100px 0 0 100px',
    borderRight: '1px solid #E2E8F0',
  },
}));

const StyledCommentInput = styled(InputBase)(() => ({
  padding: "10px 12px",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  backgroundColor: "#FFFFFF",
  fontFamily: "'Inter', sans-serif",
  width: "100%",

  "& .MuiInputBase-input": {
    fontSize: "16px",
    color: "#0F172A !important",
    '-webkit-text-fill-color': '#0F172A !important',

    "&::placeholder": {
      fontFamily: "'Inter', sans-serif",
      color: "#94A3B8",
      fontSize: "16px",
      opacity: 1, // Firefox requires this
    },
  },
}));

const webStyle = {
  tenantsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  tenantsTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "30px 0 10px",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tenantsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    backgroundColor: '#FFFFFF',
  },
  tenantsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 12px",
    backgroundColor: "#F8FAFC",
    borderRadius: "12px 12px 0 0",
  },
  tenantsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 12px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    position: 'relative',
    "&:last-of-type": {
      borderRadius: "0 0 12px 12px",
    },
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchElement: {
    padding: "10px 40px 10px 10px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  commentButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#1A7BA4",
    borderRadius: "7px",
    backgroundColor: "#F8FAFC",
    border: "1px solid #1A7BA4",
    display: "flex",
    height: "44px",
    alignItems: "center",
    margin: "10px 0 0",
    padding: "0 16px",
  },
  generateReportButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "7px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    height: "44px",
    alignItems: "center",
    margin: "10px 0 0 12px",
    padding: "0 16px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "100%",
    margin: "13px 4px",
  },
  tenantItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "100%",
    margin: "9px 4px",
    alignItems: "center",
    display: "flex",
    wordBreak: "break-word",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  selectProRateTenant: {
    fontFamily: "'Inter', sans-serif",
    height: "32px",
    fontSize: "14px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    width: "100%",
    fontWeight: 700,
    "& .MuiSelect-select": {
      fontFamily: "'Inter', sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
  },
  selectRateText: {
    fontFamily: "'Inter', sans-serif",
    padding: '2px 8px',
  },
  selectTenant: {
    fontFamily: "'Inter', sans-serif",
    height: "44px",
    fontSize: "16px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    minWidth: "220px",
    "& .MuiSelect-select": {
      fontFamily: "'Inter', sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
  },
  selectTenantOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    borderRadius: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "#E8FBFF",
      color: "#1A7BA4",
      fontWeight: "600",
    },
  },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "10px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 20px",
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "10px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
  },
  dialogTitleText: {
    fontWeight: "700",
    fontSize: "24px",
    fontFamily: "'Inter', sans-serif",
  },
  tenantsDataTableHead: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  tenantsDataTableBody: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
  },
  tenantConsumption: {
    display: "flex",
    width: "100%",
  },
  tenantConsumptionText: {
    flex: "1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
  },
  tenantConsumptionHeaderText: {
    flex: "1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "#64748B",
  },
  tenantConsumptionHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
  },
  addNewProRateButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "8px",
    minHeight: "44px",
    borderRadius: "8px",
    border: "none",
    background: "inherit",
    color: "#1A7BA4",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  typographyTitleText1: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  proRateTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    color: "#334155",
    margin: "8px 0",
  },
  proRateTableRow: {
    display: "flex",
    padding: "12px",
    borderBottom: "1px solid #CBD5E1",
    position: 'relative',
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  filterWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  },
  tenantSelectContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    marginTop: "-11px",
    width: '320px',
  },
  tenantSelectLabel: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    color: "#334155",
  },
  estimatedReadingWrapper: {
    display: "flex",
    fontSize: "14px",
    marginTop: "16px",
  },
  asterix: {
    color: "#DC2626",
  },
  estimatedReadingText: {
    color: "#0F172A",
  },
  tenantConsumptionHeaderTextTitle: {
    textAlign: "center",
    transform: "translateX(-12.5%)",
  },
  buttonsWrapper: {
    display: "flex",
    gap: "8px",
    marginTop: "24px",
    justifyContent: "flex-end",
  },
  commentLabel: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    color: "#334155",
  },
  commentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  removeItemButton: {
    position: 'absolute',
    left: -41,
    top: 12,
    width: '32px',
    height: '32px',
    backgroundColor: '#FEF2F2',
    borderRadius: '100px',
  },
  removeItemIcon: {
    color: '#DC2626',
    width: '24px',
    height: '24px',
  },
  meterCommentButton: (visible: boolean): any => ({
    position: 'relative',
    height: '28px',
    width: '28px',
    backgroundColor: '#E8F9FC',
    borderRadius: '50%',
    visibility: visible ? undefined : 'hidden',
  }),
  commentIcon: {
    height: '20px',
    width: '20px',
    color: '#1A7BA4',
  },
  commentBadge: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: '100%',
    backgroundColor: '#DC2626',
    width: '8px',
    height: '8px',
  },
  loaderWrapper: {
   margin: '200px auto 0',
   width: 'fit-content',
  },
  loader: {
   color: '#1A7BA4',
   height: '60px',
   width: '60px',
  },
  selectOther: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    height: "44px",
    borderRadius: '8px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CBD5E1',
    }
  },
  selectOtherActive: {
    color: '#0F172A',
  },
  selectOtherOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px !important",
    color: '#0F172A',
    padding: '8px 12px',
    margin: '0 8px 12px',
    borderRadius: '8px',
    gap: '8px',
    '&:last-of-type': {
      margin: '0 8px',
    },
    '&.Mui-selected': {
      backgroundColor: 'inherit',
      color: '#0F172A',
      fontWeight: '400',
    }
  },
  dividerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "28px",
    alignItems: "center",
    marginBottom: '12px',
  },
  dividerText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    margin: "0px 12px 0px 8px",
  },
  dividerSpace: {
    borderBottom: "1px solid #CBD5E1",
    display: "flex",
    flex: "auto",
    height: "0px",
  },
  checkbox: {
    width: "0px",
    height: "0px",
    borderRadius: "0px",
    color: "#64748B",
    marginRight: "8px",
    "& .MuiSvgIcon-root": {
      fontSize: "26px",
    },
    "&.Mui-checked": {
      color: "#1A7BA4",
    },
  },
  checkboxLabel: {
    "& .MuiTypography-root": {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
    },
  },
  estimatedReadingDialogText: {
    color: '#64748B',
    fontSize: '18px',
    fontFamily: "'Inter', sans-serif",
  }
};
