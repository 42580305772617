import React, {useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Tooltip,
  Autocomplete,
  TextField
} from '@mui/material';
import {errorIcon} from "../../../packages/blocks/loginpage/src/assets"
import supportedZipCodes from '../../blocks/cusotmers/src/supportedZipCodes';
import * as Yup from 'yup';
import { listOfStates, listOfCities, getStateByCity } from "./listOfCities";

export interface Props {
  handleCompanyChange: (changedDetails:any) => void;
  companyDetails:any;
  setIsEditing: (value: boolean) => void;
  isEditing: boolean;

  apiErrors?: { [key: string]: string } | null;
}

const tooltipStyles = {
  tooltip: {
    sx: {
      color: '#FFFFFF',
      fontFamily: "'Inter', sans-serif",
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: '400',
      padding: '8px 8px',
      backgroundColor: '#1E293B',
      borderRadius: '8px',
      width: 'fit-content',
      maxWidth: '480px',
      marginTop:'10px',
      '& .MuiTooltip-arrow': {
        color: '#1E293B',
      },
    }
  }
};


const styles = {
  container: {
    backgroundColor:"#F8FAFC",
    marginTop:"24px"
  },
  inputField: (isEditing: boolean) => ({
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    gap: "8px",
    width: '100%',
    marginBottom: '16px',
    padding: '10px 12px',
    backgroundColor: isEditing ? "#FFFFFF" : "#F1F5F9", // Change to white when editing
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
    outline: 'none',
    color: "#0F172A",
    fontWeight: "400",
    height: '56px',
  }),
  inputLabel: {
    fontWeight: "700",
    marginBottom:"4px",
    fontFamily: "'Inter', sans-serif",
    fontSize:"14px",
    color:"#334155"
  },
  buttonWrapper: {
    display: 'flex',
    gap: '16px',
  },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "10px 0 0",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 16px",
    textTransform: 'none',
    '&:hover': {
      background: "#FFFFFF",
    }
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "10px 0 0",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    textTransform: 'none',
    '&:hover': {
      background: "#1A7BA4",
    }
  },
  inputFieldError: {
    border: "1px solid #F87171", 
    color: "#0f172A",
  },
  errorImage:{
    position: 'absolute' as 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-70%)',
    height:"24px",
    width:"24px",
  },
  selectField: (isEditing: boolean) => ({
    fontFamily: "'Inter', sans-serif",
    height: '56px',
    fontSize: '16px',
    backgroundColor: isEditing ? "#FFFFFF" : "#F1F5F9", // Change to white when editing
    borderRadius: '8px',
    color: "#0F172A",
    width: '100%',
    '& .MuiFormControl-root .MuiInputBase-root ': {
      borderRadius: '8px',
      border: "1px solid #CBD5E1",
      height: '56px'
    },
    '& fieldset': {
      border: "none",
    },
    '& input:disabled': {
      color: '#0F172A',
      backgroundColor: '#F1F5F9',
      "-webkit-text-fill-color": "#0F172A",
    },
  }),
  selectOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: '#0F172A',
    padding: '8px 12px',
    margin: '0 8px 12px',
    borderRadius: '8px',
    '&:last-of-type': {
      margin: '0 8px',
    },
    '&.Mui-selected': {
      backgroundColor: '#E8FBFF',
      color: '#1A7BA4',
      fontWeight: '600',
    }
  },
};

export const CustomerDetails = ({handleCompanyChange, companyDetails, apiErrors, isEditing, setIsEditing}: Props) => {
  const [details, setDetails] = useState(companyDetails);   
  const [errors, setErrors] = useState<any>({});
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name);
    
    setDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));
    
  };

  const handleInputChangeSelect = (name: string, value: string) => {
    console.log(name, value); 
  
    setDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));

  };

  const trimSpaces = (value: string) => value.trim().replace(/\s+/g, ' ');
   
  const validateFields = async () => {
    const newErrors: any = {};
    const trimmedCompanyName = trimSpaces(details.companyName || '');
    const trimmedMailingAddress = trimSpaces(details.mailingAdress || '');
    const trimmedContactPerson = trimSpaces(details.contactPerson || '');
    const trimmedTitleOfContactPerson = trimSpaces(details.titleOfContactPerson || '');
    if (!trimmedCompanyName) newErrors.companyName = 'Company name is required';
    if (!trimmedMailingAddress) newErrors.mailingAdress = 'Mailing address is required';
    if (!details.city) newErrors.city = 'City is required';
    if (!details.state) newErrors.state = 'State is required';
    if (!details.zip) {
      newErrors.zip = 'ZIP code is required';
    } else {
      const matchingZip = supportedZipCodes.find((zip) => zip === details.zip.toString());
      if (!matchingZip) {
        newErrors.zip = 'Invalid ZIP code';
      }
    }
    if (errors.zip) newErrors.zip = 'Invalid zip code';
    if (!details.phone) {
      newErrors.phone = 'Phone number is required';
    } else {
      const phoneRegex = /^[2-9]{1}[0-9]{9}$/; 
      if (!phoneRegex.test(details.phone)) {
        newErrors.phone = 'Please enter a valid phone number';
      }
    }
    const faxRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    if (!details.fax) {
      newErrors.fax = 'Fax number is required';
    } else if (!faxRegex.test(details.fax)) {
      newErrors.fax = 'Please enter a valid fax number';
    }
    const emailSchema = Yup.string().email('Please enter a valid email address').required('Email is required');
    try {
      await emailSchema.validate(details.email);
    } catch (error) {
      newErrors.email = (error as any).message;
    }
    if (!trimmedContactPerson) newErrors.contactPerson = 'Contact person is required';
    if (!trimmedTitleOfContactPerson) newErrors.titleOfContactPerson = 'Title of contact person is required';
console.log(newErrors, details.fax);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; 
  };

  const handleSave = async () => {
    const isValid = await validateFields();
    console.log(isValid, details.fax);
    if (isValid) {
      handleCompanyChange({
        ...details,
        companyName: trimSpaces(details.companyName),
        mailingAdress: trimSpaces(details.mailingAdress),
        contactPerson: trimSpaces(details.contactPerson),
        titleOfContactPerson: trimSpaces(details.titleOfContactPerson),
      });
      console.log('handleCompany called');
    }
  };

  useEffect(()=>{
    setDetails(companyDetails)
  },[companyDetails])

  const handleEditClick = () => {
    setErrors({});
    setIsEditing(!isEditing);
  };

  const handleCustomInputChange = (name: string, value: any) => {
    setDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));
    
  };

  const handleZipCodeChange = (value: string) => {
    handleCustomInputChange('zip', value);
    
    const matchingZip = supportedZipCodes.find((zip) => zip.startsWith(value));
  
    if (!matchingZip) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        zip: 'Invalid ZIP code', 
      }));
    } else {
      setErrors((prevErrors: { [x: string]: any; zip: any; }) => {
        const { zip, ...rest } = prevErrors; 
        return rest;
      });
    }
  };

    return (
      <Box sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={styles.inputLabel}>Company name</Typography>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              value={details.companyName}
              name="companyName"
              style={{
                ...styles.inputField(isEditing),
                ...(errors.companyName ? styles.inputFieldError : {}),
                paddingRight: '35px',
              }}
              disabled={!isEditing}
              onChange={handleInputChange}
              data-test-id="companyNameInput"
            />
            {errors.companyName && (
              <Tooltip open={Boolean(errors.companyName)} title={errors.companyName} componentsProps={tooltipStyles} arrow>
                <img
                  src={errorIcon}
                  alt="error"
                  style={styles.errorImage}
                />
              </Tooltip>
            )}
          </div>
        </Grid>

        <Grid item xs={12}>
          <Typography sx={styles.inputLabel}>Mailing address</Typography>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              value={details.mailingAdress}
              name="mailingAdress"
              style={{
                ...styles.inputField(isEditing),
                ...(errors.mailingAdress ? styles.inputFieldError : {}),
                paddingRight: '35px',
              }}
              disabled={!isEditing}
              onChange={handleInputChange}
            />
            {errors.mailingAdress && (
              <Tooltip open={Boolean(errors.mailingAdress)} title={errors.mailingAdress} componentsProps={tooltipStyles} arrow>
                <img
                  src={errorIcon}
                  alt="error"
                  style={styles.errorImage}
                />
              </Tooltip>
            )}
          </div>        
          </Grid>

          <Grid item xs={12} sm={5}>
            <Typography sx={styles.inputLabel}>City</Typography>
            <div style={{ position: 'relative' }}>
              <Autocomplete
                disablePortal
                value={details.city}
                options={listOfCities(details.state)}
                sx={{
                  ...styles.selectField(isEditing),
                  ...(errors.city ? styles.inputFieldError : {}),
                }}
                onChange={(_, newValue) => {
                  const currentState = getStateByCity(newValue || "");
                  handleInputChangeSelect("city", newValue || "")
                  handleInputChangeSelect("state", currentState || "")
                }}
                data-test-id="city"
                disabled={!isEditing}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select one" />
                )}
              />
              {errors.city && (
                <Tooltip
                  open={Boolean(errors.city)}
                  title={errors.city}
                  componentsProps={tooltipStyles}
                  arrow
                >
                  <img src={errorIcon} alt="error" style={styles.errorImage} />
                </Tooltip>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Typography sx={styles.inputLabel}>State</Typography>
            <div style={{ position: 'relative' }}>
              <Autocomplete
                disablePortal
                value={details.state}
                options={listOfStates()}
                sx={{
                  ...styles.selectField(isEditing),
                  ...(errors.state ? styles.inputFieldError : {}),
                }}
                onChange={(_, newValue) => {
                  handleInputChangeSelect("state", newValue || "")
                  handleInputChangeSelect("city", "")
                }}
                data-test-id="state"
                disabled={!isEditing}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select one" />
                )}
              />
              {errors.state && (
                <Tooltip
                  open={Boolean(errors.state)}
                  title={errors.state}
                  componentsProps={tooltipStyles}
                  arrow
                >
                  <img src={errorIcon} alt="error" style={styles.errorImage} />
                </Tooltip>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={2}>
              <Typography sx={{ ...styles.inputLabel, whiteSpace: "nowrap" }}>ZIP code</Typography>
              <div style={{ position: 'relative' }}>
                <Autocomplete
                  freeSolo
                  options={supportedZipCodes}
                  value={details.zip}
                  onChange={(_, newValue) => handleCustomInputChange('zip', newValue)}
                  onInputChange={(_, newValue) => handleZipCodeChange(newValue)}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        type="text"
                        {...params.inputProps}
                        name="zip"
                        style={{
                          ...styles.inputField(isEditing),
                          ...(errors.zip ? styles.inputFieldError : {}),
                          paddingRight: '35px',
                          marginBottom:'0px'
                        }}
                        disabled={!isEditing}
                      />
                    </div>
                  )}
                  ListboxProps={{
                    style: { maxHeight: '160px', overflowY: 'auto' },
                  }}
                />
              {errors.zip && (
                <Tooltip open={Boolean(errors.zip)} title={errors.zip} componentsProps={tooltipStyles} arrow>
                  <img
                    src={errorIcon}
                    alt="error"
                    style={styles.errorImage}
                  />
                </Tooltip>
              )}
            </div>
          </Grid>

        <Grid item xs={12} sm={4}>
          <Typography sx={styles.inputLabel}>Phone number</Typography>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              value={details.phone}
              name="phone"
              style={{
                ...styles.inputField(isEditing),
                ...(errors.phone || apiErrors?.phone ? styles.inputFieldError : {}),
                paddingRight: '35px',
              }}
              disabled={!isEditing}
              onChange={handleInputChange}
            />
            {(errors.phone || apiErrors?.phone) && (
              <Tooltip open={Boolean(errors.phone || apiErrors?.phone)} title={errors.phone || apiErrors?.phone} componentsProps={tooltipStyles} arrow>
                <img
                  src={errorIcon}
                  alt="error"
                  style={styles.errorImage}
                />
              </Tooltip>
            )}
          </div>         
          </Grid>

        <Grid item xs={12} sm={4}>
          <Typography sx={styles.inputLabel}>Fax number</Typography>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              value={details.fax}
              name="fax"
              style={{
                ...styles.inputField(isEditing),
                ...(errors.fax ? styles.inputFieldError : {}),
                paddingRight: '35px',
              }}
              disabled={!isEditing}
              onChange={handleInputChange}
              data-test-id="faxInput"
            />
            {errors.fax && (
              <Tooltip open={Boolean(errors.fax)} title={errors.fax} componentsProps={tooltipStyles} arrow>
                <img
                  src={errorIcon}
                  alt="error"
                  style={styles.errorImage}
                  data-test-id="faxErrorIcon"
                />
              </Tooltip>
            )}
          </div>         
          </Grid>

        <Grid item xs={12} sm={4}>
          <Typography sx={styles.inputLabel}>Email</Typography>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              value={details.email}
              name="email"
              style={{
                ...styles.inputField(isEditing),
                ...(errors.email || apiErrors?.email ? styles.inputFieldError : {}),
                paddingRight: '35px',
              }}
              disabled={!isEditing}
              onChange={handleInputChange}
            />
            {(errors.email || apiErrors?.email) && (
              <Tooltip open={Boolean(errors.email || apiErrors?.email)} title={errors.email || apiErrors?.email} componentsProps={tooltipStyles} arrow>
                <img
                  src={errorIcon}
                  alt="error"
                  style={styles.errorImage}
                />
              </Tooltip>
            )}
          </div>         </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.inputLabel}>Contact person</Typography>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              value={details.contactPerson}
              name="contactPerson"
              style={{
                ...styles.inputField(isEditing),
                ...(errors.contactPerson ? styles.inputFieldError : {}),
                paddingRight: '35px',
              }}
              disabled={!isEditing}
              onChange={handleInputChange}
            />
            {errors.contactPerson && (
              <Tooltip open={Boolean(errors.contactPerson)} title={errors.contactPerson} componentsProps={tooltipStyles} arrow>
                <img
                  src={errorIcon}
                  alt="error"
                  style={styles.errorImage}
                />
              </Tooltip>
            )}
          </div>         </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.inputLabel}>Title of contact person</Typography>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              value={details.titleOfContactPerson}
              name="titleOfContactPerson"
              style={{
                ...styles.inputField(isEditing),
                ...(errors.titleOfContactPerson ? styles.inputFieldError : {}),
                paddingRight: '35px',
              }}
              disabled={!isEditing}
              onChange={handleInputChange}
            />
            {errors.titleOfContactPerson && (
              <Tooltip open={Boolean(errors.titleOfContactPerson)} title={errors.titleOfContactPerson} componentsProps={tooltipStyles} arrow>
                <img
                  src={errorIcon}
                  alt="error"
                  style={styles.errorImage}
                />
              </Tooltip>
            )}
          </div>         </Grid>
      </Grid>

      <Box 
        display="flex"    
        justifyContent="flex-end"
      >
      {isEditing ? (
        <Box sx={styles.buttonWrapper}>
          <Button 
            sx={styles.cancelButton}
            onClick={() =>{ handleEditClick();setDetails(companyDetails);}}
          >
            Cancel
          </Button>
          <Button 
            data-test-id="submitButton"
            sx={styles.submitButton}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Box>
        ) : (
          <Button 
            sx={styles.submitButton}
            onClick={() =>  handleEditClick()}
            data-test-id="editButton"
          >
            Edit
          </Button>
        )}
      </Box>
    </Box>
    );
  };