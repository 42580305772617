import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Reading {
  company_name: string;
  customer: string;
  phone_number: string;
  building_manager: string;
  office_number: string;
  super_number: string;
}

interface Tenant {
  id: number;
  tenant_name: string;
  start_date: string;
  end_date: string;
  property_id: number;
}

interface PremiseMeter {
  id: number;
  meter_number: string;
  meter_type: string;
  meter_note: string;
  water_sub_type: string;
  meter_readed: boolean;
  volume: string;
  metric: string;
  last_reading: string;
  date_of_last_reading: string;
  property_id: number;
  tenant_id: number;
}

interface Property {
  id: number;
  property_name: string;
  service_address: string;
  meter_location: string;
  building_manager: string;
  phone_number: string;
  email: string;
  of_tenants: number;
  of_meters: number;
  of_meaters_to_read: number;
  reading_period: string;
  start_reading_from: string;
  account_id: string;
  company_detail_id: string;
}

interface S {
  token: string;
  noteError: boolean;
  filteredItemsCount: number,
  validate: boolean;
  readingLoading: boolean;
  fileError: string;
  step: number;
  currentPage:number;
  itemsPerPage:number;
  totalPages:number;
  currentReadings: PremiseMeter[];
  firstItemIndex:number,
  lastItemIdex:number,
  submitedMeter: number[];
  companyDetailId: string;
  selectedReading: Reading | null;
  selectedProperty: number | null;
  properies: Property[];
  premiseMeterList: PremiseMeter[];
  tenants: Tenant[];
  currentMeter: number | null;
  modalWindowId: number | null;
  formData?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddReadingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetPropertiesListCallId: string = "";
  apiGetReadingCustomerDataCallId: string = "";
  apiGetPreemiseMeterDataCallId: string = "";
  apiAddMeterReadingDataCallId: string = "";
  apiGetTenantDataCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      noteError: false,
      filteredItemsCount: 0,
      validate: false,
      readingLoading: false,
      fileError: "",
      companyDetailId: "",
      step: 1,
      currentPage:1,
      itemsPerPage:10,
      totalPages: 1,
      currentReadings: [],
      firstItemIndex:1,
      lastItemIdex:10,
      submitedMeter: [],
      selectedReading: null,
      selectedProperty: null,
      premiseMeterList: [],
      tenants: [],
      modalWindowId: null,
      currentMeter: null,
      formData: {},
      properies: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetPropertiesListCallId) {
          if (responseJson && responseJson.data) {
            this.setState({
              properies: responseJson.data.map(
                ({ attributes }: any) => attributes as Property
              ),
            });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiGetReadingCustomerDataCallId) {
          if (responseJson && responseJson.data) {
            this.setState({ selectedReading: responseJson.data as Reading });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiGetPreemiseMeterDataCallId) {
          if (responseJson && responseJson.data) {
            this.setState({
              premiseMeterList: responseJson.data.map(
                ({ attributes }: any) => attributes as PremiseMeter
              ),
            });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiGetTenantDataCallId) {
          if (responseJson && responseJson.data) {
            this.setState({
              tenants: responseJson.data.map(
                ({ attributes }: any) => attributes as Tenant
              ),
            });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiAddMeterReadingDataCallId) {
          this.setLoading(false);
          if (responseJson && responseJson.data) {
            this.getPremoseMeterData(this.state.selectedProperty as number);
            this.setStep(2);
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  setStep = (step: number) => {
    this.setState({ step });
  };

  setNoteeError = (noteError: boolean) => {
    this.setState({ noteError });
  };

  setFileError = (fileError: string) => {
    this.setState({ fileError });
  };

  // getPropertiesListForReading
  setSelectedReading = (selectedProperty: number) => {
    this.setState({ selectedProperty });
    this.getReadingCustomerData(selectedProperty);
    this.getPremoseMeterData(selectedProperty);
    const companyDetailId = this.state.properies.find(
      (prop) => prop.id === selectedProperty
    )?.company_detail_id;
    this.setState({ companyDetailId: companyDetailId as string });
    console.log({ companyDetailId });
    this.getTenantData(selectedProperty, companyDetailId as string);
  };

  setSelectedProperties = (selectedBooking: number) => {
    this.setState({ properies: [] });
    this.getPropertiesListForReading(selectedBooking);
    this.setState({ selectedProperty: null, selectedReading: null, properies:[] });
  };

  setCurrentMeter = (currentMeter: number) => {
    this.setState({ currentMeter });
  };

  setModalWindowId = (modalWindowId: number | null) => {
    this.setState({ modalWindowId });
  };

  setValidate = (validate: boolean) => {
    this.setState({ validate });
  };

  setLoading = (readingLoading: boolean) => {
    this.setState({ readingLoading });
  };

  setFormDate = (valuesData: any) => {
    const { property_id = 0 } =
      this.state.premiseMeterList.find(
        (pm) => pm.id === this.state.currentMeter
      ) || {};

    const currentProperty = this.state.properies.find(
      (prop) => prop.id === property_id
    );

    console.log({
      companyDetailId: this.state.companyDetailId,
      currentProperty,
    });
    const formData = new FormData();
    valuesData.currReading 
      && formData.append(
        "meter_reading[current_reading]",
        valuesData.currReading as string
      );
    valuesData.note 
      && formData.append(
        "meter_reading[meter_reading_note]",
        valuesData.note as string
      );

    valuesData.files?.map((mri: File) => {
      formData.append("meter_reading[meter_reading_images][]", mri, mri.name);
    });
    formData.append("company_detail_id", `${this.state.companyDetailId}`);

    this.setState({ formData });
  };

  goToLogInPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LogInPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  getPropertiesListForReading = async (bookingId: number) => {
    const header = {
      ContentType: configJSON.getPropertiesApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPropertiesListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPropertiesApiEndPoint + `?book_number=${bookingId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPropertiesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getReadingCustomerData = async (propertyId: number) => {
    const header = {
      ContentType: configJSON.getReadingCustomerDataApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetReadingCustomerDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReadingCustomerDataApiEndPoint +
        `?property_id=${propertyId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReadingCustomerDataApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPremoseMeterData = async (propertyId: number) => {
    const header = {
      ContentType: configJSON.getPremiseMeterDataApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPreemiseMeterDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPremiseMeterDataApiEndPoint + `?property_id=${propertyId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPremiseMeterDataApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTenantData = async (propertyId: number, companyDetailId: string) => {
    const header = {
      ContentType: configJSON.getTenantDataApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTenantDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTenantDataApiEndPoint +
        `/${propertyId}/tenants?company_detail_id=${companyDetailId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTenantDataApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addMeterReadingData = async (
    propertyId: number,
    tenantId: number,
    meterId: number
  ) => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddMeterReadingDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addMeterReadingApiEndPoint +
        `/${this.state.selectedProperty}/tenants/${tenantId}/meters/${meterId}/meter_readings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      this.state.formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addMeterReadingApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleNextPage = () => {
    if (this.state.currentPage < Math.ceil(this.state.premiseMeterList.length / this.state.itemsPerPage)) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  };

  handlePrevPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
  };

  updatePagination = () => {
    const { currentPage, itemsPerPage, premiseMeterList } = this.state;
    const filteredPropertiesList = premiseMeterList;
    const filteredItemsCount = filteredPropertiesList.length;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentReadings = filteredPropertiesList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredPropertiesList.length / itemsPerPage) || 1;

    if (currentReadings.length === 0) {
      this.handlePageChange(totalPages);
    }

    if (indexOfLastItem > filteredPropertiesList.length ) {
        indexOfLastItem = filteredPropertiesList.length;
    }

    this.setState({
      currentReadings,
      filteredItemsCount,
      totalPages,
      firstItemIndex:indexOfFirstItem,
      lastItemIdex:indexOfLastItem,
    });
  };

  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  componentDidMount = async () => {
    const userRole = await getStorageData("role");

    if (userRole !== "field_representative") {
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LogInPage"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }

    await this.getToken();
    await Promise.all([this.updatePagination()]);
  };

  async componentDidUpdate(prevProps: Props, prevState: S): Promise < void> {
    if (    
      prevState.currentPage !== this.state.currentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.premiseMeterList !== this.state.premiseMeterList
      // prevState.search !== this.state.search
    ) {
      await Promise.all([ this.updatePagination()]);
    }
  }

  // Customizable Area End
}
