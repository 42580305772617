import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

interface CustomerPdfViewerProps {
  pdfUrl?: string | null;
  onClose: () => void;
}

export const CustomerPdfViewer = ({
  pdfUrl,
  onClose,
}: CustomerPdfViewerProps) => {
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const pdfDocRef = useRef<any>(null);
  const [downloadTooltipOpen, setDownloadTooltipOpen] = useState(false);

  useEffect(() => {
    if (!pdfUrl) {
      return;
    }
    // Initialize PDF viewer
    const initPDFViewer = async () => {
      try {
        setLoading(true);

        // Load PDF.js from CDN
        // @ts-ignore
        const pdfjsLib = window["pdfjs-dist/build/pdf"];
        if (!pdfjsLib) {
          throw new Error("PDF.js library not loaded");
        }

        // Load the PDF document
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        pdfDocRef.current = pdf;
        setNumPages(pdf.numPages);

        // Render the first page
        await renderPage(1, pdf);

        setLoading(false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    // Add PDF.js script to the page
    const script = document.createElement("script");
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.4.456/pdf.min.js";
    script.onload = initPDFViewer;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [pdfUrl]);

  const renderPage = async (pageNum: number, pdfDoc = pdfDocRef.current) => {
    if (!pdfDoc) {
      return;
    }

    try {
      const page = await pdfDoc.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1.25 });

      const container = document.getElementById("pdf-viewer");
      if (!container) {
        return;
      }

      // Prepare canvas
      let canvas = container.querySelector("canvas");
      if (!canvas) {
        canvas = document.createElement("canvas");
        container.innerHTML = "";
        container.appendChild(canvas);
      }

      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({
        canvasContext: context,
        viewport: viewport,
      }).promise;
    } catch (err) {
      console.error(err);
    }
  };

  const changePage = async (newPageNumber: number) => {
    if (newPageNumber >= 1 && newPageNumber <= numPages) {
      setLoading(true);
      setPageNumber(newPageNumber);
      await renderPage(newPageNumber);
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    if (!pdfUrl) {
      return;
    }
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = pdfUrl.split("/").pop() || "document.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setDownloadTooltipOpen(true);
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => setDownloadTooltipOpen(false), 3500);
    }
  };

  return (
    <Dialog open={!!pdfUrl} onClose={onClose} maxWidth={false}>
      {loading && (
        <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
          <CircularProgress sx={webStyle.loader} />
        </Box>
      )}
      {!loading && (
        <Box sx={webStyle.dialogControlsWrapper}>
          <Box sx={webStyle.paginationWrapper} data-test-id="paginationWrapper">
            <IconButton
              onClick={() => changePage(pageNumber - 1)}
              disabled={pageNumber <= 1 || loading}
            >
              <ChevronLeftRoundedIcon sx={webStyle.dialogControlsIcon} />
            </IconButton>
            <Box>
              Page {pageNumber} of {numPages}
            </Box>
            <IconButton
              onClick={() => changePage(pageNumber + 1)}
              disabled={pageNumber >= numPages || loading}
            >
              <ChevronRightRoundedIcon sx={webStyle.dialogControlsIcon} />
            </IconButton>
          </Box>
          <Tooltip
            open={downloadTooltipOpen}
            title="Download started! Your PDF will be ready shortly."
            componentsProps={tooltipStyles}
            placement="top"
            arrow
          >
            <IconButton sx={webStyle.dialogButton} onClick={handleDownload}>
              <DownloadRoundedIcon sx={webStyle.dialogControlsIcon} />
            </IconButton>
          </Tooltip>
        </Box>
      )}

      <div id="pdf-viewer" />
    </Dialog>
  );
};

const tooltipStyles = {
  tooltip: {
    sx: {
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: "400",
      padding: "8px 12px",
      backgroundColor: "#334155",
      borderRadius: "8px",
      width: "fit-content",
      maxWidth: "480px",
      "& .MuiTooltip-arrow": {
        color: "#334155",
      },
    },
  },
};

const webStyle = {
  loaderWrapper: {
    height: "calc(100vh - 64px)",
    width: "calc(100vh - 64px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "#1A7BA4",
    height: "60px",
    width: "60px",
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    fontFamily: '"Inter", sans-serif',
  },
  dialogControlsWrapper: {
    display: "flex",
    justifyContent: "center",
    minHeight: "45px",
    backgroundColor: "#333639",
  },
  dialogControlsIcon: {
    color: "#FFFFFF",
  },
  dialogButton: {
    position: "absolute",
    right: 8,
    top: 2,
  },
};
