import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/AddOutlined";
import CloseRoundedIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import {
  searchIcon,
  noPropertieisIcon,
  deleteIcon,
  editIcon,
  mailIcon,
  errorIcon,
} from "./assets";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Formik } from "formik";
import * as Yup from "yup";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { createPortal } from "react-dom";
// Customizable Area End

import fieldRepresentativesPageController, {
  PaymentStatus,
  Props,
  Representative,
  configJSON,
} from "./fieldRepresentativesPageController";
import moment from "moment";
// Customizable Area Start

// Customizable Area End
export default class fieldRepresentativesPage extends fieldRepresentativesPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  questionSchema = () => {
    return Yup.object().shape({
      first_name: Yup.string()
        .transform((value: string) => this.trimSpaces(value))
        .required("First name is required"),
      last_name: Yup.string()
        .transform((value: string) => this.trimSpaces(value))
        .required("Last name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      password: Yup.string()
        .matches(/^\S*$/, "Password cannot contain spaces")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[\w!@#$%^&*(),.?":{}|<>]{8,72}$/,
          "Password must include at least one uppercase letter, one lowercase letter, one number, one special character, be at least 8 characters long, and have equal or less than 72 characters"
        )
        .when("id", {
          is: (id) => !id, // password should not be required for editing mode
          then: Yup.string().required("Password is required"),
          otherwise: Yup.string(),
        }),
      confirm_password: Yup.string()
        .when("password", {
          is: (password) => !!password,
          then: Yup.string().required("Confirm password is required"),
          otherwise: Yup.string(),
        })
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.representativesContainer}>
        {this.state.fieldRepresentativesLoading ? (
          <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
            <CircularProgress sx={webStyle.loader} />
          </Box>
        ) : this.state.representativeList.length === 0 ? (
          <Box sx={webStyle.noRepresentativesBlock} data-test-id="emptyBlock">
            <Box sx={webStyle.noRepresentativesBlockTitle}>
              <img src={noPropertieisIcon} style={webStyle.mainImage} />
              <Typography style={webStyle.noRepresentativesTitle}>
                No field representatives
              </Typography>
              <Typography style={webStyle.noRepresentativesSubTitle}>
                Click on the button below to start adding your field
                representatives
              </Typography>
              <Box sx={webStyle.noRepresentativesAddButtonBlock}>
                <button
                  style={webStyle.addNewButton}
                  data-test-id="addButton"
                  type="button"
                  onClick={() =>
                    this.setState({ addEditDialogProps: { isAdd: true } })
                  }
                >
                  <Typography sx={webStyle.noRepresentativesButtonText}>
                    Add FR
                  </Typography>
                </button>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={webStyle.representativesTableHeader}>
              <Typography sx={webStyle.representativeTitle}>
                {configJSON.headingText}
              </Typography>
              <Box sx={webStyle.searchContainer}>
                <Box sx={webStyle.fieldControl}>
                  <input
                    style={webStyle.inputElemnt}
                    data-test-id="search"
                    type={"text"}
                    placeholder={configJSON.searchPlaceholderText}
                    onChange={(event) => {
                      this.searchRepresentatives(event.target.value);
                    }}
                  />
                  <img src={searchIcon} alt="" style={webStyle.searchIcon} />
                </Box>
                <Box>
                  <Button
                    style={{
                      width: "120px",
                      height: "44px",
                      padding: "10px 16px",
                      gap: "8px",
                      borderRadius: "8px",
                      backgroundColor: "#1A7BA4",
                      fontFamily: "'Inter', sans-serif",
                      color: "white",
                      fontWeight: "700",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() =>
                      this.setState({ addEditDialogProps: { isAdd: true } })
                    }
                  >
                    <AddIcon style={{ fontWeight: "700" }} />
                    <Typography
                      style={{
                        fontWeight: "700",
                        whiteSpace: "nowrap",
                        textTransform: "none",
                        fontFamily: "'Inter', sans-serif",
                      }}
                    >
                      Add FR
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Typography sx={webStyle.representativeCount}>
                {this.state.filteredRepresentativeList.length + " results"}
              </Typography>
            </Box>
            {this.state.currentRepresentativeList.length > 0 && (
            <Box
              sx={this.state.totalPages > 1 ? { minHeight: "714px" } : undefined}
            >
              <Box sx={webStyle.representativesTable}>
                <Box sx={webStyle.representativesTableHead}>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.firstNameText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.lastNameText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.lastLogInText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.statusText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.typeText}
                  </Typography>
                  <Typography style={webStyle.headerTitleAction}>
                    {configJSON.actionsText}
                  </Typography>
                </Box>
                {this.state.currentRepresentativeList.map((representative) => (
                  <Box
                    sx={webStyle.representativesTableBody}
                    key={representative.id}
                    data-test-id="table-row"
                  >
                    <Box sx={webStyle.representativeItem}>
                      {representative.first_name}
                    </Box>
                    <Box sx={webStyle.representativeItem}>
                      {representative.last_name}
                    </Box>
                    <Box sx={webStyle.representativeItem}>
                      {representative.last_login
                        ? moment(representative.last_login).format("M/D/YYYY")
                        : ""}
                    </Box>
                    <Box sx={webStyle.representativeItem}>
                      {representative.status === "ACTIVE" ? (
                        <Typography sx={webStyle.paidStyle}>ACTIVE</Typography>
                      ) : (
                        <Typography sx={webStyle.pendingStyle}>
                          INVITE SENT
                        </Typography>
                      )}
                    </Box>
                    <Box sx={webStyle.representativeItem}>
                      <IOSSwitch
                        data-test-id="enableSwitch"
                        sx={{ m: 1 }}
                        checked={representative.enabled}
                        onChange={() =>
                          this.changeStatusOfFieldRepresentative(
                            representative.id || '',
                            !representative.enabled,
                          )
                        }
                        disabled={this.state.changeStatusLoading}
                      />
                    </Box>
                    <Box sx={webStyle.representativeItemAction}>
                      <button
                        style={{
                          ...webStyle.downloadButton,
                          width: "150px",
                        }}
                        onClick={() => {
                          this.resendInvite(representative.id || "");
                        }}
                        data-test-id="resendInviteButton"
                        disabled={this.state.resendInviteLoading}
                      >
                        <img src={mailIcon} alt="" style={webStyle.btnIcon} />
                        <Typography style={webStyle.typographyText}>
                          Resend invite
                        </Typography>
                      </button>

                      <button
                        style={{
                          ...webStyle.downloadButton,
                          backgroundColor: "#fff",
                          color: "#1A7BA4",
                          border: "1px solid #1A7BA4",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          height: "36px",
                          width: "76px",
                        }}
                        onClick={() => {
                          this.editRepresentative(representative);
                        }}
                        data-test-id="editButton"
                      >
                        <img src={editIcon} alt="" style={webStyle.btnIcon} />
                        <Typography style={webStyle.typographyText}>
                          Edit
                        </Typography>
                      </button>

                      <button
                        style={{
                          ...webStyle.downloadButton,
                          backgroundColor: "#fff",
                          color: "#dc3545",
                          border: "1px solid #dc2626",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          height: "36px",
                          width: "90px",
                        }}
                        onClick={() => {
                          this.setState({
                            deleteRepresentativeDialogId:
                              representative.id || null,
                          });
                        }}
                        data-test-id="deleteButton"
                      >
                        <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                        <Typography style={webStyle.typographyText}>
                          Delete
                        </Typography>
                      </button>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            )}
            {this.state.totalPages > 1 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Previous Button */}
                  <button
                    onClick={() => this.handlePrevPage()}
                    data-test-id="previous-button"
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                      marginRight: "4px",
                    }}
                    disabled={this.state.currentPage === 1}
                  >
                    <KeyboardArrowLeftRoundedIcon
                      sx={
                        this.state.currentPage === 1
                          ? webStyle.chevronIconDisabled
                          : webStyle.chevronIconActive
                      }
                    />
                  </button>

                  {this.state.totalPages <= 3 ? (
                    [...Array(this.state.totalPages).keys()].map((page) => (
                      <button
                        key={page + 1}
                        data-test-id={"page-button"}
                        style={{
                          marginRight: "4px",
                          width:
                            this.state.currentPage === page + 1
                              ? "24px"
                              : "16px",
                          height: "24px",
                          backgroundColor:
                            this.state.currentPage === page + 1
                              ? "#DBF5FF"
                              : "inherit",
                          color:
                            this.state.currentPage === page + 1
                              ? "#1A7BA4"
                              : "#64748B",
                          border: "none",
                          textDecoration:
                            this.state.currentPage !== page + 1
                              ? "underline"
                              : "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                          fontWeight:
                            this.state.currentPage !== page + 1 ? 400 : 700,
                        }}
                        onClick={() => this.handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </button>
                    ))
                  ) : (
                    <>
                      {this.state.currentPage !== 1 && (
                        <button
                          data-test-id="ellipsis"
                          style={{
                            marginRight: "4px",
                            width: "16px",
                            height: "24px",
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() => this.handlePageChange(1)}
                        >
                          1
                        </button>
                      )}

                      {this.state.currentPage > 3 && (
                        <span style={{ marginRight: "4px", color: "#64748B" }}>
                          ...
                        </span>
                      )}

                      {this.state.currentPage > 2 && (
                        <button
                          data-test-id="middle-page"
                          style={{
                            marginRight: "4px",
                            width: "24px",
                            height: "24px",
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage - 1)
                          }
                        >
                          {this.state.currentPage - 1}
                        </button>
                      )}

                      <button
                        data-test-id="middle-page2"
                        style={{
                          marginRight: "4px",
                          width: "24px",
                          height: "24px",
                          backgroundColor: "#DBF5FF",
                          color: "#1A7BA4",
                          border: "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontWeight: 700,
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage)
                        }
                      >
                        {this.state.currentPage}
                      </button>

                      {this.state.currentPage < this.state.totalPages - 1 && (
                        <button
                          data-test-id="middle-page3"
                          style={{
                            marginRight: "4px",
                            width: "16px",
                            height: "24px",
                            color: "#64748B",
                            backgroundColor: "inherit",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage + 1)
                          }
                        >
                          {this.state.currentPage + 1}
                        </button>
                      )}

                      {this.state.currentPage < this.state.totalPages - 2 && (
                        <span style={{ marginRight: "4px", color: "#64748B" }}>
                          ...
                        </span>
                      )}

                      {this.state.currentPage !== this.state.totalPages && (
                        <button
                          data-test-id="last-page"
                          style={{
                            marginRight: "4px",
                            width:
                              this.state.currentPage === this.state.totalPages
                                ? "24px"
                                : "16px",
                            height: "24px",
                            backgroundColor:
                              this.state.currentPage === this.state.totalPages
                                ? "#DBF5FF"
                                : "inherit",
                            color:
                              this.state.currentPage === this.state.totalPages
                                ? "#1A7BA4"
                                : "#64748B",
                            border: "none",
                            textDecoration:
                              this.state.currentPage !== this.state.totalPages
                                ? "underline"
                                : "none",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                            fontWeight:
                              this.state.currentPage !== this.state.totalPages
                                ? 400
                                : 700,
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.totalPages)
                          }
                        >
                          {this.state.totalPages}
                        </button>
                      )}
                    </>
                  )}

                  <button
                    data-test-id="next-page"
                    onClick={() => this.handleNextPage()}
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    disabled={this.state.currentPage === this.state.totalPages}
                  >
                    <KeyboardArrowRightRoundedIcon
                      sx={
                        this.state.currentPage === this.state.totalPages
                          ? webStyle.chevronIconDisabled
                          : webStyle.chevronIconActive
                      }
                    />
                  </button>
                </Box>

                <Box sx={{ marginRight: "10px", width: "120px" }}>
                  <Typography
                    fontFamily={"'Inter', sans-serif"}
                    fontSize={"12px"}
                    color={"grey"}
                  >
                    {this.state.firstItemIndex + 1}-{this.state.lastItemIdex} of{" "}
                    {this.state.filteredRepresentativeList.length} results
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}
        {/* //dialogue for delete button */}
        <Dialog
          open={!!this.state.deleteRepresentativeDialogId}
          onClose={this.handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: "633px",
              padding: "40px 32px 32px 32px",
              borderRadius: "16px",
              fontFamily: "'Inter', sans-serif",
            },
          }}
          data-test-id="deleteModal"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              fontWeight: "600",
              padding: "0 0 24px",
              fontFamily: "'Inter', sans-serif",
              fontSize: "24px",
              letterSpacing: "-1px",
            }}
          >
            Are you sure you want to delete this account?
          </DialogTitle>
          <DialogContent style={{ marginBottom: "32px", padding: 0 }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                color: "#64748B",
                fontFamily: "'Inter', sans-serif",
                fontSize: "18px",
              }}
            >
              This action can’t be undone and all the data stored in this
              account won’t be accessible anymore.
            </DialogContentText>
            <Divider style={{ marginTop: "32px" }} />
          </DialogContent>
          <DialogActions style={{ padding: 0 }}>
            <Button
              onClick={this.handleCloseDeleteDialog}
              variant="outlined"
              sx={webStyle.cancelButton}
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                this.deleteFieldRepresentative(
                  this.state.deleteRepresentativeDialogId || ""
                )
              }
              sx={webStyle.confirmDelete}
              startIcon={<DeleteIcon />}
              data-test-id="deleteModalButton"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* dialog for add FR */}

        <Dialog
          open={!!this.state.addEditDialogProps}
          onClose={this.handleCloseAddFR}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "8px",
              padding: "32px",
            },
          }}
          data-test-id="addModal"
        >
          <DialogTitle style={{ padding: "0 0 16px", position: "relative" }}>
            <Typography variant="h6" sx={webStyle.dialogTitleText}>
              {`${
                this.state.addEditDialogProps?.isAdd ? "Add" : "Edit"
              } Field Representative`}
            </Typography>
            <IconButton
              onClick={this.handleCloseAddFR}
              style={{
                position: "absolute",
                right: "0",
                top: "-8px",
                width: "56px",
                height: "56px",
                backgroundColor: "#F8FAFC",
                borderRadius: "52px",
              }}
            >
              <CloseRoundedIcon
                sx={{ width: "24px", height: "24px", color: "#64748B" }}
              />
            </IconButton>
          </DialogTitle>
          <Divider style={{ margin: "16px 0" }} />
          <DialogContent style={{ padding: 0 }}>
            <Formik
              initialValues={{
                id:
                  this.state.addEditDialogProps?.initialRepresentative?.id ||
                  undefined,
                first_name:
                  this.state.addEditDialogProps?.initialRepresentative
                    ?.first_name || "",
                last_name:
                  this.state.addEditDialogProps?.initialRepresentative
                    ?.last_name || "",
                email:
                  this.state.addEditDialogProps?.initialRepresentative?.email ||
                  "",
                password: "",
                confirm_password: "",
                enabled:
                  this.state.addEditDialogProps?.initialRepresentative
                    ?.enabled ?? true,
                status:
                  this.state.addEditDialogProps?.initialRepresentative
                    ?.status || "INVITE_SENT",
              }}
              validateOnChange={true}
              validationSchema={this.questionSchema}
              onSubmit={(values: Representative) => {
                this.setState({ validate: false });
                this.addEditRepresentative({
                  ...values,
                  first_name: this.trimSpaces(values?.first_name || ""),
                  last_name: this.trimSpaces(values?.last_name || ""),
                });
              }}
              data-test-id="addTenantForm"
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <form style={{ margin: 0 }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Typography variant="body2" sx={webStyle.inputLable}>
                        First name
                      </Typography>
                      <StyledTextField
                        fullWidth
                        data-test-id="firstName"
                        variant="outlined"
                        autoComplete="off"
                        placeholder="John"
                        value={values?.first_name}
                        onChange={(event) =>
                          setFieldValue("first_name", event.target.value)
                        }
                        error={this.state.validate && !!errors.first_name}
                        InputProps={{
                          endAdornment:
                            this.state.validate && !!errors.first_name ? (
                              <InputAdornment position="end">
                                <img
                                  src={errorIcon}
                                  alt=""
                                  style={webStyle.errorIcon}
                                />
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                      {this.state.validate && !!errors.first_name && (
                        <Typography variant="caption" sx={webStyle.filedError}>
                          {errors.first_name}
                        </Typography>
                      )}
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography variant="body2" sx={webStyle.inputLable}>
                        Last name
                      </Typography>
                      <StyledTextField
                        fullWidth
                        data-test-id="lastName"
                        variant="outlined"
                        autoComplete="off"
                        placeholder="Doe"
                        value={values?.last_name}
                        onChange={(event) =>
                          setFieldValue("last_name", event.target.value)
                        }
                        error={this.state.validate && !!errors.last_name}
                        InputProps={{
                          endAdornment:
                            this.state.validate && !!errors.last_name ? (
                              <InputAdornment position="end">
                                <img
                                  src={errorIcon}
                                  alt=""
                                  style={webStyle.errorIcon}
                                />
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                      {this.state.validate && !!errors.last_name && (
                        <Typography variant="caption" sx={webStyle.filedError}>
                          {errors.last_name}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <Typography variant="body2" sx={webStyle.inputLable}>
                      Email
                    </Typography>
                    <StyledTextField
                      fullWidth
                      data-test-id="email"
                      type="email"
                      placeholder="email@example.com"
                      value={values?.email}
                      onChange={(event) =>
                        setFieldValue("email", event.target.value)
                      }
                      error={this.state.validate && !!errors.email}
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        endAdornment:
                          this.state.validate && !!errors.email ? (
                            <InputAdornment position="end">
                              <img
                                src={errorIcon}
                                alt=""
                                style={webStyle.errorIcon}
                              />
                            </InputAdornment>
                          ) : null,
                      }}
                    />
                    {this.state.validate && !!errors.email && (
                      <Typography variant="caption" sx={webStyle.filedError}>
                        {errors.email}
                      </Typography>
                    )}
                  </div>
                  <div style={{ position: "relative", marginBottom: "16px" }}>
                    <Typography variant="body2" sx={webStyle.inputLable}>
                      Password
                    </Typography>
                    <StyledTextField
                      fullWidth
                      data-test-id="password"
                      type={this.state.showPassword ? "text" : "password"}
                      variant="outlined"
                      autoComplete="new-password"
                      placeholder="••••••••••"
                      value={values?.password}
                      onChange={(event) => {
                        setFieldValue("password", event.target.value);
                        this.handlePasswordChange(event);
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            <IconButton onClick={this.toggleShowPassword}>
                              {this.state.showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                            {this.state.validate && !!errors.password && (
                              <InputAdornment position="end">
                                <img
                                  src={errorIcon}
                                  alt=""
                                  style={webStyle.errorIcon}
                                />
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                      error={this.state.validate && !!errors.password}
                    />
                    {this.state.validate && !!errors.password && (
                      <Typography variant="caption" sx={webStyle.filedError}>
                        {errors.password}
                      </Typography>
                    )}
                    <div style={{ marginTop: "8px", fontSize: "13px" }}>
                      <Typography style={webStyle.passwordalidation}>
                        <span
                          style={{
                            color: this.state.passwordValidations.hasCapital
                              ? "#34D399"
                              : "#DC2626",
                            fontSize: "12px",
                          }}
                        >
                          {this.state.passwordValidations.hasCapital ? (
                            <CheckCircleIcon style={{ fontSize: "12px" }} />
                          ) : (
                            <RemoveCircleIcon style={{ fontSize: "12px" }} />
                          )}
                        </span>{" "}
                        At least one capital letter
                      </Typography>
                      <Typography style={webStyle.passwordalidation}>
                        <span
                          style={{
                            color: this.state.passwordValidations.hasLowercase
                              ? "#34D399"
                              : "#DC2626",
                            fontSize: "12px",
                          }}
                        >
                          {this.state.passwordValidations.hasLowercase ? (
                            <CheckCircleIcon style={{ fontSize: "12px" }} />
                          ) : (
                            <RemoveCircleIcon style={{ fontSize: "12px" }} />
                          )}
                        </span>{" "}
                        At least one lowercase letter
                      </Typography>
                      <Typography style={webStyle.passwordalidation}>
                        <span
                          style={{
                            color: this.state.passwordValidations.hasNumber
                              ? "#34D399"
                              : "#DC2626",
                            fontSize: "12px",
                          }}
                        >
                          {this.state.passwordValidations.hasNumber ? (
                            <CheckCircleIcon style={{ fontSize: "12px" }} />
                          ) : (
                            <RemoveCircleIcon style={{ fontSize: "12px" }} />
                          )}
                        </span>{" "}
                        At least one number
                      </Typography>
                      <Typography style={webStyle.passwordalidation}>
                        <span
                          style={{
                            color: this.state.passwordValidations.hasSpecialSymbol
                              ? "#34D399"
                              : "#DC2626",
                            fontSize: "12px",
                          }}
                        >
                          {this.state.passwordValidations.hasSpecialSymbol ? (
                            <CheckCircleIcon style={{ fontSize: "12px" }} />
                          ) : (
                            <RemoveCircleIcon style={{ fontSize: "12px" }} />
                          )}
                        </span>{" "}
                        At least one special character
                      </Typography>
                      <Typography style={webStyle.passwordalidation}>
                        <span
                          style={{
                            color: this.state.passwordValidations.minLength
                              ? "#34D399"
                              : "#DC2626",
                            fontSize: "12px",
                          }}
                        >
                          {this.state.passwordValidations.minLength ? (
                            <CheckCircleIcon style={{ fontSize: "12px" }} />
                          ) : (
                            <RemoveCircleIcon style={{ fontSize: "12px" }} />
                          )}
                        </span>{" "}
                        Minimum character length is 8 characters
                      </Typography>
                    </div>
                  </div>
                  <div style={{ position: "relative", marginBottom: "24px" }}>
                    <Typography variant="body2" sx={webStyle.inputLable}>
                      Confirm password
                    </Typography>
                    <StyledTextField
                      fullWidth
                      data-test-id="confirmPassword"
                      type={
                        this.state.showConfirmPassword ? "text" : "password"
                      }
                      variant="outlined"
                      value={values?.confirm_password}
                      onChange={(event) =>
                        setFieldValue("confirm_password", event.target.value)
                      }
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: (
                          <>
                            <IconButton
                              onClick={this.toggleShowConfirmPassword}
                            >
                              {this.state.showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                            {this.state.validate &&
                              !!errors.confirm_password && (
                                <InputAdornment position="end">
                                  <img
                                    src={errorIcon}
                                    alt=""
                                    style={webStyle.errorIcon}
                                  />
                                </InputAdornment>
                              )}
                          </>
                        ),
                      }}
                      error={this.state.validate && !!errors.confirm_password}
                      placeholder="••••••••••"
                    />
                    {this.state.validate && !!errors.confirm_password && (
                      <Typography variant="caption" sx={webStyle.filedError}>
                        {errors.confirm_password}
                      </Typography>
                    )}
                  </div>
                  <Divider style={{ margin: "16px 0 32px" }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "16px",
                    }}
                  >
                    <button
                      onClick={this.handleCloseAddFR}
                      style={webStyle.cancelButtonForm}
                      data-test-id="closeAddEditModal"
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        this.setState({ validate: true });
                        console.log(errors);
                        if (Object.keys(errors).length === 0) {
                          handleSubmit();
                        }
                      }}
                      style={webStyle.submitButtonForm}
                      data-test-id="submitAddEdit"
                      type="button"
                    >
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
        {createPortal(
          <Snackbar
            open={this.state.snackbarProps.isOpen}
            message={
              <Box sx={webStyle.snackbarContent}>
                {this.state.snackbarProps?.type === "Error" ? (
                  <CancelRoundedIcon
                    sx={{
                      ...webStyle.snackbarIcon,
                      ...webStyle.snackbarWarningIcon,
                    }}
                  />
                ) : (
                  <CheckCircleRoundedIcon
                    sx={{
                      ...webStyle.snackbarIcon,
                      ...webStyle.snackbarSuccessIcon,
                    }}
                  />
                )}
                <Typography sx={webStyle.snackbarText}>
                  {this.state.snackbarProps?.message}
                </Typography>
              </Box>
            }
            autoHideDuration={3500}
            onClose={this.closeSnackbar}
            sx={webStyle.snackbar}
          />,
          document.body
        )}
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "41.14px",
  height: "24px",
  // borderRadius:"34.29px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#1A7BA4",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "20.57px",
    height: "20.57px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#CBD5E1",
    },

    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "16px",
    color: "#0f172A",
    fontFamily: "'Inter', sans-serif",
    height: "56px",
    padding: "0px 12px",
    borderRadius: "8px",
    "&::placeholder": {
      fontFamily: "'Inter', sans-serif",
      color: "#94A3B8",
      fontSize: "16px",
      opacity: 1, // Firefox requires this
    },
  },
  "&:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0 1000px white inset",
  },
}));

const webStyle = {
  representativesContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 30px 40px",
    height: "100%",
    backgroundColor: "#F8FAFC",
  },
  representativesTableHeader: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "40px 0px 0px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "auto",
  },
  representativesTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  representativesTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderRadius: "12px 12px 0 0",
  },
  representativesTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "white",
    "&:last-of-type": {
      borderRadius: "0 0 12px 12px",
    },
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
    width: "340px",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px 40px 10px 10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    width: "340px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    left: 310,
  },
  btnIcon: {
    width: "20px",
    height: "20px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(20% - 68px)",
    margin: "12px 0px",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "200px",
    margin: "12px 0px",
  },
  downloadButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    backgroundColor: "#1A7BA4",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    width: "143px",
    height: "36px",
    fontWeigth: "600",
  },
  representativeItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(20% - 68px)",
    margin: "13px 0px",
    alignItems: "center",
    display: "flex",
    wordBreak: 'break-word',
  },
  representativeItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    display: "flex",
    flexDirection: "row",
    margin: "13px 0px",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    whiteSpace: "nowrap",
  },
  paidStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#D1FAE5",
    color: "#059669",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  pendingStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  noInvoiceStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#F1F5F9",
    color: "#475569",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  representativeTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
    marginBottom: "24px",
  },
  representativeCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#334155",
    margin: "24px 0px 16px",
  },
  inputLable: {
    fontWeight: "700",
    marginBottom: "4px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: "#334155",
  },
  passwordalidation: {
    color: "#475569",
    fontSize: "13px",
    fontFamily: "'Inter', sans-serif",
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  filedError: {
    color: "#DC2626",
    marginTop: "4px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "13px",
  },
  confirmDelete: {
    padding: "6px 12px",
    borderRadius: "5px",
    textTransform: "none",
    backgroundColor: "white",
    color: "#DC2626",
    border: "1px solid #DC2626",
    fontFamily: "'Inter', sans-serif",
    fontWeight: "600",
    height: "44px",
    marginLeft: "16px !important",
  },
  cancelButton: {
    color: "#1A7BA4",
    border: "1px solid #1A7BA4",
    padding: "6px 12px",
    borderRadius: "5px",
    textTransform: "none",
    fontWeight: "700",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    height: "44px",
  },
  dialogTitleText: {
    fontWeight: "700",
    fontSize: "24px",
    fontFamily: "'Inter', sans-serif",
  },
  errorIcon: {
    width: "24px",
    height: "24px",
  },
  chevronIconActive: {
    "> path": {
      fill: "#475569",
    },
  },
  chevronIconDisabled: {
    "> path": {
      fill: "#64748B",
    },
  },
  loaderWrapper: {
    margin: "200px auto 0",
    width: "fit-content",
  },
  loader: {
    color: "#1A7BA4",
    height: "60px",
    width: "60px",
  },
  noRepresentativesBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noRepresentativesBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noRepresentativesTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  noRepresentativesSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  addNewButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px auto",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "430px",
  },
  noRepresentativesButtonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  noRepresentativesAddButtonBlock: {
    display: "flex",
    flexDirection: "row",
  },
  snackbar: {
    left: "50% !important",
    transform: "translateX(-50%)",
    fontFamily: "'Inter', sans-serif",
    "& .MuiPaper-root": {
      backgroundColor: "#FFFFFF",
      fontFamily: "'Inter', sans-serif !important",
      padding: "8px 16px 8px 8px",
      boxShadow: "0px 6px 15px -3px #00000026",
      borderRadius: "8px",
      minWidth: "auto",
      "& .MuiSnackbarContent-message": {
        padding: 0,
      },
    },
  },
  snackbarContent: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  snackbarIcon: {
    width: "26px",
    height: "26px",
  },
  snackbarWarningIcon: {
    "> path": {
      fill: "#F87171",
    },
  },
  snackbarSuccessIcon: {
    "> path": {
      fill: "#34D399",
    },
  },
  snackbarText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
  },
  cancelButtonForm: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "transparent",
    color: "#1A7BA4",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  submitButtonForm: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
};
// Customizable Area End
